<ng-container
    *ngrxLet="{
        isMainPanelExpanded: isMainPanelExpanded$,
        tabs: tabs$,
        jurisdictionMissions: jurisdictionMissions$,
        selectedTab: selectedTab$,
        isProcessing: isProcessing$,
        isAtcController: isAtcController$,
        flightList: flightList$,
        chipFilters: chipFilters$,
        appliedFilters: appliedFilters$,
        selectedFlightId: selectedFlightId$,
        lastSelectedFlight: lastSelectedFlight$
    } as vm"
>
    <div
        [@foldLeft]="vm.isMainPanelExpanded ? 'open' : 'closed'"
        class="panel"
        (@foldLeft.done)="handleFoldAnimationDone($event)"
        (@foldLeft.start)="handleFoldAnimationStart($event)"
    >
        <div class="flights-wrapper" #flightsPanelWrapper>
            <dtm-ui-loader-container [isShown]="vm.isProcessing">
                <div class="header">
                    <button type="button" class="close-button button-icon" (click)="closePanel()">
                        <dtm-ui-icon name="close"></dtm-ui-icon>
                    </button>
                    {{ "datsLibFlights.flightsPanel.header" | transloco : { value: vm.selectedTab?.type } }}
                    <dtm-ui-icon
                        *ngIf="vm.selectedTab?.type === FlightsTabType.UTM_ATC || vm.selectedTab?.type === FlightsTabType.UTM_FIS"
                        [name]="vm.selectedTab?.isActionRequired ? 'notification-2-fill' : 'notification'"
                        [ngClass]="{ 'action-required': vm.selectedTab?.isActionRequired }"
                    ></dtm-ui-icon>
                </div>
                <div class="flights-list scroll-shadows" #flightsList>
                    <ng-container *ngIf="vm.selectedTab?.type !== FlightsTabType.Mission; else missionTemplate">
                        <dats-lib-flights-filters
                            [chipFilters]="vm.chipFilters"
                            [appliedFilters]="vm.appliedFilters"
                            [totalCount]="vm.selectedTab?.total"
                            selectAllValueLabel="datsLibFlights.flightsPanel.allFiltersLabel"
                            [isMultiple]="true"
                            (filtersChange)="filtersChange.emit($event)"
                        ></dats-lib-flights-filters>
                        <ng-container *ngIf="vm.flightList?.categorizedFlights">
                            <ng-container *ngFor="let list of vm.flightList?.categorizedFlights | keyvalue">
                                <ng-container *ngIf="list.key | toType : FlightCategory as category">
                                    <dats-lib-category-flight-list
                                        *ngIf="list?.value as categorizedFlights"
                                        [flights]="categorizedFlights.visibleItems"
                                        [total]="categorizedFlights.total"
                                        [category]="category"
                                        [isExpanded]="vm.appliedFilters | invoke : checkIfIsCategoryExpanded : category : vm.flightList"
                                        [selectedFlightId]="vm.selectedFlightId"
                                        (confirmationResend)="confirmationResend.emit($event)"
                                        (checkinModify)="checkinModify.emit($event)"
                                        (selectedFlightChange)="selectedFlightChange.emit($event)"
                                        (expandChange)="changeAppliedFilters($event, vm.appliedFilters, vm.chipFilters, category)"
                                    >
                                    </dats-lib-category-flight-list>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-template #missionTemplate>
                        <dats-lib-mission-list [missions]="vm.jurisdictionMissions"></dats-lib-mission-list>
                    </ng-template>
                </div>
            </dtm-ui-loader-container>
        </div>

        <ul>
            <li *ngFor="let tab of vm.tabs">
                <dats-lib-flights-tab-item
                    [isSelected]="tab.type === vm.selectedTab?.type"
                    [flightsTab]="tab"
                    (click)="selectTab(tab)"
                ></dats-lib-flights-tab-item>
            </li>
        </ul>
    </div>

    <div
        [@foldLeft]="!vm.isMainPanelExpanded && vm.lastSelectedFlight && vm.selectedFlightId ? 'open' : 'closed'"
        class="single-flight-panel"
    >
        <dats-lib-flight-item
            *ngIf="vm.lastSelectedFlight"
            [flight]="vm.lastSelectedFlight"
            [category]="vm.lastSelectedFlight.category"
            [isAtcController]="vm.isAtcController"
            [isSelected]="false"
            (confirmationResend)="confirmationResend.emit($event)"
            (checkinModify)="checkinModify.emit($event)"
        ></dats-lib-flight-item>
    </div>
</ng-container>
