import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { FlightItem } from "../../models/flight.models";

interface OperationDetailsComponentState {
    flight: FlightItem | undefined;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-operation-details",
    templateUrl: "./operation-details.component.html",
    styleUrls: ["./operation-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperationDetailsComponent {
    @Input({ required: true }) public set flight(value: FlightItem | undefined) {
        this.localStore.setState({ flight: value });
    }

    protected readonly flight$ = this.localStore.selectByKey("flight").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<OperationDetailsComponentState>) {
        this.localStore.setState({
            flight: undefined,
        });
    }
}
