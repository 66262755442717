<ng-container *ngrxLet="{ isProcessing: isProcessing$, hasError: hasError$, zones: zones$ } as vm" [ngSwitch]="true">
    <button type="button" mat-menu-item disabled *ngSwitchCase="vm.hasError && !vm.isProcessing">
        <dtm-ui-icon name="alert-fill" class="load-error-icon" />
        {{ "datsLibAlert.zonesContextMenu.errorLabel" | transloco }}
    </button>
    <button type="button" mat-menu-item disabled *ngSwitchCase="vm.isProcessing">
        <mat-spinner diameter="20" />
        {{ "datsLibAlert.zonesContextMenu.processingLabel" | transloco }}
    </button>
    <button *ngSwitchCase="!vm.hasError && !vm.isProcessing && (!vm.zones || vm.zones.length === 0)" type="button" mat-menu-item disabled>
        {{ "datsLibAlert.zonesContextMenu.noZonesLabel" | transloco }}
    </button>
    <ng-container *ngSwitchCase="vm.zones && vm.zones.length > 0">
        <mat-menu #zoneItemOptions="matMenu" class="zone-item-options">
            <ng-template matMenuContent let-zone="zone">
                <button
                    type="button"
                    mat-menu-item
                    class="zone-item-option no-fly-zone-option"
                    (click)="createAlert.emit({ type: AlertType.NoFlyZone, zone })"
                >
                    <dtm-ui-icon name="arrow-down-circle-fill" />

                    {{ "datsLibAlert.zonesContextMenu.noFlyZoneActionLabel" | transloco }}
                </button>
                <button
                    type="button"
                    mat-menu-item
                    class="zone-item-option modify-option"
                    (click)="createAlert.emit({ type: AlertType.Modification, zone })"
                    disabled
                >
                    <dtm-ui-icon name="equalizer-fill" />

                    {{ "datsLibAlert.zonesContextMenu.modifyActionLabel" | transloco }}
                </button>
                <button
                    type="button"
                    mat-menu-item
                    class="zone-item-option message-option"
                    (click)="createAlert.emit({ type: AlertType.Message, zone })"
                >
                    <dtm-ui-icon name="mail-send-fill" />

                    {{ "datsLibAlert.zonesContextMenu.messageActionLabel" | transloco }}
                </button>
            </ng-template>
        </mat-menu>

        <button
            *ngFor="let zone of vm.zones"
            type="button"
            mat-menu-item
            class="zone-item"
            [matMenuTriggerFor]="zoneItemOptions"
            [matMenuTriggerData]="{ zone }"
        >
            {{ zone.designator }}
        </button>
    </ng-container>
</ng-container>
