import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { FlightProgress, FlightProgressPhase } from "../../models/flight.models";

interface FlightProgressBadgeComponentState {
    progress: FlightProgress | undefined;
    isSystem: boolean;
    isUnacknowledged: boolean;
}

@Component({
    selector: "dats-lib-flight-progress-badge",
    templateUrl: "./flight-progress-badge.component.html",
    styleUrls: ["./flight-progress-badge.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightProgressBadgeComponent {
    @Input({ required: true }) public set progress(value: FlightProgress) {
        this.localStore.patchState({ progress: value });
    }

    @Input() public set isUnacknowledged(value: BooleanInput) {
        this.localStore.patchState({ isUnacknowledged: coerceBooleanProperty(value) });
    }

    @Input() public set isSystem(value: BooleanInput) {
        this.localStore.patchState({ isSystem: coerceBooleanProperty(value) });
    }

    protected readonly progress$ = this.localStore.selectByKey("progress");
    protected readonly isSystem$ = this.localStore.selectByKey("isSystem");
    protected readonly isUnacknowledged$ = this.localStore.selectByKey("isUnacknowledged");
    protected readonly FlightProgressPhase = FlightProgressPhase;

    constructor(private readonly localStore: LocalComponentStore<FlightProgressBadgeComponentState>) {
        this.localStore.setState({
            progress: undefined,
            isSystem: false,
            isUnacknowledged: false,
        });
    }
}
