import { ArrayUtils } from "@dtm-frontend/shared/utils";
import { AirspaceElement, AirspaceType, FlightCategory, FlightItem, FlightList, FlightsTab, FlightsTabType } from "../models/flight.models";
import { FlightFilterType } from "../models/flights-filters.models";
import {
    ATC_CONTROLLER_TABS,
    DTM_CATEGORIES,
    FIS_CATEGORIES,
    FIS_INFORMER_TABS,
    FIS_ZONES_CATEGORIES,
    MANUALLY_ACCEPTED_CATEGORIES,
    SYSTEM_ACCEPTED_CATEGORIES,
    UTM_ATC_CATEGORIES,
    UTM_FIS_CATEGORIES,
} from "../utils/defaults";
import { CategorizedFlights } from "./flights.state";

function isFlightVisible(flight: FlightItem, category: FlightCategory, filters: FlightFilterType[]): boolean {
    if (!filters.length) {
        return true;
    }

    for (const filter of filters) {
        if (flight.attributes.includes(filter) || filter === category) {
            return true;
        }
    }

    return false;
}

function filterAirspaceTypes(flightItem: FlightItem, types: (string | AirspaceType)[]): boolean {
    const airspaceElements: AirspaceElement[] = flightItem.operation?.airspace?.elements || [];
    const hasMatchingType = airspaceElements.some((element) => types.includes(element.type));

    return hasMatchingType;
}

function getAirspaceTypesFlights(flightItems: FlightItem[], types: (string | AirspaceType)[]): FlightItem[] {
    return flightItems.filter((flightItem) => filterAirspaceTypes(flightItem, types));
}

function categorizeFlights(flightItems: FlightItem[], categories: FlightCategory[]): CategorizedFlights {
    const categorizedFlights: CategorizedFlights = {};

    for (const flightItem of flightItems) {
        if (categories.includes(flightItem.category)) {
            if (!categorizedFlights[flightItem.category]) {
                categorizedFlights[flightItem.category] = [];
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            categorizedFlights[flightItem.category]!.push(flightItem);
        }
    }

    return categorizedFlights;
}

function getCategorizedFlights(tabType: FlightsTabType, flightItems: FlightItem[]): CategorizedFlights {
    switch (tabType) {
        case FlightsTabType.UTM_ATC:
            return categorizeFlights(flightItems, UTM_ATC_CATEGORIES);
        case FlightsTabType.UTM_FIS:
            return categorizeFlights(flightItems, UTM_FIS_CATEGORIES);
        case FlightsTabType.AcceptedManually:
            return categorizeFlights(flightItems, MANUALLY_ACCEPTED_CATEGORIES);
        case FlightsTabType.SystemAccepted:
            return categorizeFlights(flightItems, SYSTEM_ACCEPTED_CATEGORIES);
        case FlightsTabType.FIS:
            return categorizeFlights(flightItems, FIS_CATEGORIES);
        case FlightsTabType.TWR: {
            const flights = getAirspaceTypesFlights(flightItems, [AirspaceType.CTR, AirspaceType.MCTR]);

            return categorizeFlights(flights, FIS_ZONES_CATEGORIES);
        }
        case FlightsTabType.DTM: {
            const atzFlights = getAirspaceTypesFlights(flightItems, [AirspaceType.ATZ]);
            const notAtcFlights = flightItems.filter((flight) => DTM_CATEGORIES.includes(flight.category));

            return categorizeFlights(ArrayUtils.unique([...atzFlights, ...notAtcFlights]), FIS_ZONES_CATEGORIES);
        }
        // TODO DTATS-59 missions, archive
        default:
            return {};
    }
}

export function getFilteredFlightList(
    flights: FlightItem[],
    isAtcController: boolean,
    selectedTabType: FlightsTabType | undefined,
    filters: FlightFilterType[]
): FlightList {
    const resultList: FlightList = {
        categorizedFlights: {},
        visibleFlights: [],
        filteredOutFlights: [],
        filters: {},
    };

    const tabTypes = isAtcController ? ATC_CONTROLLER_TABS : FIS_INFORMER_TABS;
    const filteredOutFlightsSet: Set<FlightItem> = new Set();
    const visibleFlightsSet: Set<FlightItem> = new Set();

    if (selectedTabType === FlightsTabType.Mission) {
        resultList.visibleFlights = flights;

        return resultList;
    }

    tabTypes.forEach((type) => {
        const categorizedFlights = getCategorizedFlights(type, flights);

        if (!selectedTabType || selectedTabType === type) {
            resultList.categorizedFlights = Object.keys(categorizedFlights).reduce((result, key) => {
                const category = key as FlightCategory;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const items = Object.values(categorizedFlights[category]!);
                items.forEach((item) => {
                    item.attributes.forEach((attribute) => {
                        const existingFilter = resultList.filters[attribute] ?? { categories: [], total: 0 };
                        if (!existingFilter.categories.includes(category)) {
                            existingFilter.categories.push(category);
                        }
                        resultList.filters[attribute] = {
                            categories: existingFilter.categories,
                            total: existingFilter.total + 1,
                        };
                    });
                });
                const [visibleItems, filteredOutItems] = ArrayUtils.partition(items, (flight) =>
                    isFlightVisible(flight, category, filters)
                );

                result[category] = {
                    visibleItems,
                    total: items.length,
                };

                resultList.visibleFlights.push(...visibleItems);
                visibleItems.forEach((item) => visibleFlightsSet.add(item));
                filteredOutItems.forEach((item) => filteredOutFlightsSet.add(item));

                return result;
            }, resultList.categorizedFlights);
        } else {
            const items = Object.values(categorizedFlights).flat(2);
            items.forEach((item) => filteredOutFlightsSet.add(item));
        }
    });

    resultList.filteredOutFlights = [...filteredOutFlightsSet.values()].filter((flight) => !visibleFlightsSet.has(flight));

    return resultList;
}

export function getFlightsTabs(flights: FlightItem[], isAtcController: boolean): FlightsTab[] {
    const tabTypes = isAtcController ? ATC_CONTROLLER_TABS : FIS_INFORMER_TABS;

    return tabTypes
        .filter((tabType) => tabType !== FlightsTabType.Mission)
        .map((type) => {
            const categorizedFlights = getCategorizedFlights(type, flights);

            return {
                type,
                total: Object.values(categorizedFlights).reduce((total, items) => total + (items?.length ?? 0), 0),
                isActionRequired: !!(
                    categorizedFlights.Emergency?.length ||
                    categorizedFlights.Pending?.length ||
                    categorizedFlights.Pending_112?.length
                ),
            };
        });
}

export function getDefaultTabs(isAtcController: boolean): FlightsTab[] {
    const tabTypes = isAtcController ? ATC_CONTROLLER_TABS : FIS_INFORMER_TABS;

    return tabTypes.map((type) => ({
        type,
        total: 0,
        isActionRequired: false,
    }));
}

export function mergeTabs(existingTabs: FlightsTab[], newTabs: FlightsTab[]): FlightsTab[] {
    return ArrayUtils.unique([...existingTabs, ...newTabs], (tab) => tab.type);
}
