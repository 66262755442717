import { PageResponseBody } from "@dtm-frontend/shared/ui";
import {
    Alert,
    AlertBadgeType,
    AlertPointOfInterest,
    AlertPointOfInterestWithPages,
    CategorizedAlertsByBadge,
} from "../models/alert.models";

export type AlertResponseBody = Alert;

export interface GetPoiListResponseBody {
    items: PageResponseBody<PointOfInterestResponseBody>;
}

export type PointOfInterestResponseBody = Omit<AlertPointOfInterest, "isBookmarked"> & { bookmarked: boolean };

export interface PageableRequestPayload {
    size: number;
    page: number;
    sort: string;
}

export interface PageableResponseBody<T> extends Omit<PageResponseBody<T>, "hasContent"> {
    sort: SortResponseBody[];
    empty: boolean;
}

export interface SortResponseBody {
    direction: string;
    nullHandling: string;
    ascending: boolean;
    property: string;
    ignoreCase: boolean;
}

export function categorizeAlertsByBadge(response: AlertResponseBody[]) {
    const categorizedAlertsByBadge: CategorizedAlertsByBadge = {};
    const dateNow = new Date();

    for (const responseItem of response) {
        const startedAt = new Date(responseItem.startedAt);
        if (startedAt <= dateNow && Object.values(AlertBadgeType).includes(responseItem.type)) {
            if (!categorizedAlertsByBadge[responseItem.type]) {
                categorizedAlertsByBadge[responseItem.type] = [];
            }
            categorizedAlertsByBadge[responseItem.type]?.push(responseItem);
        }

        if (startedAt > dateNow) {
            if (!categorizedAlertsByBadge[AlertBadgeType.NotStarted]) {
                categorizedAlertsByBadge[AlertBadgeType.NotStarted] = [];
            }
            categorizedAlertsByBadge[AlertBadgeType.NotStarted]?.push(responseItem);
        }
    }

    return categorizedAlertsByBadge;
}

export function convertGetPoiListResponseBodyToPoiWithPages(
    response: PageResponseBody<PointOfInterestResponseBody>
): AlertPointOfInterestWithPages {
    return {
        content: response.content.map((poi) => ({ ...poi, isBookmarked: poi.bookmarked })),
        pageNumber: response.number,
        pageSize: response.size,
        totalElements: response.totalElements,
    };
}
