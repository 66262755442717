import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { LetDirective } from "@ngrx/component";

interface TimerComponentState {
    isIconDisplayed: boolean;
    date: Date | undefined;
    isObsolete: boolean;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-time-label",
    standalone: true,
    imports: [CommonModule, LetDirective, SharedI18nModule, SharedUiModule, MatTooltipModule],
    templateUrl: "./time-label.component.html",
    styleUrls: ["./time-label.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TimeLabelComponent {
    @Input() public set date(value: Date) {
        this.localStore.patchState({ date: value });
    }
    @Input() public set isIconDisplayed(value: BooleanInput) {
        this.localStore.patchState({ isIconDisplayed: coerceBooleanProperty(value) });
    }
    @Input() public set isObsolete(value: BooleanInput) {
        this.localStore.patchState({ isObsolete: coerceBooleanProperty(value) });
    }

    protected readonly isIconDisplayed$ = this.localStore.selectByKey("isIconDisplayed");
    protected readonly date$ = this.localStore.selectByKey("date");
    protected readonly isObsolete$ = this.localStore.selectByKey("isObsolete");

    constructor(private readonly localStore: LocalComponentStore<TimerComponentState>) {
        this.localStore.setState({ isIconDisplayed: true, date: undefined, isObsolete: false });
    }
}
