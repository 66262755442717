<ng-container *ngrxLet="{ hospitals: hospitals$, templates: templates$, selectedAlertType: selectedAlertType$ } as vm">
    <dats-lib-action-sidebar-header (actionCancel)="actionCancel.emit()">
        {{ "datsLibAlert.alertForms.hospitalAlertForm.header" | transloco }}
    </dats-lib-action-sidebar-header>
    <dats-lib-alert-form
        class="action-sidebar-content scroll-shadows"
        [templates]="vm.templates"
        [alertType]="vm.selectedAlertType"
        [isMessageTemplateIdSelectionDisabled]="!hospitalFormControl.value"
    >
        <dtm-ui-select-field
            contentSlot
            [placeholder]="'datsLibAlert.alertForms.selectPlaceholder' | transloco"
            [formControl]="hospitalFormControl"
            [isClearable]="false"
        >
            <label>{{ "datsLibAlert.alertForms.hospitalAlertForm.hospitalLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let hospital of vm.hospitals" [value]="hospital">
                {{ hospital.title }}
                <span hint *ngIf="hospital.description">{{ hospital.description }}</span>
            </dtm-ui-select-option>

            <div class="field-error" *dtmUiFieldHasError="hospitalFormControl; name: 'required'">
                {{ "datsLibAlert.requiredFieldErrorMessage" | transloco }}
            </div>
        </dtm-ui-select-field>
    </dats-lib-alert-form>
    <dats-lib-action-sidebar-footer
        (actionCancel)="actionCancel.emit()"
        (actionConfirm)="tryEmitConfirm()"
        [confirmButtonText]="'datsLibAlert.alertForms.saveButtonLabel' | transloco : { value: vm.selectedAlertType }"
    >
    </dats-lib-action-sidebar-footer>
</ng-container>
