export namespace UserSettingsActions {
    export class SetScreenBrightness {
        public static readonly type = "[User Settings] Set screen brightness";
        constructor(public screenBrightness: number) {}
    }
    export class SetSystemVolume {
        public static readonly type = "[User Settings] Set system volume";
        constructor(public systemVolume: number) {}
    }
}
