import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, catchError, map, throwError } from "rxjs";
import { FLIGHTS_ENDPOINTS, FlightsEndpoints } from "../flights.tokens";
import {
    ArchiveCheckinFormValues,
    FlightItem,
    FlightsErrorType,
    JurisdictionMission,
    ModifyCheckinFormValues,
    TechnicalCheckinData,
} from "../models/flight.models";
import {
    ArchiveCheckinRequestPayload,
    GetFlightsResponseBody,
    JurisdictionMissionResponseBody,
    ModifyCheckinRequestPayload,
    PageableResponseBody,
    convertGetFlightItemsResponseBodyToFlightItems,
    convertJurisdictionMissionResponseBodyToJurisdictionMission,
    convertTechnicalCheckinDataToRequestPayload,
    prepareCheckinModificationPayload,
} from "./flights-api.converters";

@Injectable({
    providedIn: "root",
})
export class FlightsApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(FLIGHTS_ENDPOINTS) private readonly endpoints: FlightsEndpoints) {}

    public getFlights(): Observable<FlightItem[]> {
        return this.httpClient.get<GetFlightsResponseBody>(this.endpoints.getFlights).pipe(
            map((response) => convertGetFlightItemsResponseBodyToFlightItems(response?.items)),
            catchError(() => throwError(() => ({ type: FlightsErrorType.CannotGetFlights })))
        );
    }

    public getJurisdictionMissionList(): Observable<JurisdictionMission[]> {
        return this.httpClient.get<PageableResponseBody<JurisdictionMissionResponseBody>>(this.endpoints.getJurisdictionMissionList).pipe(
            map((response) => response.content.map((mission) => convertJurisdictionMissionResponseBodyToJurisdictionMission(mission))),
            catchError(() => throwError(() => ({ type: FlightsErrorType.CannotGetMissions })))
        );
    }

    public getJurisdictionMission(id: string): Observable<JurisdictionMission> {
        return this.httpClient
            .get<JurisdictionMissionResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getJurisdictionMission, { id }))
            .pipe(
                map((response) => convertJurisdictionMissionResponseBodyToJurisdictionMission(response)),
                catchError(() => throwError(() => ({ type: FlightsErrorType.CannotGetMission })))
            );
    }

    public standbyCheckin(id: string, duration: number): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.standbyCheckin, { id }), {
                duration: `PT${duration}M`,
            })
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotStandbyCheckin }))));
    }

    public modifyCheckin(id: string, formValues: ModifyCheckinFormValues) {
        const requestBody: ModifyCheckinRequestPayload = prepareCheckinModificationPayload(formValues);

        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.modifyCheckin, { id }), requestBody)
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotModifyCheckin }))));
    }

    public modifyCheckinAndAccept(id: string, formValues: ModifyCheckinFormValues) {
        const requestBody: ModifyCheckinRequestPayload = prepareCheckinModificationPayload(formValues);

        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.acceptCheckin, { id }), requestBody)
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotModifyCheckin }))));
    }

    public acceptCheckin(id: string) {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.acceptCheckin, { id }), {})
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotAcceptCheckin }))));
    }

    public stopCheckin(id: string) {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.stopCheckin, { id }), {})
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotStopCheckin }))));
    }

    public rejectCheckin(id: string) {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.acceptCheckin, { id }))
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotRejectCheckin }))));
    }

    public archiveCheckin(id: string, formValues: ArchiveCheckinFormValues) {
        const body: ArchiveCheckinRequestPayload = {
            reason: {
                id: formValues.id,
                args: {
                    value: formValues.description,
                },
            },
        };

        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.getFlights, { id }), { body })
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotArchiveCheckin }))));
    }

    public resendCheckinConfirmation(id: string): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.modifyCheckin, { id }), {})
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotResendCheckinConfirmation }))));
    }

    public createTechnicalCheckin(checkinData: TechnicalCheckinData): Observable<void> {
        return this.httpClient
            .post<void>(this.endpoints.createTechnicalCheckin, convertTechnicalCheckinDataToRequestPayload(checkinData), {
                headers: {
                    "Content-Type": "application/vnd.pansa.dats.v1+json",
                    Accept: "application/vnd.pansa.dats.v1+json",
                },
            })
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotCreateTechnicalCheckin }))));
    }
}
