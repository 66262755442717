import { Injectable, inject } from "@angular/core";
import { ArrayUtils } from "@dtm-frontend/shared/utils";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, catchError, finalize, tap } from "rxjs";
import { Error } from "../../shared/models/error.model";
import {
    AlertErrorType,
    AlertGeometrySourceType,
    AlertPointOfInterest,
    AlertProposal,
    CategorizedAlertsByBadge,
    PoiType,
    Zone,
} from "../models/alert.models";
import { AlertApiService } from "../services/alert-api.service";
import { AlertActions } from "./alert.actions";

export interface AlertStateModel {
    error: Error<AlertErrorType> | undefined;
    isActionProcessing: boolean;
    selectedAlertGeometrySourceType: AlertGeometrySourceType | undefined;
    alerts: CategorizedAlertsByBadge | undefined;
    alertProposal: AlertProposal | undefined;
    hospitalList: AlertPointOfInterest[] | undefined;
    hemsBaseList: AlertPointOfInterest[] | undefined;
    zoneAutocompletionList: Zone[] | undefined;
    isZoneAutocompletionProcessing: boolean;
    zonesForCoordinates: Zone[] | undefined;
    isZonesForCoordinatesProcessing: boolean;
}

const defaultState: AlertStateModel = {
    error: undefined,
    isActionProcessing: false,
    selectedAlertGeometrySourceType: undefined,
    alerts: undefined,
    alertProposal: undefined,
    hospitalList: undefined,
    hemsBaseList: undefined,
    zoneAutocompletionList: undefined,
    isZoneAutocompletionProcessing: false,
    zonesForCoordinates: undefined,
    isZonesForCoordinatesProcessing: false,
};

@State<AlertStateModel>({
    name: "alert",
    defaults: defaultState,
})
@Injectable()
export class AlertState {
    private alertApi = inject(AlertApiService);

    @Selector()
    public static error(state: AlertStateModel): Error<AlertErrorType> | undefined {
        return state.error;
    }

    @Selector()
    public static isActionProcessing(state: AlertStateModel): boolean {
        return state.isActionProcessing;
    }

    @Selector()
    public static selectedAlertGeometrySourceType(state: AlertStateModel): AlertGeometrySourceType | undefined {
        return state.selectedAlertGeometrySourceType;
    }

    @Selector()
    public static alerts(state: AlertStateModel) {
        return state.alerts;
    }

    @Selector()
    public static alertProposal(state: AlertStateModel): AlertProposal | undefined {
        return state.alertProposal;
    }

    @Selector()
    public static hospitalList(state: AlertStateModel): AlertPointOfInterest[] | undefined {
        return state.hospitalList;
    }

    @Selector()
    public static hemsBaseList(state: AlertStateModel): AlertPointOfInterest[] | undefined {
        return state.hemsBaseList;
    }

    @Selector()
    public static zoneAutocompletionList(state: AlertStateModel): Zone[] | undefined {
        return state.zoneAutocompletionList;
    }

    @Selector()
    public static isZoneAutocompletionProcessing(state: AlertStateModel): boolean {
        return state.isZoneAutocompletionProcessing;
    }

    @Selector()
    public static zonesForCoordinates(state: AlertStateModel): Zone[] | undefined {
        return state.zonesForCoordinates;
    }

    @Selector()
    public static isZonesForCoordinatesProcessing(state: AlertStateModel): boolean {
        return state.isZonesForCoordinatesProcessing;
    }

    @Action(AlertActions.SelectAlertGeometrySourceType)
    public selectAlertGeometrySourceType(context: StateContext<AlertStateModel>, action: AlertActions.SelectAlertGeometrySourceType) {
        context.patchState({ selectedAlertGeometrySourceType: action.type });
    }

    @Action(AlertActions.GetAlerts)
    public getAlerts(context: StateContext<AlertStateModel>) {
        context.patchState({ error: undefined, alertProposal: undefined, isActionProcessing: true });

        return this.alertApi.getAlerts().pipe(
            tap((alerts) => {
                context.patchState({ alerts });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAlertProposal)
    public getAlertProposal(context: StateContext<AlertStateModel>, action: AlertActions.GetAlertProposal) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.getAlertProposal(action.params.type, action.params.poiId, action.params.designator).pipe(
            tap((alertProposal) => {
                context.patchState({ alertProposal });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    alertProposal: undefined,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.CreateAlert)
    public createAlert(context: StateContext<AlertStateModel>, action: AlertActions.CreateAlert) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.createAlert(action.alertEntity).pipe(
            tap(() => context.dispatch(new AlertActions.GetAlerts())),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.ModifyAlert)
    public modifyAlert(context: StateContext<AlertStateModel>, action: AlertActions.ModifyAlert) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.updateAlert(action.alertId, action.timeRange).pipe(
            tap(() => context.dispatch(new AlertActions.GetAlerts())),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.FinishAlert)
    public finishAlert(context: StateContext<AlertStateModel>, action: AlertActions.FinishAlert) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.finishAlert(action.alertId).pipe(
            tap(() => context.dispatch(new AlertActions.GetAlerts())),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAllHospitals)
    public getAllHospitals(context: StateContext<AlertStateModel>) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.getPoiList(false, PoiType.Hospital).pipe(
            tap(({ content: hospitalList }) => {
                context.patchState({
                    hospitalList,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAllHemsBases)
    public getAllHemsBases(context: StateContext<AlertStateModel>) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.getPoiList(false, PoiType.Hems).pipe(
            tap(({ content: hemsBaseList }) => {
                context.patchState({
                    hemsBaseList,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAllBookmarkedPois)
    public getAllBookmarkedPois(context: StateContext<AlertStateModel>) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.getPoiList(true).pipe(
            tap(({ content }) => {
                const [hospitalList, poisWithoutHospitals] = ArrayUtils.partition(content, (poi) => poi.type === PoiType.Hospital);
                const hemsBaseList = poisWithoutHospitals.filter((poi) => poi.type === PoiType.Hems);

                context.patchState({
                    hospitalList,
                    hemsBaseList,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.SetBookmarkedHospitals)
    public setBookmarkedHospitals(context: StateContext<AlertStateModel>, action: AlertActions.SetBookmarkedHospitals) {
        const bookmarkedHospitals = context.getState().hospitalList;

        if (!bookmarkedHospitals) {
            return;
        }

        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.setBookmarkedHospitals(bookmarkedHospitals, action.newBookmarkedHospitals).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetZoneAutocompletion)
    public getZoneAutocompletion(context: StateContext<AlertStateModel>, action: AlertActions.GetZoneAutocompletion) {
        context.patchState({ isZoneAutocompletionProcessing: true, zoneAutocompletionList: undefined });

        return this.alertApi.getZoneAutocompletion(action.value).pipe(
            tap((zoneAutocompletionList) => {
                context.patchState({ zoneAutocompletionList });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isZoneAutocompletionProcessing: false }))
        );
    }

    @Action(AlertActions.SetBookmarkedHemsBases)
    public setBookmarkedHemsBases(context: StateContext<AlertStateModel>, action: AlertActions.SetBookmarkedHemsBases) {
        const bookmarkedHemsBases = context.getState().hemsBaseList;

        if (!bookmarkedHemsBases) {
            return;
        }

        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.setBookmarkedHemsBases(bookmarkedHemsBases, action.newBookmarkedHemsBases).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetZonesForCoordinates)
    public getZonesForCoordinates(context: StateContext<AlertStateModel>, action: AlertActions.GetZonesForCoordinates) {
        context.patchState({ isZonesForCoordinatesProcessing: true, zonesForCoordinates: undefined });

        return this.alertApi.getZonesForWGS84Coordinates(action.coordinates).pipe(
            tap((zonesForCoordinates) => context.patchState({ zonesForCoordinates })),
            catchError((error) => {
                context.patchState({
                    error,
                    zonesForCoordinates: undefined,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isZonesForCoordinatesProcessing: false }))
        );
    }
}
