import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ALERT_ENDPOINTS } from "@dtm-frontend/drone-air-traffic-services-lib/alert";
import { FLIGHTS_ENDPOINTS, FlightsModule } from "@dtm-frontend/drone-air-traffic-services-lib/flights";
import { KEYCLOAK_CONFIG, SharedAuthModule } from "@dtm-frontend/shared/auth";
import { AZURE_MAPS_SUBSCRIPTION_KEY } from "@dtm-frontend/shared/map";
import { LEAFLET_MAP_CONFIG } from "@dtm-frontend/shared/map/leaflet";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import {
    I18nRootModule,
    LANGUAGE_CONFIGURATION,
    LanguageCode,
    TranslationHelperService,
    getTranslocoInlineLoader,
} from "@dtm-frontend/shared/ui/i18n";
import { FunctionUtils, Logger, LoggerModule } from "@dtm-frontend/shared/utils";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NgxsModule } from "@ngxs/store";
import { first, tap } from "rxjs/operators";
import { DroneAirTrafficServicesEnvironment } from "../environments/environment.model";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { ActionsHandler } from "./services/actions.handler";

Logger.initialize("https://0c9284e9596d92472f5d259a5f5431e9@sentry.pansa.cloud/17");

export function createAppModule(environment: DroneAirTrafficServicesEnvironment) {
    @NgModule({
        declarations: [AppComponent],
        imports: [
            NgxsModule.forRoot([], {
                // NOTE: must be first because of https://github.com/ngxs/store/issues/375
                developmentMode: !environment.production,
            }),
            AppRoutingModule,
            BrowserAnimationsModule,
            BrowserModule,
            I18nRootModule.forRoot({
                developmentMode: !environment.production,
                ...LANGUAGE_CONFIGURATION,
            }),
            NgxsStoragePluginModule.forRoot({
                key: ["ui"],
                storage: StorageOption.LocalStorage,
            }),
            NgxsReduxDevtoolsPluginModule.forRoot({
                disabled: environment.production,
                name: "Drone Air Traffic Services",
            }),
            LoggerModule.forRoot(environment.name, !environment.production),
            SharedAuthModule.forRoot({
                bearerExcludedUrls: [...environment.bearerExcludedUrls],
            }),
            SharedUiModule,
            HeaderComponent,
            FlightsModule.forRoot(),
        ],
        providers: [
            {
                provide: APP_INITIALIZER,
                useFactory: () => FunctionUtils.noop,
                deps: [ActionsHandler],
                multi: true,
            },
            {
                provide: TRANSLOCO_SCOPE,
                multi: true,
                useValue: {
                    scope: "dats",
                    loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
                },
            },
            {
                provide: KEYCLOAK_CONFIG,
                useValue: environment.keycloakConfig,
            },
            {
                provide: AZURE_MAPS_SUBSCRIPTION_KEY,
                useValue: environment.azureMapsSubscriptionKey,
            },
            {
                provide: LEAFLET_MAP_CONFIG,
                useValue: environment.leafletMapConfig,
            },
            {
                provide: FLIGHTS_ENDPOINTS,
                useValue: environment.flightsEndpoints,
            },
            {
                provide: ALERT_ENDPOINTS,
                useValue: environment.alertEndpoints,
            },
        ],
        bootstrap: [AppComponent],
    })
    class AppModule {
        constructor(translocoHelper: TranslationHelperService, titleService: Title) {
            translocoHelper
                .waitForTranslation("dats.appTitle")
                .pipe(first(), tap(titleService.setTitle.bind(titleService)))
                .subscribe();
        }
    }

    return AppModule;
}
