import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AlertGeometrySourceType } from "../../models/alert.models";

interface AlertGeometryPanelComponentState {
    selectedAlertGeometrySourceType: AlertGeometrySourceType | undefined;
}

const FADE_ANIMATION_DURATION = 200;

@UntilDestroy()
@Component({
    selector: "dats-lib-alert-geometry-panel",
    templateUrl: "./alert-geometry-panel.component.html",
    styleUrls: ["./alert-geometry-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.fadeAnimation(FADE_ANIMATION_DURATION), AnimationUtils.slideInSideOffScreenUp(FADE_ANIMATION_DURATION)],
})
export class AlertGeometryPanelComponent {
    protected readonly AlertGeometrySourceType = AlertGeometrySourceType;

    @Input({ required: true }) public set selectedAlertGeometrySourceType(value: AlertGeometrySourceType | undefined) {
        this.localStore.patchState({ selectedAlertGeometrySourceType: value });
    }

    protected readonly selectedAlertGeometrySourceType$ = this.localStore.selectByKey("selectedAlertGeometrySourceType");

    @Output() public readonly alertGeometrySourceTypeChange = new EventEmitter<AlertGeometrySourceType | undefined>();

    constructor(private readonly localStore: LocalComponentStore<AlertGeometryPanelComponentState>) {
        this.localStore.setState({
            selectedAlertGeometrySourceType: undefined,
        });
    }
}
