import { GeoJSON, Page } from "@dtm-frontend/shared/ui";
import { ISO8601TimeDuration } from "@dtm-frontend/shared/utils";

export enum AlertGeometrySourceType {
    AreaCircle = "AreaCircle",
    AreaPolygon = "AreaPolygon",
    AreaCorridor = "AreaCorridor",
    Airspace = "Airspace",
    Hospital = "Hospital",
    HEMS = "HEMS",
}

export enum AlertType {
    NoFlyZone = "NO_FLY_ZONE",
    Message = "MESSAGE",
    Modification = "MODIFICATION",
}

export interface Alert {
    id: string;
    type: AlertBadgeType;
    title: string;
    messageContent: string;
    startAt: string;
    finishAt: string;
    area: Polygon;
}

export enum AlertBadgeType {
    NoFlyZone = "NO_FLY_ZONE",
    Message = "MESSAGE",
    Modification = "MODIFICATION",
    NotStarted = "NOT_STARTED",
}

export type CategorizedAlertsByBadge = Partial<Record<AlertBadgeType, Alert[]>>;

interface Polygon {
    "@type": "GeoApi$WKB$Polygon";
    data: string;
}

export enum AlertErrorType {
    CannotGetAlert = "CannotGetAlert",
    CannotCreateLocationAlert = "CannotCreateLocationAlert",
    CannotGetAlertProposals = "CannotGetAlertProposals",
    CannotGetAlerts = "CannotGetAlerts",
    CannotGetPointsOfInterest = "CannotGetPointsOfInterest",
    CannotBookmarkHospitals = "CannotBookmarkHospitals",
}

export interface AlertError {
    type: AlertErrorType;
}

export enum PoiType {
    Other = "OTHER",
    Airspace = "AIRSPACE",
    Hems = "HEMS",
    Hospital = "HOSPITAL",
    TakeoffSpot = "TAKEOFF_SPOT",
}

export interface AlertPointOfInterest {
    id: string;
    title: string;
    description: string;
    type: PoiType;
    isBookmarked: boolean;
    point: {
        data: string;
    };
}

export interface AlertPointOfInterestWithPages extends Page {
    content: AlertPointOfInterest[];
}

export interface AlertTextTemplate {
    id: string;
    content: string;
}

export interface AlertProposal {
    alertType: AlertType;
    textTemplates: AlertTextTemplate[];
}

export enum AlertAreaType {
    Poi = "POI",
    Wkb = "WKB",
    Zone = "Zone",
}

export interface ZoneArea {
    "@type": AlertAreaType.Zone;
    designator: string;
}

export interface PoiArea {
    "@type": AlertAreaType.Poi;
    id: string;
}

export interface WkbArea {
    "@type": AlertAreaType.Wkb;
    polygon: GeoJSON;
}

export interface AlertEntity<TArea extends { "@type": AlertAreaType }> {
    type: AlertType;
    area: TArea;
    startAt: Date;
    duration?: ISO8601TimeDuration;
    message?: string;
}

export type ZoneAlertEntity = AlertEntity<ZoneArea>;
export type PoiAlertEntity = AlertEntity<PoiArea>;
export type WkbAlertEntity = AlertEntity<WkbArea>;

export type LocationAlertEntity = ZoneAlertEntity | PoiAlertEntity | WkbAlertEntity;

export const ALERT_BADGES = [
    {
        type: AlertBadgeType.NoFlyZone,
        class: "no-fly-zone",
        icon: "forbid",
        tooltipKey: "datsLibAlert.alertBadges.noFlyZoneTooltip",
    },
    {
        type: AlertBadgeType.Message,
        class: "message",
        icon: "mail-send",
        tooltipKey: "datsLibAlert.alertBadges.messageTooltip",
    },
    {
        type: AlertBadgeType.Modification,
        class: "modification",
        icon: "equalizer-fill",
        tooltipKey: "datsLibAlert.alertBadges.modificationTooltip",
    },
    {
        type: AlertBadgeType.NotStarted,
        class: "not-started",
        icon: "timer-line",
        tooltipKey: "datsLibAlert.alertBadges.scheduledTooltip",
    },
] as const;
