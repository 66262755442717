import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { ButtonTheme, ConfirmationDialogComponent, DialogService, SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule, TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetDirective } from "@ngrx/component";
import { firstValueFrom, map } from "rxjs";
import { Alert, ALERT_BADGES, AlertBadgeType } from "../../models/alert.models";

const DEFAULT_HIDE_BAR_TIME = 5;
const DEFAULT_EXTEND_TIME = 15;

interface AlertsListItemComponentState {
    alert: Alert | undefined;
    alertsBadgeType: AlertBadgeType;
    isMenuOpen: boolean;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-alerts-list-item",
    standalone: true,
    imports: [CommonModule, LetDirective, SharedUiModule, SharedI18nModule, MatMenuModule, TranslocoModule],
    templateUrl: "./alerts-list-item.component.html",
    styleUrls: ["../alert-badges.scss", "./alerts-list-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AlertsListItemComponent {
    private readonly dialogService = inject(DialogService);
    private readonly transloco = inject(TranslocoService);

    @Input() public set alert(value: Alert) {
        this.localStore.patchState({ alert: value });
    }
    @Input() public set alertsBadgeType(value: AlertBadgeType) {
        this.localStore.patchState({ alertsBadgeType: value });
    }

    @Output() public readonly finish = new EventEmitter<Alert>();
    @Output() public readonly modify = new EventEmitter<Alert>();

    private readonly localStore = inject(LocalComponentStore<AlertsListItemComponentState>);
    protected readonly AlertBadgeType = AlertBadgeType;
    protected readonly DEFAULT_HIDE_BAR_TIME = DEFAULT_HIDE_BAR_TIME;
    protected readonly DEFAULT_EXTEND_TIME = DEFAULT_EXTEND_TIME;
    protected readonly alertBadgesByType: Record<AlertBadgeType, { class: string; icon: string }> = ALERT_BADGES.reduce(
        (result: Record<string, { class: string; icon: string }>, badge) => {
            result[badge.type] = badge;

            return result;
        },
        {}
    );
    protected readonly alert$ = this.localStore.selectByKey("alert");
    protected readonly alertsBadgeType$ = this.localStore.selectByKey("alertsBadgeType");
    protected readonly isMenuOpen$ = this.localStore.selectByKey("isMenuOpen");

    constructor() {
        this.localStore.setState({ alert: undefined, alertsBadgeType: AlertBadgeType.NotStarted, isMenuOpen: false });
    }

    protected onMenuOpened(): void {
        this.localStore.patchState({ isMenuOpen: true });
    }

    protected onMenuClosed(): void {
        this.localStore.patchState({ isMenuOpen: false });
    }

    protected hideBar(): void {
        // TODO DTATS-462
        alert("TODO:Hide bar");
    }

    protected hideBarUntilEnd(): void {
        // TODO DTATS-462
        alert("TODO:Hide bar until end");
    }

    protected extendAlertTime(): void {
        // TODO DTATS-462
        alert("TODO:Extend alert time");
    }

    protected modifyAlert() {
        this.modify.emit(this.localStore.selectSnapshotByKey("alert"));
    }

    protected async finishAlert() {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.transloco.translate("datsLibAlert.alertList.alertActions.finishConfirmation"),
                confirmationText: this.transloco.translate("datsLibAlert.alertList.alertActions.finishConfirmationText"),
                declineButtonLabel: this.transloco.translate("datsLibAlert.alertList.alertActions.finishDeclineButtonLabel"),
                confirmButtonLabel: this.transloco.translate("datsLibAlert.alertList.alertActions.finishConfirmButtonLabel"),
                theme: ButtonTheme.Warn,
            },
        });

        const result = await firstValueFrom(dialogRef.afterClosed().pipe(map(Boolean), untilDestroyed(this))).catch(() => false);

        if (result) {
            this.finish.emit(this.localStore.selectSnapshotByKey("alert"));
        }
    }

    protected sendMessage(): void {
        // TODO DTATS-458
        alert("TODO:Send message");
    }
}
