<ng-container
    *ngrxLet="{
        missionTypeOptions: missionTypeOptions$,
        appliedMissionTypeFilters: appliedMissionTypeFilters$,
        appliedTimeFilters: appliedTimeFilters$,
        missions: missions$,
        filteredMissions: filteredMissions$
    } as vm"
>
    <div class="filters-wrapper" *ngIf="vm.missions.length > 0">
        <div class="filter-wrapper">
            <div class="time-filter-label">
                {{ "datsLibFlights.missionList.typeFilterLabel" | transloco }}
            </div>
            <dats-lib-flights-filters
                selectAllValueLabel="datsLibFlights.flightsPanel.allFiltersLabel"
                [chipFilters]="vm.missionTypeOptions"
                [totalCount]="vm.missions.length"
                [appliedFilters]="vm.appliedMissionTypeFilters"
                (filtersChange)="applyMissionTypeFilters($event)"
            ></dats-lib-flights-filters>
        </div>

        <div class="filter-wrapper">
            <div class="time-filter-label">
                {{ "datsLibFlights.missionList.timeFilterLabel" | transloco }}
            </div>
            <dats-lib-flights-filters
                [chipFilters]="filterTimeOptions"
                [appliedFilters]="vm.appliedTimeFilters"
                (filtersChange)="applyTimeFilters($event)"
            ></dats-lib-flights-filters>
        </div>
    </div>

    <dats-lib-mission-item
        *ngFor="let mission of vm.filteredMissions; trackBy: trackByMissionId"
        [mission]="mission"
        class="mission-item"
        (extendedMissionDataOpen)="extendedMissionDataOpen.emit(mission.missionId)"
    ></dats-lib-mission-item>

    <div class="no-missions-label" *ngIf="vm.filteredMissions.length === 0">
        {{ "datsLibFlights.missionList.noMissionsLabel" | transloco }}
    </div>
</ng-container>
