import { Directive, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { LEAFLET_MAP_PROVIDER, LeafletMapProvider } from "@dtm-frontend/shared/map/leaflet";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Position } from "geojson";
import { DivIcon, GeoJSON, LatLngBounds, Map as LeafletMap, Marker, geoJSON } from "leaflet";
import { Jurisdiction } from "./../../../capabilities/models/capabilities.model";

const MIN_LINE_LENGTH_TO_SHOW_LABEL = 100;
const HALF_ANGLE_DEG = 180;
const JURISDICTION_AREA_COLOR = "#1477D5"; // NOTE: $color-secondary-700
const JURISDICTION_TEXT_LABEL_COLOR = "#FFFFFF"; // NOTE: $color-white
const FOG_OF_WAR_COLOR = "#061636"; // NOTE: $color-gray-900
const FOG_OF_WAR_OPACITY = 0.4;

interface FlightsLayerDirectiveState {
    jurisdiction: Jurisdiction | undefined;
    labelStartPosition: Position | undefined;
    labelEndPosition: Position | undefined;
}

@UntilDestroy()
@Directive({
    standalone: true,
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "dats-lib-jurisdiction-layer",
    providers: [LocalComponentStore],
})
export class JurisdictionLayerDirective implements OnInit, OnDestroy {
    @Input({ required: true }) public set jurisdiction(value: Jurisdiction | undefined) {
        this.localStore.patchState({ jurisdiction: value, labelStartPosition: undefined, labelEndPosition: undefined });
    }

    @Input() public set zoom(value: { bounds: LatLngBounds } | undefined) {
        if (value) {
            this.zoomToBounds(value.bounds);
        }
    }

    private readonly jurisdiction$ = this.localStore.selectByKey("jurisdiction");
    private jurisdictionArea: GeoJSON | undefined = undefined;
    private jurisdictionLabel: Marker | undefined = undefined;

    constructor(
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider,
        private readonly localStore: LocalComponentStore<FlightsLayerDirectiveState>
    ) {
        this.localStore.setState({
            jurisdiction: undefined,
            labelStartPosition: undefined,
            labelEndPosition: undefined,
        });
    }

    public async ngOnInit() {
        const map: LeafletMap = await this.mapProvider.getMap();

        map.on("zoomend", () => {
            this.updateLabelVisibility(map);
            if (this.jurisdictionArea) {
                this.jurisdictionArea.setStyle({ weight: this.getLineWeight(map.getZoom()) });
            }
        });

        this.jurisdiction$.pipe(untilDestroyed(this)).subscribe((jurisdiction) => {
            if (this.jurisdictionArea) {
                map.removeLayer(this.jurisdictionArea);
            }
            if (this.jurisdictionLabel) {
                map.removeLayer(this.jurisdictionLabel);
            }
            if (!jurisdiction?.geometry) {
                return;
            }

            this.jurisdictionArea = geoJSON(jurisdiction.geometry, {
                style: () => ({
                    color: JURISDICTION_AREA_COLOR,
                    weight: this.getLineWeight(map.getZoom()),
                    opacity: 1,
                    fillColor: FOG_OF_WAR_COLOR,
                    fillOpacity: FOG_OF_WAR_OPACITY,
                    interactive: false,
                }),
            });
            this.jurisdictionArea.addTo(map);

            this.jurisdictionLabel = this.createLabelForFirstJurisdictionPolygon(jurisdiction);
            if (this.jurisdictionLabel) {
                this.jurisdictionLabel.addTo(map);
            }
        });
    }

    public async ngOnDestroy() {
        // NOTE: when map will be removed by other source we can skip cleanup
        try {
            const map: LeafletMap = await this.mapProvider.getMap();

            map.off("zoomend");

            if (this.jurisdictionArea) {
                map.removeLayer(this.jurisdictionArea);
            }
            if (this.jurisdictionLabel) {
                map.removeLayer(this.jurisdictionLabel);
            }
        } catch (error) {}
    }

    private async zoomToBounds(bounds: LatLngBounds) {
        const map: LeafletMap = await this.mapProvider.getMap();
        map.fitBounds(bounds);
    }

    private createLabelForFirstJurisdictionPolygon(jurisdiction: Jurisdiction): Marker | undefined {
        if (!jurisdiction.name || !jurisdiction.geometry?.coordinates[1]) {
            return undefined;
        }

        return this.createLabel(
            jurisdiction.geometry.coordinates[1].flat(1).map((coordinate) => [coordinate[1], coordinate[0]]),
            jurisdiction.name
        );
    }

    private createLabel(coordinates: Position[], name: string): Marker {
        const northWestPointIndex = this.findNorthWestPointIndex(coordinates);
        const nextPointIndex = (northWestPointIndex + 1) % coordinates.length;
        const centerPoint = this.calculateCenterPoint(coordinates[northWestPointIndex], coordinates[nextPointIndex]);
        const angle = this.calculateAngleBetweenPoints(coordinates[northWestPointIndex], coordinates[nextPointIndex]);

        const labelIcon = new DivIcon({
            className: "jurisdiction-label",
            html: `<div style="
                background-color: ${JURISDICTION_AREA_COLOR}; 
                color: ${JURISDICTION_TEXT_LABEL_COLOR}; 
                border-radius: 4px; 
                font-size: 16px;
                white-space: nowrap;
                font-weight: 700;
                transform: translate(-50%, 0) rotate(${-angle}deg);
                padding: 0px 6px;
                display: inline-block;
            ">${name}</div>`,
            iconAnchor: [0, 0],
        });

        this.localStore.patchState({ labelStartPosition: coordinates[northWestPointIndex], labelEndPosition: coordinates[nextPointIndex] });

        return new Marker([centerPoint[0], centerPoint[1]], {
            icon: labelIcon,
            interactive: false,
        });
    }

    private updateLabelVisibility(map: LeafletMap): void {
        const labelStartPosition = this.localStore.selectSnapshotByKey("labelStartPosition");
        const labelEndPosition = this.localStore.selectSnapshotByKey("labelEndPosition");
        const element = this.jurisdictionLabel?.getElement();

        if (!element || !labelStartPosition || !labelEndPosition) {
            return;
        }

        const startPoint = map.latLngToContainerPoint([labelStartPosition[0], labelStartPosition[1]]);
        const endPoint = map.latLngToContainerPoint([labelEndPosition[0], labelEndPosition[1]]);
        const distance = startPoint.distanceTo(endPoint);
        element.style.display = distance > MIN_LINE_LENGTH_TO_SHOW_LABEL ? "block" : "none";
    }

    private findNorthWestPointIndex(coordinates: Position[]): number {
        let northWestPointIndex = 0;
        for (let index = 1; index < coordinates.length; index++) {
            if (
                coordinates[index][0] > coordinates[northWestPointIndex][0] ||
                (coordinates[index][0] === coordinates[northWestPointIndex][0] &&
                    coordinates[index][1] < coordinates[northWestPointIndex][1])
            ) {
                northWestPointIndex = index;
            }
        }

        return northWestPointIndex;
    }

    private calculateCenterPoint(pointLeft: Position, pointRight: Position): Position {
        return [(pointLeft[0] + pointRight[0]) / 2, (pointLeft[1] + pointRight[1]) / 2];
    }

    private calculateAngleBetweenPoints(pointLeft: Position, pointRight: Position): number {
        const deltaX = (pointRight[1] - pointLeft[1]) * Math.cos((pointLeft[0] * Math.PI) / HALF_ANGLE_DEG);
        const deltaY = pointRight[0] - pointLeft[0];

        return Math.atan2(deltaY, deltaX) * (HALF_ANGLE_DEG / Math.PI);
    }

    private getLineWeight(zoom: number): number {
        // NOTE: based on the zoom level, the line weight is different to avoid too thick lines on the map
        /* eslint-disable no-magic-numbers*/
        if (zoom <= 7) {
            return 2;
        }
        if (zoom <= 10) {
            return 3;
        }
        if (zoom <= 12) {
            return 4;
        }
        if (zoom === 13) {
            return 5;
        }
        if (zoom === 14) {
            return 6;
        }

        return 8;
        /* eslint-enable no-magic-numbers*/
    }
}
