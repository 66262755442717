import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { PushPipe } from "@ngrx/component";
import equal from "fast-deep-equal";
import { combineLatestWith, distinctUntilChanged, map } from "rxjs";
import { ActionSidebarFooterComponent, ActionSidebarHeaderComponent } from "../../../shared";

interface HeightFilterFormComponentState {
    initialHeightFilter: number | undefined;
}

export interface HeightFilterFormGroup {
    height: FormControl<number>;
    customHeight: FormControl<number>;
}

// eslint-disable-next-line no-magic-numbers
const PREDEFINED_HEIGHT_VALUES = [60, 90, 120];
const MAX_CHECKIN_HEIGHT = 120;
const HEIGHT_SLIDER_OPTIONS = {
    floor: 0,
    ceil: MAX_CHECKIN_HEIGHT,
    vertical: true,
    // eslint-disable-next-line no-magic-numbers
    tickStep: MAX_CHECKIN_HEIGHT / 4,
    showSelectionBar: true,
    showTicksValues: true,
    reversedControls: true,
};

@UntilDestroy()
@Component({
    selector: "dats-lib-height-filter-form",
    standalone: true,
    imports: [
        CommonModule,
        TranslocoModule,
        SharedUiModule,
        SharedI18nModule,
        PushPipe,
        ActionSidebarHeaderComponent,
        ActionSidebarFooterComponent,
        NgxSliderModule,
        ReactiveFormsModule,
    ],
    templateUrl: "./height-filter-form.component.html",
    styleUrls: ["../../../shared/components/sidebar/action-sidebar.scss", "./height-filter-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HeightFilterFormComponent {
    @Input({ required: true }) public set heightFilter(value: number | undefined) {
        this.prepareForm(value);
    }

    @Output() public readonly actionConfirm = new EventEmitter<number>();
    @Output() public readonly actionCancel = new EventEmitter<void>();

    protected heightFilterForm = new FormGroup<HeightFilterFormGroup>({
        height: new FormControl(0, { nonNullable: true }),
        customHeight: new FormControl(0, { nonNullable: true, validators: [Validators.max(MAX_CHECKIN_HEIGHT), Validators.min(0)] }),
    });

    protected readonly PREDEFINED_HEIGHT_VALUES = PREDEFINED_HEIGHT_VALUES;
    protected readonly HEIGHT_SLIDER_OPTIONS = HEIGHT_SLIDER_OPTIONS;

    @Output() public readonly isFormChanged$ = this.heightFilterForm.valueChanges.pipe(
        combineLatestWith(this.localStore.selectByKey("initialHeightFilter")),
        map(([formData, initialFormData]) => !equal(formData.height, initialFormData)),
        distinctUntilChanged()
    );

    @Output() public readonly isFormChanged = this.isFormChanged$;

    constructor(private readonly localStore: LocalComponentStore<HeightFilterFormComponentState>) {
        this.localStore.setState({
            initialHeightFilter: undefined,
        });

        this.listenOnCustomHeight();
    }

    protected confirm() {
        const heightValue = this.heightFilterForm.controls.height.value ?? 0;
        this.actionConfirm.emit(heightValue > 0 ? heightValue : undefined);
    }

    protected setHeightValue(value: number): void {
        this.heightFilterForm.controls.height.setValue(value, { emitEvent: false });
        this.heightFilterForm.controls.customHeight.setValue(value, { emitEvent: false });
    }

    protected restoreInitialData() {
        const initialHeightFilter = this.localStore.selectSnapshotByKey("initialHeightFilter");

        this.setHeightValue(initialHeightFilter ?? 0);
    }

    private prepareForm(filterHeight: number | undefined = 0) {
        this.localStore.patchState({ initialHeightFilter: filterHeight });
        this.setHeightValue(filterHeight);
    }

    private listenOnCustomHeight(): void {
        this.heightFilterForm.controls.customHeight.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.setHeightValue(value ?? 0);
        });
    }
}
