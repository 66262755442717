import { inject, Injectable } from "@angular/core";
import { AuthState } from "@dtm-frontend/shared/auth";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from "@ngxs/store";
import { catchError, EMPTY, finalize, tap } from "rxjs";
import {
    FlightCategory,
    FlightItem,
    FlightList,
    FlightsError,
    FlightsTab,
    FlightsTabType,
    JurisdictionMission,
} from "../models/flight.models";
import { FlightFilterType } from "../models/flights-filters.models";
import { FlightsApiService } from "../services/flights-api.service";
import { FlightsActions } from "./flights.actions";
import { getDefaultTabs, getFilteredFlightList, getFlightsTabs, mergeTabs } from "./flights.state.helpers";

export interface FlightsStateModel {
    error: FlightsError | undefined;
    isPanelProcessing: boolean;
    isActionProcessing: boolean;
    allFlights: FlightItem[] | undefined;
    tabs: FlightsTab[];
    filteredFlightList: FlightList | undefined;
    selectedTabType: FlightsTabType | undefined;
    selectedFlightId: string | undefined;
    appliedFilters: FlightFilterType[];
    isAtcController: boolean;
    jurisdictionMissions: JurisdictionMission[] | undefined;
}

export type CategorizedFlights = Partial<Record<FlightCategory, FlightItem[]>>;

const defaultState: FlightsStateModel = {
    error: undefined,
    isPanelProcessing: false,
    isActionProcessing: false,
    allFlights: undefined,
    tabs: getDefaultTabs(true), // TODO to implement roles in DTATS-59
    filteredFlightList: undefined,
    selectedTabType: undefined,
    selectedFlightId: undefined,
    appliedFilters: [],
    isAtcController: true, // TODO to implement roles in DTATS-59
    jurisdictionMissions: [],
};

const BGRIMM_ID = "b7da9da5-fe74-4023-aeba-c0285518eeca"; // TODO to implement roles in DTATS-59

@State<FlightsStateModel>({
    name: "flights",
    defaults: defaultState,
})
@UntilDestroy()
@Injectable()
export class FlightsState implements NgxsOnInit {
    private readonly store = inject(Store);

    @Selector()
    public static error(state: FlightsStateModel): FlightsError | undefined {
        return state.error;
    }

    @Selector()
    public static isPanelProcessing(state: FlightsStateModel): boolean {
        return state.isPanelProcessing;
    }

    @Selector()
    public static isActionProcessing(state: FlightsStateModel): boolean {
        return state.isActionProcessing;
    }

    @Selector()
    public static allFlights(state: FlightsStateModel): FlightItem[] | undefined {
        return state.allFlights;
    }

    @Selector()
    public static selectedFlightId(state: FlightsStateModel): string | undefined {
        return state.selectedFlightId;
    }

    @Selector()
    public static appliedFilters(state: FlightsStateModel): FlightFilterType[] {
        return state.appliedFilters;
    }

    @Selector()
    public static isAtcController(state: FlightsStateModel): boolean {
        return state.isAtcController;
    }

    @Selector()
    public static selectedTabType(state: FlightsStateModel): FlightsTabType | undefined {
        return state.selectedTabType;
    }

    @Selector()
    public static filteredFlightList(state: FlightsStateModel): FlightList | undefined {
        return state.filteredFlightList;
    }

    @Selector()
    public static tabs(state: FlightsStateModel) {
        return state.tabs;
    }

    @Selector()
    public static jurisdictionMissions(state: FlightsStateModel) {
        return state.jurisdictionMissions;
    }

    constructor(private readonly flightsApi: FlightsApiService) {}

    public ngxsOnInit(context: StateContext<FlightsStateModel>) {
        this.store
            .select(AuthState.userId)
            .pipe(untilDestroyed(this))
            .subscribe((userId) => {
                const isAtcController = userId === BGRIMM_ID; // TODO to implement roles in DTATS-59
                context.patchState({
                    isAtcController: isAtcController,
                    tabs: getDefaultTabs(isAtcController),
                });
            });
    }

    @Action(FlightsActions.GetFlights)
    public getFlights(context: StateContext<FlightsStateModel>) {
        context.patchState({ isPanelProcessing: true, error: undefined });

        return this.flightsApi.getFlights().pipe(
            tap((flightItems: FlightItem[]) => {
                const state = context.getState();
                context.patchState({
                    isPanelProcessing: false,
                    allFlights: flightItems,
                    filteredFlightList: getFilteredFlightList(
                        flightItems,
                        state.isAtcController,
                        state.selectedTabType,
                        state.appliedFilters
                    ),
                    tabs: mergeTabs(state.tabs, getFlightsTabs(flightItems, state.isAtcController)),
                });

                const selectedFlightId = state.selectedFlightId;
                if (flightItems && selectedFlightId) {
                    const doSelectedFlightStillExist = flightItems.some((flight) => flight.id === selectedFlightId);

                    if (!doSelectedFlightStillExist) {
                        context.patchState({ selectedFlightId: undefined });
                    }
                }
            }),
            catchError((error) => {
                context.patchState({
                    isPanelProcessing: false,
                    error,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightsActions.GetMissions)
    public getMissions(context: StateContext<FlightsStateModel>) {
        context.patchState({ isPanelProcessing: true, error: undefined });

        return this.flightsApi.getJurisdictionMissions().pipe(
            tap((response) => {
                const state = context.getState();
                context.patchState({
                    isPanelProcessing: false,
                    jurisdictionMissions: response,
                    tabs: mergeTabs(state.tabs, [
                        {
                            type: FlightsTabType.Mission,
                            total: response.length,
                            isActionRequired: false,
                        },
                    ]),
                });
            }),
            catchError((error) => {
                context.patchState({
                    isPanelProcessing: false,
                    error,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightsActions.SelectFlightsTab)
    public selectFlightsTab(context: StateContext<FlightsStateModel>, action: FlightsActions.SelectFlightsTab) {
        const state = context.getState();
        const filteredFlightList: FlightList | undefined = !state.allFlights
            ? undefined
            : getFilteredFlightList(state.allFlights, state.isAtcController, action.tabType, state.appliedFilters);

        context.patchState({
            selectedTabType: action.tabType,
            filteredFlightList,
            selectedFlightId:
                !action.tabType || filteredFlightList?.visibleFlights.some((flight) => flight.id === state.selectedFlightId)
                    ? state.selectedFlightId
                    : undefined,
        });
    }

    @Action(FlightsActions.SelectFlight)
    public selectFlight(context: StateContext<FlightsStateModel>, action: FlightsActions.SelectFlight) {
        const state = context.getState();
        context.patchState({
            selectedFlightId: action.flightId,
        });

        if (action.flightId && !state.filteredFlightList?.visibleFlights.some((flight) => flight.id === action.flightId)) {
            context.dispatch(new FlightsActions.SelectFlightsTab(undefined));
        }
    }

    @Action(FlightsActions.ApplyFilters)
    public applyFilters(context: StateContext<FlightsStateModel>, action: FlightsActions.ApplyFilters) {
        const state = context.getState();
        context.patchState({
            appliedFilters: action.filters,
            filteredFlightList: !state.allFlights
                ? undefined
                : getFilteredFlightList(state.allFlights, state.isAtcController, state.selectedTabType, action.filters),
        });
    }

    @Action(FlightsActions.ClearCategorizedFlights)
    public clearCategorizedFlights(context: StateContext<FlightsStateModel>) {
        context.patchState({ allFlights: undefined, selectedFlightId: undefined });
    }

    @Action(FlightsActions.StandbyCheckin)
    public standbyCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.StandbyCheckin) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.standbyCheckin(action.checkinId, action.duration).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.ModifyCheckin)
    public modifyCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.ModifyCheckinAndAccept) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.modifyCheckin(action.checkinId, action.formValues).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.ModifyCheckinAndAccept)
    public modifyCheckinAndAccept(context: StateContext<FlightsStateModel>, action: FlightsActions.ModifyCheckinAndAccept) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.modifyCheckinAndAccept(action.checkinId, action.formValues).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.AcceptCheckin)
    public acceptCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.AcceptCheckin) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.acceptCheckin(action.checkinId).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.RejectCheckin)
    public rejectCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.RejectCheckin) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.rejectCheckin(action.checkinId).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.StopCheckin)
    public stopCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.StopCheckin) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.stopCheckin(action.checkinId).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.ArchiveCheckin)
    public archiveCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.ArchiveCheckin) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.archiveCheckin(action.checkinId, action.formValues).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.ResendCheckinConfirmation)
    public resendCheckinConfirmation(context: StateContext<FlightsStateModel>, action: FlightsActions.ResendCheckinConfirmation) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.resendCheckinConfirmation(action.checkinId).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }
}
