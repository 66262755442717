<ng-container *ngrxLet="{ selectedAlertGeometrySourceType: selectedAlertGeometrySourceType$ } as vm">
    <div
        *ngIf="
            vm.selectedAlertGeometrySourceType === AlertGeometrySourceType.AreaCircle ||
            vm.selectedAlertGeometrySourceType === AlertGeometrySourceType.AreaPolygon ||
            vm.selectedAlertGeometrySourceType === AlertGeometrySourceType.AreaCorridor
        "
        @slideInSideOffScreenUp
        @fade
        class="panel"
    >
        <div class="geometry-types">
            <button
                type="button"
                class="button-secondary geometry-type-button"
                [class.selected]="vm.selectedAlertGeometrySourceType === AlertGeometrySourceType.AreaCircle"
                (click)="alertGeometrySourceTypeChange.emit(AlertGeometrySourceType.AreaCircle)"
            >
                <dtm-ui-icon name="pen-nib-oval"></dtm-ui-icon>
                <span>{{ "datsLibAlert.alertGeometryPanel.circleLabel" | transloco }}</span>
            </button>
            <button
                type="button"
                class="button-secondary geometry-type-button"
                [class.selected]="vm.selectedAlertGeometrySourceType === AlertGeometrySourceType.AreaPolygon"
                (click)="alertGeometrySourceTypeChange.emit(AlertGeometrySourceType.AreaPolygon)"
            >
                <dtm-ui-icon name="pen-nib-prism"></dtm-ui-icon>
                <span>{{ "datsLibAlert.alertGeometryPanel.polygonLabel" | transloco }}</span>
            </button>
            <button
                type="button"
                class="button-secondary geometry-type-button"
                [class.selected]="vm.selectedAlertGeometrySourceType === AlertGeometrySourceType.AreaCorridor"
                (click)="alertGeometrySourceTypeChange.emit(AlertGeometrySourceType.AreaCorridor)"
            >
                <dtm-ui-icon name="pen-nib-line"></dtm-ui-icon>
                <span>{{ "datsLibAlert.alertGeometryPanel.corridorLabel" | transloco }}</span>
            </button>
        </div>
        <button type="button" class="button-icon" (click)="alertGeometrySourceTypeChange.emit(undefined)">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
</ng-container>
