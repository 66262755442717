import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { WGS84Coordinates } from "@dtm-frontend/shared/map/leaflet";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { LetDirective } from "@ngrx/component";
import { Store } from "@ngxs/store";
import equal from "fast-deep-equal";
import { map } from "rxjs";
import { AlertErrorType, AlertType, Zone } from "../../models/alert.models";
import { AlertActions } from "../../state/alert.actions";
import { AlertState } from "../../state/alert.state";

interface ZonesContextMenuComponentState {
    coordinates: WGS84Coordinates | undefined;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-alert-zones-context-menu",
    templateUrl: "./zones-context-menu.component.html",
    styleUrls: ["./zones-context-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    standalone: true,

    imports: [CommonModule, LetDirective, MatMenuModule, MatButtonModule, MatProgressSpinnerModule, SharedI18nModule, SharedUiModule],
})
export class ZonesContextMenuComponent {
    @Input() public set coordinates(value: WGS84Coordinates | undefined) {
        const currentCoordinates = this.localStore.selectSnapshotByKey("coordinates");

        if (equal(currentCoordinates, value) || !value) {
            return;
        }

        this.localStore.patchState({ coordinates: value });
        this.store.dispatch(new AlertActions.GetZonesForCoordinates(value));
    }

    @Output() public readonly createAlert = new EventEmitter<{ type: AlertType; zone: Zone }>();

    protected readonly zones$ = this.store.select(AlertState.zonesForCoordinates);
    protected readonly isProcessing$ = this.store.select(AlertState.isZonesForCoordinatesProcessing);
    protected readonly hasError$ = this.store
        .select(AlertState.error)
        .pipe(map((error) => error?.type === AlertErrorType.CannotGetZonesForCoordinates));

    protected readonly AlertType = AlertType;

    constructor(private readonly localStore: LocalComponentStore<ZonesContextMenuComponentState>, private readonly store: Store) {
        this.localStore.setState({
            coordinates: undefined,
        });
    }
}
