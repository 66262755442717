/*
    NOTE: This code is based on https://github1s.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/leaflet.markercluster/index.d.ts
*/

/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="./leaflet.markercluster.d.ts" />

import {
    DivIcon,
    FeatureGroup,
    FitBoundsOptions,
    Icon,
    LatLngBounds,
    Layer,
    LayerGroup,
    LayerOptions,
    LeafletEvent,
    Marker,
    Point,
    PolylineOptions,
} from "leaflet";
import * as LeafletMarkerCluster from "leaflet.markercluster";

export interface MarkerCluster extends Marker {
    _group: MarkerClusterGroup;

    /**
     * Recursively retrieve all child markers of this cluster.
     */
    getAllChildMarkers(): Marker[];

    /**
     * Returns the count of how many child markers we have.
     */
    getChildCount(): number;

    /**
     * Zoom to the minimum of showing all of the child markers, or the extents of this cluster.
     */
    zoomToBounds(options?: FitBoundsOptions): void;

    /**
     * Returns the cluster bounds.
     */
    getBounds(): LatLngBounds;

    /**
     * Spiderfies the child markers of this cluster.
     */
    spiderfy(): void;

    /**
     * Unspiderfies a cluster (opposite of spiderfy).
     */
    unspiderfy(): void;
}

export interface MarkerClusterGroupOptions extends LayerOptions {
    /**
     * The maximum radius that a cluster will cover from the central marker (in pixels). Default 80.
     * Decreasing will make more, smaller clusters. You can also use a function that accepts
     * the current map zoom and returns the maximum cluster radius in pixels
     */
    maxClusterRadius?: number | ((zoom: number) => number) | undefined;

    /**
     * Function used to create the cluster icon
     */
    iconCreateFunction?: ((cluster: MarkerCluster) => Icon | DivIcon) | undefined;

    /**
     * Map pane where the cluster icons will be added.
     * Defaults to L.Marker's default (currently 'markerPane')
     */
    clusterPane?: string | undefined;

    /**
     * When you click a cluster at any zoom level we spiderfy it
     * so you can see all of its markers.
     */
    spiderfyOnEveryZoom?: boolean | undefined;

    /**
     * When you click a cluster at the bottom zoom level we spiderfy it
     * so you can see all of its markers.
     */
    spiderfyOnMaxZoom?: boolean | undefined;

    /**
     * When you mouse over a cluster it shows the bounds of its markers.
     */
    showCoverageOnHover?: boolean | undefined;

    /**
     * When you click a cluster we zoom to its bounds.
     */
    zoomToBoundsOnClick?: boolean | undefined;

    /**
     * If set to true, overrides the icon for all added markers to make them appear as a 1 size cluster.
     */
    singleMarkerMode?: boolean | undefined;

    /**
     * If set, at this zoom level and below markers will not be clustered. This defaults to disabled.
     */
    disableClusteringAtZoom?: number | undefined;

    /**
     * Clusters and markers too far from the viewport are removed from the map
     * for performance.
     */
    removeOutsideVisibleBounds?: boolean | undefined;

    /**
     * Smoothly split / merge cluster children when zooming and spiderfying.
     * If L.DomUtil.TRANSITION is false, this option has no effect (no animation is possible).
     */
    animate?: boolean | undefined;

    /**
     * If set to true (and animate option is also true) then adding individual markers to the
     * MarkerClusterGroup after it has been added to the map will add the marker and animate it
     * into the cluster. Defaults to false as this gives better performance when bulk adding markers.
     * addLayers does not support this, only addLayer with individual Markers.
     */
    animateAddingMarkers?: boolean | undefined;

    /**
     * Custom function to calculate spiderfy shape positions or "original" to use the original marker positions.
     */
    spiderfyShapePositions?: ((count: number, centerPoint: Point) => Point[] | "original") | undefined | "original";

    /**
     * Increase from 1 to increase the distance away from the center that spiderfied markers are placed.
     * Use if you are using big marker icons (Default: 1).
     */
    spiderfyDistanceMultiplier?: number | undefined;

    /**
     * Allows you to specify PolylineOptions to style spider legs.
     * By default, they are { weight: 1.5, color: '#222', opacity: 0.5 }.
     */
    spiderLegPolylineOptions?: PolylineOptions | undefined;

    /**
     * Boolean to split the addLayers processing in to small intervals so that the page does not freeze.
     */
    chunkedLoading?: boolean | undefined;

    /**
     * Time delay (in ms) between consecutive periods of processing for addLayers. Default to 50ms.
     */
    chunkDelay?: number | undefined;

    /**
     * Time interval (in ms) during which addLayers works before pausing to let the rest of the page process.
     * In particular, this prevents the page from freezing while adding a lot of markers. Defaults to 200ms.
     */
    chunkInterval?: number | undefined;

    /**
     * Callback function that is called at the end of each chunkInterval.
     * Typically used to implement a progress indicator. Defaults to null.
     */
    chunkProgress?: ((processedMarkers: number, totalMarkers: number, elapsedTime: number) => void) | undefined;

    /**
     * Options to pass when creating the L.Polygon(points, options) to show the bounds of a cluster.
     * Defaults to empty
     */
    polygonOptions?: PolylineOptions | undefined;
}

/**
 * Cluster-related handler functions.
 */
export type AnimationEndEventHandlerFn = (event: LeafletEvent) => void;
export type SpiderfyEventHandlerFn = (event: MarkerClusterSpiderfyEvent) => void;

/**
 * Event fired on spiderfy cluster actions.
 */
export interface MarkerClusterSpiderfyEvent extends LeafletEvent {
    /**
     * The cluster that fired the event.
     */
    cluster: MarkerCluster;

    /**
     * The markers in the cluster that fired the event.
     */
    markers: Marker[];
}

/**
 * Extend existing event handler function map to include cluster events.
 */
export interface LeafletEventHandlerFnMap {
    /**
     * Fires when overlapping markers get spiderified.
     */
    spiderfied?: SpiderfyEventHandlerFn | undefined;

    /**
     * Fires when overlapping markers get unspiderified.
     */
    unspiderfied?: SpiderfyEventHandlerFn | undefined;

    /**
     * Fires when marker clustering/unclustering animation has completed.
     */
    animationend?: AnimationEndEventHandlerFn | undefined;
}

/**
 * Extend Evented to include cluster events.
 */
export interface Evented {
    on(type: "spiderfied" | "unspiderfied", fn?: SpiderfyEventHandlerFn, context?: any): this;
    on(type: "animationend", fn?: AnimationEndEventHandlerFn, context?: any): this;

    off(type: "spiderfied" | "unspiderfied", fn?: SpiderfyEventHandlerFn, context?: any): this;
    off(type: "animationend", fn?: AnimationEndEventHandlerFn, context?: any): this;

    once(type: "spiderfied" | "unspiderfied", fn?: SpiderfyEventHandlerFn, context?: any): this;
    once(type: "animationend", fn?: AnimationEndEventHandlerFn, context?: any): this;

    addEventListener(type: "spiderfied" | "unspiderfied", fn?: SpiderfyEventHandlerFn, context?: any): this;
    addEventListener(type: "animationend", fn?: AnimationEndEventHandlerFn, context?: any): this;

    removeEventListener(type: "spiderfied" | "unspiderfied", fn?: SpiderfyEventHandlerFn, context?: any): this;
    removeEventListener(type: "animationend", fn?: AnimationEndEventHandlerFn, context?: any): this;

    addOneTimeEventListener(type: "spiderfied" | "unspiderfied", fn?: SpiderfyEventHandlerFn, context?: any): this;
    addOneTimeEventListener(type: "animationend", fn?: AnimationEndEventHandlerFn, context?: any): this;
}

export declare class MarkerClusterGroup extends FeatureGroup {
    public options: MarkerClusterGroupOptions;

    constructor(options?: MarkerClusterGroupOptions);
    public addLayers(layers: Layer[], skipLayerAddEvent?: boolean): this;
    public removeLayers(layers: Layer[]): this;
    public clearLayers(): this;
    public getVisibleParent(marker: Marker): Marker;
    public refreshClusters(clusters?: Marker | Marker[] | LayerGroup | { [index: string]: Layer }): this;
    public getChildCount(): number;
    public getAllChildMarkers(): Marker[];
    public hasLayer(layer: Layer): boolean;
    public zoomToShowLayer(layer: Layer, callback?: () => void): void;
}

// NOTE: These overrides are extending the spiderfy mechanism to use the original marker positions.
/* eslint-disable no-underscore-dangle */
const originalSpiderfy = LeafletMarkerCluster.MarkerCluster.prototype.spiderfy;
const originalAnimationSpiderfy = LeafletMarkerCluster.MarkerCluster.prototype._animationSpiderfy;
LeafletMarkerCluster.MarkerCluster.prototype.spiderfy = function (this: MarkerCluster): void {
    if (this._group.options.spiderfyShapePositions === "original") {
        this._group.options.spiderfyShapePositions = () => "original";
    }

    originalSpiderfy.call(this);
};

LeafletMarkerCluster.MarkerCluster.prototype._animationSpiderfy = function (
    this: MarkerCluster,
    markers: Marker[],
    positions: Point[] | "original"
): void {
    if (positions === "original") {
        positions = markers.map((marker) => this._map.latLngToLayerPoint(marker.getLatLng()));
    }

    originalAnimationSpiderfy.call(this, markers, positions);
};
/* eslint-enable no-underscore-dangle */

export const createMarkerClusterGroup = (options?: MarkerClusterGroupOptions): MarkerClusterGroup =>
    new LeafletMarkerCluster.MarkerClusterGroup(options) as MarkerClusterGroup;
