<ng-container *ngrxLet="{ isAtcController: isAtcController$, selectedAlertGeometrySourceType: selectedAlertGeometrySourceType$ } as vm">
    <div class="block">
        <dats-lib-flights-navbar-clock />
    </div>
    <div class="block">
        <dats-lib-alert-select-button
            [isDisabled]="!!vm.selectedAlertGeometrySourceType"
            (alertGeometrySourceTypeChange)="changeAlertGeometrySourceType($event)"
        />
    </div>
</ng-container>
