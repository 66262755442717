<ng-container
    *ngrxLet="{
        operation: operation$,
        modification: modification$,
        isEmergency: isEmergency$,
        isDataAcknowledged: isDataAcknowledged$
    } as vm"
>
    <div class="row" [class.data-emergency]="vm.isEmergency" [class.modification-proposed]="!vm.isDataAcknowledged">
        <div class="column">
            <dtm-ui-icon name="time-utc"></dtm-ui-icon>
            <dtm-ui-time-range-display
                [startTime]="vm.operation.plannedStartAt"
                [endTime]="vm.operation.plannedEndAt"
                timeZone="UTC"
                [matTooltip]="
                    'datsLibFlights.timeHeightDisplay.timeDisplayTooltip'
                        | transloco
                            : {
                                  dateFrom: vm.operation.plannedStartAt | localizeDate : localTimezoneLocalizeDateConfig,
                                  dateTo: vm.operation.plannedEndAt | localizeDate : localTimezoneLocalizeDateConfig,
                                  dateFromUtc: vm.operation.plannedStartAt | localizeDate : utcLocalizeDateConfig,
                                  dateToUtc: vm.operation.plannedEndAt | localizeDate : utcLocalizeDateConfig
                              }
                "
                matTooltipClass="time-display-tooltip"
            ></dtm-ui-time-range-display>
        </div>
        <div class="column">
            <dats-lib-height-label
                [height]="vm.operation.geography.maxHeight"
                [elevation]="vm.operation.geography.maxElevation"
                isMaxHeight
            ></dats-lib-height-label>
        </div>
    </div>

    <div
        *ngIf="vm.modification && !vm.isEmergency"
        class="row no-icon"
        [ngClass]="vm.isDataAcknowledged ? 'modification-acknowledged' : 'modification-not-acknowledged'"
    >
        <div>
            <dtm-ui-time-range-display
                [startTime]="vm.modification.initial.plannedStartAt"
                [endTime]="vm.modification.initial.plannedEndAt"
                timeZone="UTC"
                *ngIf="vm.modification.isTimeModified"
            ></dtm-ui-time-range-display>
        </div>
        <span class="height-label" *ngIf="vm.modification.isHeightModified">
            <dats-lib-height-label
                [isIconDisplayed]="false"
                [height]="vm.modification.initial.maxHeight"
                [elevation]="vm.operation.geography.maxElevation"
                isMaxHeight
            ></dats-lib-height-label>
        </span>
    </div>
</ng-container>
