import { GeoJSON } from "@dtm-frontend/shared/ui";
import { Logger } from "@dtm-frontend/shared/utils";
import { LatLngBounds, LatLngTuple } from "leaflet";
import { GeoJSON as OpenLayerGeoJSON } from "ol/format";
import WKB from "ol/format/WKB";
import { Geometry, MultiPolygon } from "ol/geom";
import { Capabilities, DATSFeature, Grid, GridType, Jurisdiction } from "../models/capabilities.model";

export interface CapabilitiesResponseBody {
    permissions: DATSFeature[];
    jurisdiction: JurisdictionResponseBody;
    grid: GridResponseBody;
}

export interface JurisdictionResponseBody {
    "@type": string;
    data: string;
    name?: string;
}

export interface GridResponseBody {
    type: GridType;
    geometry: {
        "@type": "MutliPolygon";
        data: string;
    };
}

/* eslint-disable no-magic-numbers*/
const MAP_BOUNDS = [
    [
        [-90, -180],
        [-90, 180],
        [90, 180],
        [90, -180],
        [-90, -180],
    ],
];
/* eslint-enable no-magic-numbers*/

function convertWkbToMultiPolygon(wkbHex: string) {
    const wkbBuffer = new WKB();
    const geometry = wkbBuffer.readGeometry(wkbHex);

    if (geometry instanceof MultiPolygon) {
        return geometry;
    }

    Logger.captureException("Capabilities geometry is not a MultiPolygon", { extra: { geometry } });

    return undefined;
}

function convertMultiPolygonToLatLngBounds(multiPolygon: MultiPolygon) {
    const rawCoordinates = multiPolygon.getCoordinates().flat(2);
    const latLngTuples: LatLngTuple[] = rawCoordinates.map((coordinate) => [+coordinate[1], +coordinate[0]]);

    return new LatLngBounds(latLngTuples);
}

function convertOpenLayersGeometryToGeoJSON(geometry: Geometry): GeoJSON.MultiPolygon {
    const format = new OpenLayerGeoJSON();

    return JSON.parse(format.writeGeometry(geometry));
}

export function convertGridResponseBodyToGrid(response: GridResponseBody): Grid {
    const gridGeometry = convertWkbToMultiPolygon(response.geometry.data);

    return {
        type: response.type,
        geometry: gridGeometry ? convertOpenLayersGeometryToGeoJSON(gridGeometry) : undefined,
    };
}

export function convertJurisdictionResponseBodyToJurisdiction(response: JurisdictionResponseBody): Jurisdiction {
    const geometry = convertWkbToMultiPolygon(response.data);
    const jurisdictionGeometry = geometry ? convertOpenLayersGeometryToGeoJSON(geometry) : undefined;
    if (jurisdictionGeometry) {
        jurisdictionGeometry.coordinates = [MAP_BOUNDS, ...jurisdictionGeometry.coordinates];
    }

    return {
        data: response.data,
        bounds: geometry ? convertMultiPolygonToLatLngBounds(geometry) : undefined,
        geometry: jurisdictionGeometry ? jurisdictionGeometry : undefined,
    };
}

export function convertCapabilitiesResponseBodyToCapabilities(response: CapabilitiesResponseBody): Capabilities {
    return {
        permissions: response.permissions,
        jurisdiction: convertJurisdictionResponseBodyToJurisdiction(response.jurisdiction),
        grid: convertGridResponseBodyToGrid(response.grid),
    };
}
