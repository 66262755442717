import { AlertGeometrySourceType, AlertPointOfInterest, AlertType, LocationAlertEntity } from "../models/alert.models";

export namespace AlertActions {
    export class SelectAlertGeometrySourceType {
        public static readonly type = "[Alert] Select alert type";
        constructor(public type: AlertGeometrySourceType | undefined) {}
    }

    export class GetAlerts {
        public static readonly type = "[Alert] Get alerts";
    }

    export class GetAlertProposals {
        public static readonly type = "[Alert] Get alert proposals";
        constructor(public type: AlertType, public poiId?: string, public designator?: string) {}
    }

    export class CreateAlert {
        public static readonly type = "[Alert] Create alert";
        constructor(public alertEntity: LocationAlertEntity) {}
    }

    export class GetAllHospitals {
        public static readonly type = "[Alert] Get all hospitals";
    }

    export class GetAllHemsBases {
        public static readonly type = "[Alert] Get all HEMS bases";
    }

    export class GetAllZones {
        public static readonly type = "[Alert] Get all zones";
    }

    export class GetAllPois {
        public static readonly type = "[Alert] Get all POIs";
    }

    export class SetBookmarkedHospitals {
        public static readonly type = "[Alert] Set bookmarked hospitals";
        constructor(public newBookmarkedHospitals: AlertPointOfInterest[]) {}
    }
}
