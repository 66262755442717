import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

const DATS_JSON_CONTENT_TYPE = "application/vnd.pansa.dats.v1+json";

@Injectable()
export class DroneAirTrafficServicesInterceptor implements HttpInterceptor {
    public intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler) {
        const headers = httpRequest.headers.set("content-type", DATS_JSON_CONTENT_TYPE);

        const modifiedRequest = httpRequest.clone({
            headers,
        });

        return next.handle(modifiedRequest);
    }
}
