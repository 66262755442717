<ng-container *ngrxLet="{ isTimeExceeded: isTimeExceeded$ } as vm">
    <dats-lib-action-sidebar-header
        [headerText]="'datsLibFlights.standbyCheckinForm.actionSidebarHeader' | transloco"
        (actionCancel)="actionCancel.emit()"
    >
    </dats-lib-action-sidebar-header>

    <div class="action-sidebar-content scroll-shadows" *ngrxLet="{ isTimeExceeded: isTimeExceeded$ } as vm">
        <dats-lib-operation-details [flight]="flight$ | ngrxPush"></dats-lib-operation-details>
        <h2 class="section-header">{{ "datsLibFlights.standbyCheckinForm.sectionHeader" | transloco }}</h2>
        <div class="time-offset">
            <dtm-ui-expanded-number-input
                [formControl]="durationControl"
                [minValue]="0"
                [stepValue]="1"
                [valueSuffix]="'datsLibFlights.standbyCheckinForm.controlSuffix' | transloco"
                [predefinedOptions]="PREDEFINED_TIME_OFFSET"
            >
                <label>{{ "datsLibFlights.standbyCheckinForm.controlLabel" | transloco }}</label>
            </dtm-ui-expanded-number-input>
        </div>
        <div class="info">
            <p class="text" *ngIf="!vm.isTimeExceeded">{{ "datsLibFlights.standbyCheckinForm.rejectInfo.offsetText" | transloco }}</p>
            <ng-container *ngIf="vm.isTimeExceeded">
                <p class="warning">{{ "datsLibFlights.standbyCheckinForm.rejectInfo.main" | transloco }}</p>
                <p class="text">{{ "datsLibFlights.standbyCheckinForm.rejectInfo.rejectText" | transloco }}</p>
            </ng-container>
        </div>
    </div>

    <dats-lib-action-sidebar-footer
        (actionCancel)="actionCancel.emit()"
        (actionConfirm)="confirm()"
        [confirmButtonTheme]="ButtonTheme.Warn"
        [confirmButtonText]="'datsLibFlights.standbyCheckinForm.actionButtonLabel' | transloco : { value: vm.isTimeExceeded }"
    >
        <button *ngIf="hasFormChanged$ | ngrxPush" type="button" class="button-secondary" (click)="restoreInitialData()">
            <dtm-ui-icon name="refresh"></dtm-ui-icon>
            {{ "datsLibFlights.actionSidebar.restoreButtonLabel" | transloco }}
        </button>
    </dats-lib-action-sidebar-footer>
</ng-container>
