import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule, TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { TranslocoModule } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

const MAX_DESCRIPTION_LENGTH = 200;

const ARCHIVE_OPTIONS = [
    {
        id: "datsLibFlights.archiveOptions.flightError.id",
        description: "datsLibFlights.archiveOptions.flightError.description",
    },
    {
        id: "datsLibFlights.archiveOptions.pilotError.id",
        description: "datsLibFlights.archiveOptions.pilotError.description",
    },
    {
        id: "datsLibFlights.archiveOptions.flightCompleted.id",
        description: "datsLibFlights.archiveOptions.flightCompleted.description",
    },
    {
        id: "datsLibFlights.archiveOptions.applicationError.id",
        description: "datsLibFlights.archiveOptions.applicationError.description",
    },
    {
        id: "datsLibFlights.archiveOptions.mapIssue.id",
        description: "datsLibFlights.archiveOptions.mapIssue.description",
    },
    {
        id: "datsLibFlights.archiveOptions.other.id",
        description: "datsLibFlights.archiveOptions.other.id",
    },
];

@UntilDestroy()
@Component({
    selector: "dats-lib-archive-dialog.component",
    standalone: true,
    imports: [CommonModule, SharedI18nModule, SharedUiModule, TranslocoModule, MatDialogModule, ReactiveFormsModule, MatInputModule],
    templateUrl: "./archive-dialog.component.html",
    styleUrls: ["./archive-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArchiveDialogComponent {
    private readonly dialogRef = inject(MatDialogRef<ArchiveDialogComponent>);
    private readonly translationHelperService = inject(TranslationHelperService);

    protected ARCHIVE_OPTIONS = ARCHIVE_OPTIONS;
    protected readonly form = new FormGroup({
        id: new FormControl(""),
        description: new FormControl("", {
            validators: [Validators.required, Validators.maxLength(MAX_DESCRIPTION_LENGTH)],
        }),
    });

    constructor() {
        this.form.controls.id.valueChanges.pipe(untilDestroyed(this)).subscribe((id) => {
            const archiveOption = ARCHIVE_OPTIONS.find((option) => option.id === id);
            if (!archiveOption) {
                return;
            }
            this.form.controls.description.patchValue(this.translationHelperService.tryTranslate(archiveOption.description) || "");
        });

        this.translationHelperService
            .waitForTranslation(ARCHIVE_OPTIONS[0].id)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.form.controls.id.patchValue(ARCHIVE_OPTIONS[0].id);
            });
    }

    protected submit() {
        if (this.form.invalid) {
            this.form.markAsTouched();

            return;
        }
        this.dialogRef.close(this.form.value);
    }
}
