import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ButtonTheme } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, MINUTES_IN_HOUR } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { distinctUntilChanged, map } from "rxjs";
import { FlightItem } from "../../models/flight.models";

// eslint-disable-next-line no-magic-numbers
const PREDEFINED_TIME_OFFSET = [5, 15, 30, 59];

const INITIAL_STANDBY_TIME = 10;

interface StandbyCheckinFormComponentState {
    isTimeExceeded: boolean;
    flight: FlightItem | undefined;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-standby-checkin-form",
    templateUrl: "./standby-checkin-form.component.html",
    styleUrls: ["../../../shared/components/sidebar/action-sidebar.scss", "./standby-checkin-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class StandbyCheckinFormComponent {
    @Input({ required: true }) public set flight(value: FlightItem) {
        this.localStore.patchState({ flight: value });
    }
    @Output() public readonly actionConfirm = new EventEmitter<{ id: string; duration: number; isTimeExceeded: boolean }>();
    @Output() public readonly actionCancel = new EventEmitter<void>();
    @Output() public readonly isFormChanged = new EventEmitter<boolean>();

    protected readonly ButtonTheme = ButtonTheme;
    protected readonly PREDEFINED_TIME_OFFSET = PREDEFINED_TIME_OFFSET;
    protected readonly isTimeExceeded$ = this.localStore.selectByKey("isTimeExceeded");
    protected readonly flight$ = this.localStore.selectByKey("flight");
    protected readonly durationControl = new FormControl<number>(INITIAL_STANDBY_TIME, { nonNullable: true });
    protected readonly hasFormChanged$ = this.durationControl.valueChanges.pipe(
        map((value) => value !== INITIAL_STANDBY_TIME),
        distinctUntilChanged()
    );

    constructor(private readonly localStore: LocalComponentStore<StandbyCheckinFormComponentState>) {
        this.localStore.setState({
            isTimeExceeded: false,
            flight: undefined,
        });
        this.listenOnOffsetControl();
        this.listenOnFormChanged();
    }

    protected confirm() {
        const id = this.localStore.selectSnapshotByKey("flight")?.id;
        const isTimeExceeded = this.localStore.selectSnapshotByKey("isTimeExceeded");
        if (!id) {
            return;
        }

        if (this.durationControl.invalid) {
            this.durationControl.markAllAsTouched();

            return;
        }
        this.actionConfirm.emit({
            id,
            duration: this.durationControl.value,
            isTimeExceeded,
        });
    }

    protected restoreInitialData() {
        this.durationControl.setValue(INITIAL_STANDBY_TIME);
    }

    private listenOnOffsetControl(): void {
        this.durationControl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((value) => this.localStore.patchState({ isTimeExceeded: value >= MINUTES_IN_HOUR }));
    }

    private listenOnFormChanged(): void {
        this.hasFormChanged$.pipe(untilDestroyed(this)).subscribe((isFormChanged) => this.isFormChanged.emit(isFormChanged));
    }
}
