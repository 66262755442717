import { PageResponseBody } from "@dtm-frontend/shared/ui";
import { DateUtils } from "@dtm-frontend/shared/utils";
import { AlertTimeRange } from "../components/alert-forms/alert-time-range/alert-time-range.component";
import {
    Alert,
    AlertBadgeType,
    AlertGeometrySourceType,
    AlertPointOfInterest,
    AlertPointOfInterestWithPages,
    AlertType,
    CategorizedAlertsByBadge,
    LocationAlertEntity,
    PoiArea,
    Polygon,
    WkbArea,
    Zone,
    ZoneArea,
} from "../models/alert.models";

export interface AlertResponseBody {
    id: string;
    type: AlertBadgeType;
    title: string;
    messageContent: string;
    area: Polygon;
    startAt: Date;
    finishAt?: Date;
    templateId?: string;
    sourceType?: AlertGeometrySourceType;
}

export interface GetPoiListResponseBody {
    items: PageResponseBody<PointOfInterestResponseBody>;
}

export type PointOfInterestResponseBody = Omit<AlertPointOfInterest, "isBookmarked"> & { bookmarked: boolean };

export interface PageableRequestPayload {
    size: number;
    page: number;
    sort: string;
}

export interface PageableResponseBody<T> extends Omit<PageResponseBody<T>, "hasContent"> {
    sort: SortResponseBody[];
    empty: boolean;
}

export interface SortResponseBody {
    direction: string;
    nullHandling: string;
    ascending: boolean;
    property: string;
    ignoreCase: boolean;
}

interface CreateAlertRequestPayload {
    type: AlertType;
    scope: {
        area: ZoneArea | PoiArea | WkbArea;
        startAt?: string;
        finishAt?: string;
    };
    text: {
        templateId?: {
            value: string;
        };
        content: string;
        modified?: boolean;
    };
}

export interface GetZonesFeatureInfoResponseBody {
    features: {
        properties: {
            designator: string;
        };
    }[];
}

interface UpdateAlertRequestPayload {
    startAt?: string;
    finishAt?: string;
}

export function convertAlertResponseBodyToAlert(alert: AlertResponseBody): Alert {
    return {
        id: alert.id,
        type: alert.type,
        title: alert.title,
        messageContent: alert.messageContent,
        area: alert.area,
        templateId: alert.templateId,
        sourceType: alert.sourceType,
        startAt: new Date(alert.startAt),
        finishAt: alert.finishAt ? new Date(alert.finishAt) : undefined,
    };
}

export function convertGetZonesFeatureInfoResponseBodyToZone(response: GetZonesFeatureInfoResponseBody): Zone[] {
    return response.features.map(
        (feature): Zone => ({
            designator: feature.properties.designator,
        })
    );
}

export function convertLocationAlertEntityToCreateAlertRequestPayload(alertData: LocationAlertEntity): CreateAlertRequestPayload {
    return {
        type: alertData.type,
        scope: {
            area: alertData.area,
            startAt: alertData.startAt <= new Date() ? undefined : alertData.startAt.toISOString(),
            finishAt: alertData.duration
                ? DateUtils.getFinishDateForISO8601Duration(alertData.startAt, alertData.duration).toISOString()
                : undefined,
        },
        text: {
            templateId: alertData.message?.templateId ? { value: alertData.message.templateId } : undefined,
            content: alertData.message?.content ?? "",
            modified: alertData.message?.isModified,
        },
    };
}

export function convertAlertTimeRangeToUpdateAlertRequestPayload(timeRange: AlertTimeRange | undefined): UpdateAlertRequestPayload {
    return {
        startAt: timeRange?.startDate ? (timeRange.startDate < new Date() ? undefined : timeRange.startDate.toISOString()) : undefined,
        finishAt: timeRange?.duration
            ? DateUtils.getFinishDateForISO8601Duration(timeRange?.startDate, timeRange?.duration).toISOString()
            : undefined,
    };
}

export function categorizeAlertsByBadge(response: AlertResponseBody[]) {
    const categorizedAlertsByBadge: CategorizedAlertsByBadge = {};
    const dateNow = new Date();

    for (const responseItem of response) {
        const startedAt = new Date(responseItem.startAt);
        if (startedAt <= dateNow && Object.values(AlertBadgeType).includes(responseItem.type)) {
            if (!categorizedAlertsByBadge[responseItem.type]) {
                categorizedAlertsByBadge[responseItem.type] = [];
            }
            categorizedAlertsByBadge[responseItem.type]?.push(responseItem);
        }

        if (startedAt > dateNow) {
            if (!categorizedAlertsByBadge[AlertBadgeType.NotStarted]) {
                categorizedAlertsByBadge[AlertBadgeType.NotStarted] = [];
            }
            categorizedAlertsByBadge[AlertBadgeType.NotStarted]?.push(responseItem);
        }
    }

    return categorizedAlertsByBadge;
}

export function convertGetPoiListResponseBodyToPoiWithPages(
    response: PageResponseBody<PointOfInterestResponseBody>
): AlertPointOfInterestWithPages {
    return {
        content: response.content.map((poi) => ({ ...poi, isBookmarked: poi.bookmarked })),
        pageNumber: response.number,
        pageSize: response.size,
        totalElements: response.totalElements,
    };
}
