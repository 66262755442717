<ng-container
    *ngrxLet="{
        isBookmarkedHospitalsVisible: isBookmarkedHospitalsVisible$,
        hospitalList: hospitalList$,
        selectedHospitalId: selectedHospitalId$
    } as vm"
>
    <div class="poi-action" [matTooltip]="'datsLibFlights.pointOfInterest.searchTooltip' | transloco" matTooltipPosition="left">
        <dtm-ui-icon name="search"></dtm-ui-icon>
    </div>
    <section class="hospitals">
        <div
            class="poi-action"
            [matTooltip]="'datsLibFlights.pointOfInterest.hospitalsTooltip' | transloco"
            matTooltipPosition="left"
            [class.active]="vm.isBookmarkedHospitalsVisible"
            (click)="displayBookmarkedHospitals()"
        >
            <dtm-ui-icon name="first-aid-kit-line"></dtm-ui-icon>
        </div>
        <div class="list-wrapper" *ngIf="vm.isBookmarkedHospitalsVisible">
            <div class="bookmarked-hospital-list">
                <div
                    *ngFor="let hospital of vm.hospitalList"
                    class="element"
                    [class.active]="vm.selectedHospitalId === hospital.id"
                    (click)="selectHospital(hospital)"
                >
                    {{ hospital.title | ellipsifyText : 15 }}
                </div>
            </div>
            <div
                class="element bookmarked"
                (click)="hospitalClick.emit()"
                [matTooltip]="'datsLibFlights.pointOfInterest.addFavoriteHospitalsTooltip' | transloco"
            >
                <dtm-ui-icon name="star-fill"></dtm-ui-icon>
            </div>
        </div>
    </section>

    <div class="poi-action" [matTooltip]="'datsLibFlights.pointOfInterest.hemsBasesTooltip' | transloco" matTooltipPosition="left">
        <dtm-ui-icon name="complex-helicopter"></dtm-ui-icon>
    </div>
    <div class="poi-action" [matTooltip]="'datsLibFlights.pointOfInterest.zonesTooltip' | transloco" matTooltipPosition="left">
        <dtm-ui-icon name="collage"></dtm-ui-icon>
    </div>
</ng-container>
