import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { distinctUntilChanged, map, startWith, switchMap } from "rxjs";
import { AlertGeometrySourceType, AlertPointOfInterest, AlertTextTemplate, AlertType } from "../../../models/alert.models";
import { AlertFormComponent, AlertFormGroup } from "../alert-form/alert-form.component";

interface HemsAlertFormComponentState {
    hemsBases: AlertPointOfInterest[];
    templates: AlertTextTemplate[];
    alertForm: FormGroup<AlertFormGroup> | undefined;
}

export type HemsAlertFormGroupValue = {
    source: AlertGeometrySourceType.HEMS;
    hemsBase: AlertPointOfInterest;
} & Required<FormGroup<AlertFormGroup>["value"]>;

@UntilDestroy()
@Component({
    selector: "dats-lib-hems-alert-form",
    templateUrl: "./hems-alert-form.component.html",
    styleUrls: ["../../../../shared/components/sidebar/action-sidebar.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HemsAlertFormComponent {
    @Input({ required: true }) public set hemsBases(value: AlertPointOfInterest[] | undefined) {
        this.localStore.patchState({ hemsBases: value ?? [] });
    }
    @Input({ required: true }) public set templates(value: AlertTextTemplate[]) {
        this.localStore.patchState({ templates: value });
    }

    @ViewChild(AlertFormComponent, { static: false }) public set alertForm(value: AlertFormComponent | undefined) {
        this.localStore.patchState({ alertForm: value?.alertForm });
    }
    private readonly alertFormChanges$ = this.localStore.selectByKey("alertForm").pipe(
        RxjsUtils.filterFalsy(),
        switchMap((form) => form.valueChanges),
        startWith<FormGroup<AlertFormGroup>["value"]>({})
    );

    @Output() public readonly actionCancel = new EventEmitter<void>();
    @Output() public readonly actionConfirm = new EventEmitter<HemsAlertFormGroupValue>();
    @Output() public readonly isFormChanged = this.alertFormChanges$.pipe(
        map((value) => !!value.message),
        distinctUntilChanged()
    );

    protected readonly hemsBases$ = this.localStore.selectByKey("hemsBases");
    protected readonly templates$ = this.localStore.selectByKey("templates");
    protected readonly selectedType$ = this.alertFormChanges$.pipe(map((value) => value?.type ?? AlertType.NoFlyZone));

    protected readonly hemsBaseFormControl = new FormControl<AlertPointOfInterest | null>(null, [Validators.required]);

    constructor(private readonly localStore: LocalComponentStore<HemsAlertFormComponentState>) {
        this.localStore.setState({
            hemsBases: [],
            templates: [],
            alertForm: undefined,
        });
    }

    protected tryEmitConfirm(): void {
        const alertForm = this.localStore.selectSnapshotByKey("alertForm");

        if (alertForm === undefined) {
            return;
        }

        alertForm.updateValueAndValidity();
        alertForm.markAllAsTouched();
        this.hemsBaseFormControl.updateValueAndValidity();
        this.hemsBaseFormControl.markAsTouched();

        if (alertForm.valid && this.hemsBaseFormControl.valid && this.hemsBaseFormControl.value !== null) {
            this.actionConfirm.emit({
                ...alertForm.getRawValue(),
                source: AlertGeometrySourceType.HEMS,
                hemsBase: this.hemsBaseFormControl.value,
            });
        }
    }
}
