import { Directive, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import {
    LEAFLET_MAP_PROVIDER,
    LeafletMapProvider,
    MarkerCluster,
    MarkerClusterGroup,
    MarkerClusterGroupOptions,
    createMarkerClusterGroup,
} from "@dtm-frontend/shared/map/leaflet";
import { ArrayUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import turfBooleanPointInPolygon from "@turf/boolean-point-in-polygon";
import turfCenterOfMass from "@turf/center";
import { Feature, Polygon, Properties, point as turfPoint, polygon as turfPolygon } from "@turf/helpers";
import equal from "fast-deep-equal";
import {
    DivIcon,
    DomEvent,
    FeatureGroup,
    Icon,
    Layer,
    Map as LeafletMap,
    Marker,
    Point,
    geoJSON as createLeafletGeoJSONLayer,
} from "leaflet";
import { combineLatest, throttleTime } from "rxjs";
import { Grid } from "../../../capabilities/models/capabilities.model";
import { FlightCategory, FlightItem, FlightList } from "../../models/flight.models";

const ICON_SIZE_DEFAULT = 44;
const ICON_SIZE_HIGHLIGHTED = 60;
const ICON_SIZE_SELECTED = 56;
const ICON_ANCHOR_MODIFIER = 0.5;
// NOTE: each flight have different z-index to be safe it must be greater than amount of flights on the map
const Z_INDEX_OFFSET = 100000;

const LAYER_REFRESH_THROTTLE_TIME = 300;
const FLY_TO_ANIMATION_DURATION = 0.3;
const DEFAULT_ZOOM_LEVEL = 12;
const SUBDUED_ICON_OPACITY = 0.35;

interface FlightsLayerDirectiveState {
    flightList: FlightList | undefined;
    selectedFlightId: string | undefined;
    previouslySelectedFlightId: string | undefined;
    heightFilter: number | undefined;
    previousHeightFilter: number | undefined;
    grid: Grid | undefined;
    isClusteringEnabled: boolean;
}

enum FlightIconState {
    Default = "default",
    Highlighted = "highlighted",
    Selected = "selected",
}

interface FlightItemMarker {
    item: FlightItem;
    iconState: FlightIconState;
}

const MarkerOptions = {
    [FlightIconState.Default]: {
        iconSize: ICON_SIZE_DEFAULT,
        zIndexOffset: Z_INDEX_OFFSET,
        className: "",
    },
    [FlightIconState.Highlighted]: {
        iconSize: ICON_SIZE_HIGHLIGHTED,
        zIndexOffset: 100 * Z_INDEX_OFFSET,
        className: "flight-marker-highlighted",
    },
    [FlightIconState.Selected]: {
        iconSize: ICON_SIZE_SELECTED,
        zIndexOffset: 1000 * Z_INDEX_OFFSET,
        className: "flight-marker-highlighted-selected",
    },
} as const;

const FlightCategoryIcons: Record<FlightCategory, { icon: string; isAnimated: boolean }> = {
    [FlightCategory.Pending_112]: { icon: "Pending112.svg", isAnimated: true },
    [FlightCategory.Emergency]: { icon: "Emergency.svg", isAnimated: true },
    [FlightCategory.Stop]: { icon: "Land.svg", isAnimated: false },
    [FlightCategory.Pending]: { icon: "Pending.svg", isAnimated: true },
    [FlightCategory.AcceptedManually]: { icon: "AcceptedManually.svg", isAnimated: false },
    [FlightCategory.AcceptedSystem]: { icon: "AcceptedSystem.svg", isAnimated: false },
    [FlightCategory.AcceptedOther]: { icon: "Other.svg", isAnimated: false },
    [FlightCategory.Completed]: { icon: "AcceptedManually.svg", isAnimated: false },
    [FlightCategory.CompletedSystem]: { icon: "AcceptedSystem.svg", isAnimated: false },
    [FlightCategory.CompletedOther]: { icon: "Other.svg", isAnimated: false },
    [FlightCategory.Rejected]: { icon: "Other.svg", isAnimated: false },
    [FlightCategory.Overdue]: { icon: "Overdue.svg", isAnimated: false },
    [FlightCategory.OverdueSystem]: { icon: "Overdue.svg", isAnimated: false },
    [FlightCategory.OverdueOther]: { icon: "Overdue.svg", isAnimated: false },
    [FlightCategory.Standby]: { icon: "Standby.svg", isAnimated: true },
    [FlightCategory.Canceled]: { icon: "Other.svg", isAnimated: false },
    [FlightCategory.Other]: { icon: "Other.svg", isAnimated: false },
    [FlightCategory.Mission]: { icon: "Other.svg", isAnimated: false },
};

const FlightCategoryNotAckIcons: Record<FlightCategory, { icon: string; isAnimated: boolean }> = {
    ...FlightCategoryIcons,
    [FlightCategory.Stop]: { icon: "Land_NotAck.svg", isAnimated: true },
    [FlightCategory.AcceptedManually]: { icon: "Modified_NotAck.svg", isAnimated: true },
    [FlightCategory.AcceptedSystem]: { icon: "Modified_NotAck.svg", isAnimated: true },
    [FlightCategory.AcceptedOther]: { icon: "Modified_NotAck.svg", isAnimated: true },
};

const MARKER_CLUSTER_ICON_SIZE = 40;
const MARKER_CLUSTER_SMALL_COUNT = 10;
const MARKER_CLUSTER_MEDIUM_COUNT = 100;

function iconCreateFunction(cluster: MarkerCluster) {
    const childCount = cluster.getChildCount() - 1; // -1 for the hidden center marker

    let className = " marker-cluster-";
    if (childCount < MARKER_CLUSTER_SMALL_COUNT) {
        className += "small";
    } else if (childCount < MARKER_CLUSTER_MEDIUM_COUNT) {
        className += "medium";
    } else {
        className += "large";
    }

    return new DivIcon({
        html: `<div><span>${childCount} <span aria-label="markers"></span></span></div>`,
        className: "marker-cluster" + className,
        iconSize: new Point(MARKER_CLUSTER_ICON_SIZE, MARKER_CLUSTER_ICON_SIZE),
    });
}

const MARKER_CLUSTER_GROUP_OPTIONS: MarkerClusterGroupOptions = {
    showCoverageOnHover: false,

    maxClusterRadius: Number.MAX_SAFE_INTEGER,
    zoomToBoundsOnClick: false,
    spiderfyOnEveryZoom: true,
    animateAddingMarkers: false,
    spiderfyShapePositions: "original",
    spiderLegPolylineOptions: { weight: 0 },
    iconCreateFunction,
};

@UntilDestroy()
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "dats-lib-flights-layer",
    providers: [LocalComponentStore],
})
export class FlightsLayerDirective implements OnInit, OnDestroy {
    @Input({ required: true }) public set flightList(value: FlightList | undefined) {
        this.localStore.patchState({ flightList: value });
    }

    @Input() public set selectedFlightId(value: string | undefined) {
        this.localStore.patchState((state) => ({
            previouslySelectedFlightId: state.selectedFlightId,
            selectedFlightId: value,
        }));
    }

    @Input() public set heightFilter(value: number | undefined) {
        this.localStore.patchState({ heightFilter: value });
    }

    @Input() public set grid(value: Grid | undefined) {
        this.localStore.patchState({ grid: value });
    }

    @Input() public set isClusteringEnabled(value: boolean) {
        this.localStore.patchState({ isClusteringEnabled: value });
    }

    @Output() public readonly flightClick = new EventEmitter<string>();

    private readonly flightList$ = this.localStore.selectByKey("flightList");

    private readonly flightIconsLayer = new FeatureGroup();
    private readonly gridDisplayLayer = new FeatureGroup();
    private readonly clusteredIconsLayers: Map<string, { cluster: MarkerClusterGroup; geometry: Feature<Polygon, Properties> }> = new Map();
    private readonly flightsMap = new Map<string, { flight: FlightItemMarker; layer: Layer; parentFeatureGroup: FeatureGroup }>();

    constructor(
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider,
        private readonly localStore: LocalComponentStore<FlightsLayerDirectiveState>
    ) {
        this.localStore.setState({
            flightList: undefined,
            selectedFlightId: undefined,
            previouslySelectedFlightId: undefined,
            heightFilter: undefined,
            previousHeightFilter: undefined,
            grid: undefined,
            isClusteringEnabled: false,
        });
    }

    public async ngOnInit() {
        const map: LeafletMap = await this.mapProvider.getMap();
        map.addLayer(this.gridDisplayLayer);
        map.addLayer(this.flightIconsLayer);

        combineLatest([
            this.flightList$,
            this.localStore.selectByKey("selectedFlightId"),
            this.localStore.selectByKey("heightFilter"),
            this.localStore.selectByKey("grid"),
            this.localStore.selectByKey("isClusteringEnabled"),
        ])
            .pipe(throttleTime(LAYER_REFRESH_THROTTLE_TIME, undefined, { leading: true, trailing: true }), untilDestroyed(this))
            .subscribe(([flightList, selectedFlightId, heightFilter, grid, isClusteringEnabled]) => {
                this.updateMarkers(
                    flightList,
                    selectedFlightId,
                    this.localStore.selectSnapshotByKey("previouslySelectedFlightId"),
                    heightFilter,
                    this.localStore.selectSnapshotByKey("previousHeightFilter"),
                    grid,
                    isClusteringEnabled
                );
                this.localStore.patchState({ previousHeightFilter: heightFilter });
            });
    }

    public async ngOnDestroy() {
        // NOTE: when map will be removed by other source we can skip cleanup
        try {
            const map: LeafletMap = await this.mapProvider.getMap();

            this.clusteredIconsLayers.forEach((layer) => {
                layer.cluster.clearLayers();
                this.flightIconsLayer.removeLayer(layer.cluster);
            });
            map.removeLayer(this.flightIconsLayer);
            map.removeLayer(this.gridDisplayLayer);
        } catch (error) {}
    }

    public async zoomToFlightItem(flightId: string, leftPaddingPixels: number = 0, topPaddingPixels: number = 0) {
        const flightMapItem = this.flightsMap.get(flightId);

        if (!flightMapItem) {
            return;
        }

        const map: LeafletMap = await this.mapProvider.getMap();

        const { latitude, longitude } = flightMapItem.flight.item.operation.geography;
        const point = map.project([latitude, longitude], DEFAULT_ZOOM_LEVEL);
        const shiftedPoint = point.add([-leftPaddingPixels / 2, -topPaddingPixels / 2]);
        const shiftedLatLng = map.unproject(shiftedPoint, DEFAULT_ZOOM_LEVEL);

        map.flyTo(shiftedLatLng, DEFAULT_ZOOM_LEVEL, {
            animate: true,
            duration: FLY_TO_ANIMATION_DURATION,
        });
    }

    private manageSubGrids(grid: Grid, isClusteringEnabled: boolean): { hasGridsChanged: boolean } {
        const subGrids = (grid.geometry?.type === "MultiPolygon" ? grid.geometry.coordinates : []).map((polygonCoords) =>
            turfPolygon(polygonCoords)
        );

        const subGridMap = new Map(subGrids.map((subGrid) => [JSON.stringify(subGrid), subGrid]));
        const gridClusteredIconsLayersKeys = Array.from(this.clusteredIconsLayers.keys());

        const [subGridsToSkip, subGridsToAdd] = ArrayUtils.partition(Array.from(subGridMap.keys()), (key) =>
            this.clusteredIconsLayers.has(key)
        );
        const [subGridsToRemove] = ArrayUtils.partition(gridClusteredIconsLayersKeys, (key) => !subGridsToSkip.includes(key));

        if (!isClusteringEnabled) {
            subGridsToAdd.length = 0;
            subGridsToRemove.length = 0;
            subGridsToRemove.push(...gridClusteredIconsLayersKeys);
        }

        subGridsToAdd.forEach((key) => {
            const cluster = createMarkerClusterGroup(MARKER_CLUSTER_GROUP_OPTIONS);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const geometry = subGridMap.get(key)!;
            this.clusteredIconsLayers.set(key, { cluster, geometry });
            cluster.addTo(this.flightIconsLayer);

            cluster.addLayer(this.createClusterCenterMarker(geometry));
        });

        subGridsToRemove.forEach((key) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const cluster = this.clusteredIconsLayers.get(key)!.cluster;
            cluster.clearLayers();
            this.flightIconsLayer.removeLayer(cluster);
            this.clusteredIconsLayers.delete(key);
        });

        if (subGridsToAdd.length || subGridsToRemove.length) {
            this.gridDisplayLayer.clearLayers();

            [...subGridsToAdd, ...subGridsToSkip].forEach((subGrid) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.gridDisplayLayer.addLayer(createLeafletGeoJSONLayer(subGridMap.get(subGrid)!));
            });
        }

        return { hasGridsChanged: subGridsToAdd.length > 0 || subGridsToRemove.length > 0 };
    }

    private async updateMarkers(
        flightList: FlightList | undefined,
        selectedFlightId: string | undefined,
        previouslySelectedFlightId: string | undefined,
        heightFilter: number | undefined,
        previousHeightFilter: number | undefined,
        grid: Grid | undefined,
        isClusteringEnabled: boolean
    ) {
        if (!grid) {
            return;
        }

        const { hasGridsChanged } = this.manageSubGrids(grid, isClusteringEnabled);

        const newFlightsMap = new Map<string, FlightItemMarker>([
            ...(flightList?.filteredOutFlights ?? []).map(
                (flightItem) =>
                    [
                        flightItem.id,
                        {
                            item: flightItem,
                            iconState: this.getFlightIconState(
                                flightItem.id,
                                selectedFlightId,
                                true,
                                !!flightList?.filteredOutFlights.length
                            ),
                        },
                    ] as [string, FlightItemMarker]
            ),
            ...(flightList?.visibleFlights ?? []).map(
                (flightItem) =>
                    [
                        flightItem.id,
                        {
                            item: flightItem,
                            iconState: this.getFlightIconState(
                                flightItem.id,
                                selectedFlightId,
                                false,
                                !!flightList?.filteredOutFlights.length
                            ),
                        },
                    ] as [string, FlightItemMarker]
            ),
        ]);
        const [flightsToUpdateOrSkip, flightsToAdd] = ArrayUtils.partition(
            [...newFlightsMap.keys()],
            (id) => hasGridsChanged || this.flightsMap.has(id)
        );
        const [flightsToRemove] = ArrayUtils.partition([...this.flightsMap.keys()], (id) => !newFlightsMap.has(id));
        const flightsToUpdate = flightsToUpdateOrSkip.filter(
            (id) =>
                hasGridsChanged ||
                id === selectedFlightId ||
                id === previouslySelectedFlightId ||
                !equal(this.flightsMap.get(id)?.flight.item, newFlightsMap.get(id)?.item) ||
                !equal(this.flightsMap.get(id)?.flight.iconState, newFlightsMap.get(id)?.iconState) ||
                this.isHeightFilterImpactingOpacity(this.flightsMap.get(id)?.flight, heightFilter, previousHeightFilter)
        );

        [...flightsToRemove, ...flightsToUpdate].forEach((id) => {
            const flight = this.flightsMap.get(id);

            if (flight) {
                flight.parentFeatureGroup.removeLayer(flight.layer);
            }

            this.flightsMap.delete(id);
        });
        [...flightsToAdd, ...flightsToUpdate].forEach((id) => {
            const flight = newFlightsMap.get(id);

            if (!flight) {
                return;
            }

            const flightMarker = this.createFlightMarker(flight, heightFilter);
            const parentFeatureGroup = this.getClusterGroupForFlight(flight);

            parentFeatureGroup.addLayer(flightMarker);
            this.flightsMap.set(id, { flight, layer: flightMarker, parentFeatureGroup });
        });

        this.flightIconsLayer.bringToBack();
    }

    private getClusterGroupForFlight(flight: FlightItemMarker) {
        if (flight.item.category === FlightCategory.Emergency) {
            return this.flightIconsLayer;
        }

        for (const cluster of this.clusteredIconsLayers.values()) {
            const flightPoint = turfPoint([flight.item.operation.geography.longitude, flight.item.operation.geography.latitude]);
            if (turfBooleanPointInPolygon(flightPoint, cluster.geometry)) {
                return cluster.cluster;
            }
        }

        return this.flightIconsLayer;
    }

    private isHeightFilterImpactingOpacity(
        flight: FlightItemMarker | undefined,
        heightFilter: number | undefined,
        previousHeightFilter: number | undefined
    ) {
        return (
            flight &&
            previousHeightFilter !== heightFilter &&
            (previousHeightFilter === undefined ||
                heightFilter === undefined ||
                flight.item.operation.geography.maxHeight < previousHeightFilter !==
                    flight.item.operation.geography.maxHeight < heightFilter)
        );
    }

    private getFlightIconOpacity(flight: FlightItemMarker, heightFilter: number = 0): number {
        return flight.item.operation.geography.maxHeight < heightFilter && flight.iconState !== FlightIconState.Selected
            ? SUBDUED_ICON_OPACITY
            : 1;
    }

    private getFlightIconState(
        flightId: string,
        selectedFlightId: string | undefined,
        isFilteredOut: boolean,
        isAnyFlightFilteredOut: boolean
    ) {
        if (flightId === selectedFlightId) {
            return FlightIconState.Selected;
        }
        if (isAnyFlightFilteredOut && !isFilteredOut) {
            // TODO: DTATS-423 revert to FlightIconState.Highlighted
            return FlightIconState.Default;
        }

        return FlightIconState.Default;
    }

    /**
     * Creates a hidden marker for the center of a cluster to enable proper display of single item clusters
     * and gravity other icons towards the center of the cluster.
     */
    private createClusterCenterMarker(geometry: Feature<Polygon, Properties>): Layer {
        const center = turfCenterOfMass(geometry);

        return new Marker([center.geometry.coordinates[1], center.geometry.coordinates[0]], {
            opacity: 0,
        });
    }

    private createFlightMarker(flight: FlightItemMarker, heightFilter: number | undefined): Layer {
        const icon = this.getIcon(flight.item);
        const iconUrl = `assets/images/${icon.icon}`;
        const opacity = this.getFlightIconOpacity(flight, heightFilter);

        let flightMarkerLayer: Layer;

        // if (isSelectedFlight || icon.isAnimated) {
        // TODO: DTATS-210 add support for animated canvas icons, remove one comment line below
        // eslint-disable-next-line prefer-const
        flightMarkerLayer = new Marker([flight.item.operation.geography.latitude, flight.item.operation.geography.longitude], {
            icon: new Icon({
                iconUrl,
                iconSize: [MarkerOptions[flight.iconState].iconSize, MarkerOptions[flight.iconState].iconSize],
                iconAnchor: [
                    MarkerOptions[flight.iconState].iconSize * ICON_ANCHOR_MODIFIER,
                    MarkerOptions[flight.iconState].iconSize * ICON_ANCHOR_MODIFIER,
                ],
                className: MarkerOptions[flight.iconState].className,
            }),
            zIndexOffset: opacity === SUBDUED_ICON_OPACITY ? 0 : MarkerOptions[flight.iconState].zIndexOffset,
            opacity: opacity,
        });
        // TODO: DTATS-210
        // } else {
        // flightMarkerLayer = createCanvasIconMarker(
        //     [flight.item.operation.geography.latitude, flight.item.operation.geography.longitude],
        //     {
        //         icon: {
        //             url: iconUrl,
        //             size: ICON_OPTIONS.iconSize as [number, number],
        //         },
        //         fillOpacity: this.getFlightIconOpacity(flight, selectedFlightId),
        //     }
        // );
        // }

        flightMarkerLayer.on("click", (event) => {
            this.flightClick.emit(flight.item.id);
            DomEvent.stopPropagation(event);
        });

        return flightMarkerLayer;
    }

    private getIcon(flightItem: FlightItem) {
        if (flightItem.modification && flightItem.isUnacknowledged) {
            return FlightCategoryNotAckIcons[flightItem.category];
        }

        return FlightCategoryIcons[flightItem.category];
    }
}
