import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import {
    DateUtils,
    FunctionUtils,
    HOURS_IN_DAY,
    ISO8601TimeDuration,
    LocalComponentStore,
    MILLISECONDS_IN_DAY,
    MILLISECONDS_IN_MINUTE,
    MINUTES_IN_HOUR,
    RxjsUtils,
    SECONDS_IN_MINUTE,
} from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, distinctUntilChanged, filter, map, tap, withLatestFrom } from "rxjs";

interface AlertTimeRangeComponentState {
    isStartTimeTomorrow: boolean;
    isEndTimeEarlierThanStartTime: boolean;
    startTimeMilliseconds: number;
    minimumStartTimeConstraint: Date;
}

export interface AlertTimeRange {
    startDate: Date;
    duration: ISO8601TimeDuration | null;
}

const MAX_DURATION_MINUTES = MINUTES_IN_HOUR * HOURS_IN_DAY; // 24 hours, 1440 minutes
const DEFAULT_DURATION_MINUTES = MINUTES_IN_HOUR;

@UntilDestroy()
@Component({
    selector: "dats-lib-alert-time-range",
    templateUrl: "./alert-time-range.component.html",
    styleUrls: ["./alert-time-range.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AlertTimeRangeComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AlertTimeRangeComponent),
            multi: true,
        },
    ],
})
export class AlertTimeRangeComponent implements ControlValueAccessor, Validator {
    protected readonly MAX_DURATION_MINUTES = MAX_DURATION_MINUTES;
    protected readonly MINUTES_IN_HOUR = MINUTES_IN_HOUR;

    protected readonly isStartTimeTomorrow$ = this.localStore.selectByKey("isStartTimeTomorrow");
    protected readonly isEndTimeEarlierThanStartTime$ = this.localStore.selectByKey("isEndTimeEarlierThanStartTime");
    protected readonly isEndTimeDayAfterTomorrow$ = combineLatest([this.isEndTimeEarlierThanStartTime$, this.isStartTimeTomorrow$]).pipe(
        map(([isEndTimeEarlierThanStartTime, isStartTimeTomorrow]) => isStartTimeTomorrow && isEndTimeEarlierThanStartTime)
    );

    protected readonly minimumStartTimeConstraint$ = this.localStore.selectByKey("minimumStartTimeConstraint");
    protected readonly durationMinutesControl = new FormControl<number | null>(DEFAULT_DURATION_MINUTES, {
        nonNullable: true,
        validators: [Validators.min(1), Validators.max(MAX_DURATION_MINUTES)],
    });
    protected readonly startTimeControl = new FormControl<Date | null>(null, { nonNullable: true, validators: [Validators.required] });
    protected readonly endTimeControl = new FormControl<Date | null>(null, { nonNullable: true, validators: [Validators.required] });

    constructor(private readonly localStore: LocalComponentStore<AlertTimeRangeComponentState>) {
        const now = new Date();
        now.setSeconds(0, 0);

        this.localStore.setState({
            isStartTimeTomorrow: false,
            isEndTimeEarlierThanStartTime: false,
            startTimeMilliseconds: now.getTime(),
            minimumStartTimeConstraint: now,
        });

        this.startTimeControl.setValue(now);
        this.endTimeControl.setValue(new Date(now.getTime() + DEFAULT_DURATION_MINUTES * MILLISECONDS_IN_MINUTE));

        this.synchronizeFormValues();
    }

    public validate(): ValidationErrors | null {
        return this.startTimeControl.valid && this.endTimeControl.valid && this.durationMinutesControl.valid ? null : { synced: true };
    }

    public writeValue(value: AlertTimeRange | null): void {
        if (value) {
            this.setInternalFormValuesFromFormGroup(value);
        }
    }

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: AlertTimeRange) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    public registerOnChange(fn: (value: AlertTimeRange) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    private emitChanges() {
        const daysOffset = this.localStore.selectSnapshotByKey("isStartTimeTomorrow") ? 1 : 0;
        const startTimeMilliseconds = this.localStore.selectSnapshotByKey("startTimeMilliseconds");

        this.propagateChange({
            startDate: this.getDateShiftedByDays(new Date(startTimeMilliseconds), daysOffset),
            duration: this.durationMinutesControl.value ? this.getISO8601Duration(this.durationMinutesControl.value) : null,
        });
    }

    private setInternalFormValuesFromFormGroup(value: AlertTimeRange | undefined) {
        this.localStore.patchState({ minimumStartTimeConstraint: value?.startDate ?? new Date() });

        if (value?.duration) {
            const durationSeconds = DateUtils.convertISO8601DurationToSeconds(value.duration);
            if (durationSeconds) {
                this.durationMinutesControl.setValue(Math.ceil(durationSeconds / SECONDS_IN_MINUTE));
            }
        }

        if (value?.startDate) {
            value.startDate.setSeconds(0, 0);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            if (this.checkIfIsSameUTCDay(value.startDate, this.startTimeControl.value!)) {
                this.startTimeControl.setValue(value.startDate);
            } else {
                this.startTimeControl.setValue(this.getDateShiftedByDays(value.startDate, -1));
            }
        }
    }

    protected getDateShiftedByDays(date: Date | null, days: number) {
        if (date) {
            return new Date(date.getTime() + days * MILLISECONDS_IN_DAY);
        }

        return new Date();
    }

    private checkIfIsSameUTCDay(firstDate: Date, secondDate: Date) {
        return firstDate.getUTCDate() === secondDate.getUTCDate();
    }

    private synchronizeFormValues() {
        this.startTimeControl.valueChanges
            .pipe(
                tap(() => this.emitChanges()),
                RxjsUtils.filterFalsy(),
                map((startTime) => startTime.getTime()),
                distinctUntilChanged(),
                withLatestFrom(this.minimumStartTimeConstraint$),
                untilDestroyed(this)
            )
            .subscribe(([startTimeMilliseconds, minimumStartTimeConstraint]) => {
                if (startTimeMilliseconds < minimumStartTimeConstraint.getTime()) {
                    this.localStore.patchState({ isStartTimeTomorrow: true, startTimeMilliseconds });
                } else {
                    this.localStore.patchState({ isStartTimeTomorrow: false, startTimeMilliseconds });
                }

                if (this.durationMinutesControl.value) {
                    let newEndDateTime = new Date(startTimeMilliseconds + this.durationMinutesControl.value * MILLISECONDS_IN_MINUTE);

                    if (!this.checkIfIsSameUTCDay(newEndDateTime, new Date(startTimeMilliseconds))) {
                        newEndDateTime = this.getDateShiftedByDays(newEndDateTime, -1);
                    }

                    this.endTimeControl.setValue(newEndDateTime);
                } else {
                    this.emitChanges();
                }
            });

        this.endTimeControl.valueChanges
            .pipe(
                tap(() => this.emitChanges()),
                RxjsUtils.filterFalsy(),
                map((endTime) => endTime.getTime()),
                distinctUntilChanged(),
                untilDestroyed(this)
            )
            .subscribe((endTimeMilliseconds) => {
                if (!this.startTimeControl.value) {
                    return;
                }

                this.localStore.patchState({ isEndTimeEarlierThanStartTime: endTimeMilliseconds <= this.startTimeControl.value.getTime() });

                let durationMinutes = Math.ceil((endTimeMilliseconds - this.startTimeControl.value.getTime()) / MILLISECONDS_IN_MINUTE);

                if (durationMinutes <= 0) {
                    durationMinutes = MAX_DURATION_MINUTES + durationMinutes;
                }

                if (durationMinutes > MAX_DURATION_MINUTES) {
                    durationMinutes = durationMinutes - MAX_DURATION_MINUTES;
                }

                this.durationMinutesControl.setValue(durationMinutes);
            });

        this.durationMinutesControl.valueChanges
            .pipe(
                tap(() => this.emitChanges()),
                RxjsUtils.filterFalsy(),
                filter((durationMinutes) => durationMinutes > 0 && durationMinutes <= MAX_DURATION_MINUTES),
                untilDestroyed(this)
            )
            .subscribe((durationMinutes) => {
                if (!this.startTimeControl.value) {
                    return;
                }

                let newEndTime = new Date(this.startTimeControl.value.getTime() + durationMinutes * MILLISECONDS_IN_MINUTE);

                if (!this.checkIfIsSameUTCDay(newEndTime, this.startTimeControl.value)) {
                    newEndTime = this.getDateShiftedByDays(newEndTime, -1);
                }

                this.endTimeControl.setValue(newEndTime);
                this.emitChanges();
            });
    }

    protected getISO8601Duration(durationMinutes: number) {
        return DateUtils.convertSecondsToISO8601Duration(durationMinutes * SECONDS_IN_MINUTE);
    }
}
