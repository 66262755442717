import { AirspaceType, FlightCategory, FlightsTabType } from "../models/flight.models";
import { FlightFilterType } from "../models/flights-filters.models";

export const ATC_CONTROLLER_TABS: FlightsTabType[] = [
    FlightsTabType.UTM_ATC,
    FlightsTabType.AcceptedManually,
    FlightsTabType.SystemAccepted,
    FlightsTabType.Mission,
];
export const FIS_INFORMER_TABS: FlightsTabType[] = [
    FlightsTabType.UTM_FIS,
    FlightsTabType.FIS,
    FlightsTabType.TWR,
    FlightsTabType.DTM,
    FlightsTabType.Mission,
];

export const UTM_ATC_CATEGORIES: FlightCategory[] = [
    FlightCategory.Pending_112,
    FlightCategory.Emergency,
    FlightCategory.Stop,
    FlightCategory.Pending,
    FlightCategory.Standby,
];
export const UTM_FIS_CATEGORIES: FlightCategory[] = [FlightCategory.Emergency, FlightCategory.Stop];
export const MANUALLY_ACCEPTED_CATEGORIES: FlightCategory[] = [FlightCategory.AcceptedManually, FlightCategory.Overdue];
export const SYSTEM_ACCEPTED_CATEGORIES: FlightCategory[] = [FlightCategory.AcceptedSystem, FlightCategory.OverdueSystem];
export const FIS_CATEGORIES: FlightCategory[] = [FlightCategory.Overdue, FlightCategory.AcceptedManually];
export const DTM_CATEGORIES: FlightCategory[] = [
    FlightCategory.AcceptedOther,
    FlightCategory.AcceptedSystem,
    FlightCategory.OverdueOther,
    FlightCategory.OverdueSystem,
];
export const FIS_ZONES_CATEGORIES: FlightCategory[] = [
    FlightCategory.Emergency,
    FlightCategory.Stop,
    FlightCategory.Overdue,
    FlightCategory.OverdueSystem,
    FlightCategory.AcceptedManually,
    FlightCategory.AcceptedSystem,
];

export const AVAILABLE_CATEGORY_FILTERS: FlightFilterType[] = [
    FlightCategory.Emergency,
    FlightCategory.Stop,
    FlightCategory.Pending,
    FlightCategory.Standby,
    FlightCategory.AcceptedManually,
    FlightCategory.AcceptedSystem,
    FlightCategory.AcceptedOther,
    FlightCategory.Completed,
    FlightCategory.CompletedSystem,
    FlightCategory.CompletedOther,
    FlightCategory.Rejected,
    FlightCategory.Overdue,
    FlightCategory.OverdueSystem,
    FlightCategory.OverdueOther,
    FlightCategory.Standby,
    FlightCategory.Canceled,
    FlightCategory.Other,
];

export const AVAILABLE_AIRSPACE_TYPE_FILTERS = [AirspaceType.CTR, AirspaceType.MCTR];
