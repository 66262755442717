import { Options } from "@angular-slider/ngx-slider";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { DateUtils, LocalComponentStore, MILLISECONDS_IN_MINUTE, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { combineLatestWith, distinctUntilChanged, map } from "rxjs";
import { FlightAcceptancePhase, FlightItem, ModifyCheckinFormValues } from "../../models/flight.models";

interface ModifyCheckinFormComponentState {
    flight: FlightItem | undefined;
    initialFormData: ModifyCheckinFormValues | undefined;
    heightSliderOptions: Options;
}

export interface ModifyCheckinFormGroup {
    height: FormControl<number | null>;
    flightTime: FormControl<number | null>;
    customHeight: FormControl<number | null>;
    delayStart: FormControl<number | null>;
    startAt: FormControl<Date | null>;
    finishAt: FormControl<Date | null>;
}

// eslint-disable-next-line no-magic-numbers
const PREDEFINED_HEIGHT_VALUES = [60, 90, 120];
const MAX_CHECKIN_HEIGHT = 120;
const HEIGHT_SLIDER_OPTIONS = {
    floor: 0,
    ceil: MAX_CHECKIN_HEIGHT,
    vertical: true,
    // eslint-disable-next-line no-magic-numbers
    tickStep: MAX_CHECKIN_HEIGHT / 4,
    showSelectionBar: true,
    showTicksValues: true,
};
const MAX_TICK_AMOUNT = 10;
const DEFAULT_TICK_VALUE = 20;

@UntilDestroy()
@Component({
    selector: "dats-lib-modify-checkin-form",
    templateUrl: "./modify-checkin-form.component.html",
    styleUrls: ["../sidebar/action-sidebar.scss", "./modify-checkin-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ModifyCheckinFormComponent {
    @Input({ required: true }) public set flight(value: FlightItem) {
        this.prepareForm(value);
        this.localStore.patchState({ flight: value });
    }
    @Output() public readonly actionConfirm = new EventEmitter<{ flight: FlightItem; formValues: ModifyCheckinFormValues }>();
    @Output() public readonly actionCancel = new EventEmitter<void>();
    @Output() public readonly isFormChanged = new EventEmitter<boolean>();

    protected modifyForm = new FormGroup<ModifyCheckinFormGroup>(
        {
            height: new FormControl(null),
            customHeight: new FormControl(null, [Validators.max(MAX_CHECKIN_HEIGHT), Validators.min(0)]),
            flightTime: new FormControl(null),
            delayStart: new FormControl(null),
            startAt: new FormControl(null, [this.futureDateValidator()]),
            finishAt: new FormControl(null),
        },
        { validators: this.timeExceededValidator() }
    );

    protected readonly PREDEFINED_HEIGHT_VALUES = PREDEFINED_HEIGHT_VALUES;
    protected readonly HEIGHT_SLIDER_OPTIONS = HEIGHT_SLIDER_OPTIONS;

    protected readonly flight$ = this.localStore.selectByKey("flight").pipe(RxjsUtils.filterFalsy());
    protected readonly heightSliderOptions$ = this.localStore.selectByKey("heightSliderOptions");
    protected readonly hasFormChanged$ = this.modifyForm.valueChanges.pipe(
        combineLatestWith(this.localStore.selectByKey("initialFormData")),
        map(([formData, initialFormData]) => !equal(this.prepareFormToCompare(formData), this.prepareFormToCompare(initialFormData))),
        distinctUntilChanged()
    );

    constructor(private readonly localStore: LocalComponentStore<ModifyCheckinFormComponentState>) {
        this.localStore.setState({
            flight: undefined,
            initialFormData: undefined,
            heightSliderOptions: HEIGHT_SLIDER_OPTIONS,
        });

        this.listenOnCustomHeight();
        this.listenOnFormChanged();
    }

    protected confirm() {
        const flight = this.localStore.selectSnapshotByKey("flight");
        if (!flight) {
            return;
        }

        if (this.modifyForm.invalid) {
            this.modifyForm.markAllAsTouched();

            return;
        }
        this.actionConfirm.emit({
            flight,
            formValues: {
                height: this.modifyForm.controls.height.value,
                customHeight: this.modifyForm.controls.customHeight.value,
                delayStart: this.modifyForm.controls.delayStart.value,
                finishAt: this.modifyForm.controls.finishAt.value,
                flightTime: this.modifyForm.controls.flightTime.value,
                startAt: this.modifyForm.controls.startAt.value,
            },
        });
    }

    protected updateEndTime(flightTimeInMinutes: number): void {
        const startAtTime = this.startAtControl.value;

        if (!startAtTime || this.flightTimeControl.pristine) {
            return;
        }

        const calculatedFinishTime = new Date(startAtTime.getTime() + flightTimeInMinutes * MILLISECONDS_IN_MINUTE);

        this.setValueToDateControl(this.finishAtControl, calculatedFinishTime);
    }

    protected updateTime(delayInMinutes: number): void {
        const startTime = this.startAtControl.value;
        const finishTime = this.finishAtControl.value;
        const referenceStartTime = this.localStore.selectSnapshotByKey("flight")?.operation.plannedStartAt;

        if (!startTime || !referenceStartTime || !finishTime || this.delayStartControl.pristine) {
            return;
        }

        const calculatedStartTime = new Date(referenceStartTime.getTime() + delayInMinutes * MILLISECONDS_IN_MINUTE);
        const calculatedFinishTime = new Date(calculatedStartTime.getTime() + finishTime.getTime() - startTime.getTime());

        this.setValueToDateControl(this.startAtControl, calculatedStartTime);
        this.setValueToDateControl(this.finishAtControl, calculatedFinishTime);
    }

    protected updateDelayAndFlightTime(startTime: Date | null): void {
        const finishTime = this.finishAtControl.value;
        const flight = this.localStore.selectSnapshotByKey("flight");

        if (!startTime || !finishTime || !flight) {
            return;
        }

        const isTimeExceeded = startTime.getTime() > finishTime.getTime();

        this.modifyForm.patchValue(
            {
                flightTime: isTimeExceeded ? 0 : DateUtils.minutesBetween(startTime, finishTime),
                delayStart:
                    startTime.getTime() > flight.operation.plannedStartAt.getTime()
                        ? DateUtils.minutesBetween(startTime, flight.operation.plannedStartAt)
                        : 0,
            },
            { emitEvent: false }
        );
    }

    protected updateFlightTime(finishTime: Date | null): void {
        const startTime = this.startAtControl.value;
        const flight = this.localStore.selectSnapshotByKey("flight");

        if (!finishTime || !startTime || !flight) {
            return;
        }

        this.patchFlightTime(startTime, finishTime);
    }

    protected setHeightValue(value: number): void {
        this.modifyForm.controls.height.setValue(value, { emitEvent: false });
        this.modifyForm.controls.customHeight.setValue(value, { emitEvent: false });
    }

    protected getStartTimeMax(plannedEndAt: Date, missionEndAt?: Date): Date {
        if (missionEndAt) {
            return new Date(new Date(missionEndAt).setMinutes(missionEndAt.getMinutes() - 1));
        }

        return new Date(new Date(plannedEndAt).setMinutes(plannedEndAt.getMinutes() - 1));
    }

    protected getFinishTimeMin(plannedStartAt: Date): Date {
        return new Date(new Date(plannedStartAt).setMinutes(plannedStartAt.getMinutes() + 1));
    }

    protected getFinishTimeMax(plannedEndAt: Date, missionEndAt?: Date): Date {
        if (missionEndAt) {
            return new Date(new Date(missionEndAt).setMinutes(missionEndAt.getMinutes()));
        }

        return new Date(new Date(plannedEndAt).setMinutes(plannedEndAt.getMinutes()));
    }

    protected restoreInitialData() {
        const initialValues = this.localStore.selectSnapshotByKey("initialFormData");
        if (!initialValues) {
            return;
        }
        this.patchForm(initialValues);
    }

    private setValueToDateControl(dateControl: FormControl<Date | null>, value: Date | null) {
        dateControl.reset();
        dateControl.setValue(value, { emitEvent: false });
    }

    private get finishAtControl(): FormControl<Date | null> {
        return this.modifyForm.controls.finishAt;
    }

    private get startAtControl(): FormControl<Date | null> {
        return this.modifyForm.controls.startAt;
    }

    private get flightTimeControl(): FormControl<number | null> {
        return this.modifyForm.controls.flightTime;
    }

    private get delayStartControl(): FormControl<number | null> {
        return this.modifyForm.controls.delayStart;
    }

    private listenOnCustomHeight(): void {
        this.modifyForm.controls.customHeight.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.setHeightValue(value ?? 0);
        });
    }

    private listenOnFormChanged(): void {
        this.hasFormChanged$.pipe(untilDestroyed(this)).subscribe((isFormChanged) => this.isFormChanged.emit(isFormChanged));
    }

    private patchFlightTime(startTime: Date, finishTime: Date): void {
        const isTimeExceeded = startTime.getTime() > finishTime.getTime();

        this.modifyForm.patchValue(
            {
                flightTime: isTimeExceeded ? 0 : DateUtils.minutesBetween(startTime, finishTime),
            },
            { emitEvent: false }
        );
    }

    private patchForm(values: ModifyCheckinFormValues) {
        this.setValueToDateControl(this.startAtControl, values.startAt);
        this.setValueToDateControl(this.finishAtControl, values.finishAt);
        this.modifyForm.patchValue(values);
    }

    private prepareForm(flight: FlightItem) {
        const maxHeight = Math.min(
            flight.modification ? flight.modification.initial.maxHeight : flight.operation.geography.maxHeight,
            flight.operation.mission?.maxHeight ?? Number.POSITIVE_INFINITY
        );
        this.modifyForm.controls.customHeight.setValidators([Validators.max(maxHeight)]);

        if (flight.acceptance.phase === FlightAcceptancePhase.Accepted) {
            this.modifyForm.controls.startAt.disable({ emitEvent: false });
            this.modifyForm.controls.delayStart.disable({ emitEvent: false });
        } else {
            this.modifyForm.controls.startAt.enable({ emitEvent: false });
            this.modifyForm.controls.delayStart.enable({ emitEvent: false });
        }
        const initialValues = this.prepareInitialValues(flight);
        const heightSliderOptions = this.prepareHeightSliderOptions(flight.operation.geography.maxHeight);
        this.localStore.patchState({ initialFormData: initialValues, heightSliderOptions });
        this.patchForm(initialValues);
    }

    private prepareHeightSliderOptions(maxHeight: number) {
        const heightSliderOptions = this.localStore.selectSnapshotByKey("heightSliderOptions");

        return {
            ...heightSliderOptions,
            ceil: maxHeight,
            tickStep: maxHeight > MAX_TICK_AMOUNT * DEFAULT_TICK_VALUE ? maxHeight / MAX_TICK_AMOUNT : DEFAULT_TICK_VALUE,
        };
    }

    private prepareInitialValues(flight: FlightItem): ModifyCheckinFormValues {
        return {
            startAt: new Date(flight.operation.plannedStartAt.setSeconds(0, 0)),
            finishAt: new Date(flight.operation.plannedEndAt.setSeconds(0, 0)),
            height: flight.operation.geography.maxHeight,
            customHeight: flight.operation.geography.maxHeight,
            delayStart: 0,
            flightTime: DateUtils.minutesBetween(flight.operation.plannedStartAt, flight.operation.plannedEndAt),
        };
    }

    private prepareFormToCompare(formData: Partial<ModifyCheckinFormValues> | undefined) {
        const modified = { ...formData };
        const isAccepted = this.localStore.selectSnapshotByKey("flight")?.acceptance.phase === FlightAcceptancePhase.Accepted;
        delete modified.height;
        if (isAccepted) {
            delete modified.startAt;
            delete modified.delayStart;
        }

        return modified;
    }

    private futureDateValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const inputDate = control.value;
            const now = new Date();

            if (!inputDate || this.localStore.selectSnapshotByKey("flight")?.operation.plannedStartAt.getTime() === inputDate.getTime()) {
                return null;
            }

            return inputDate?.getTime() >= now.getTime() ? null : { timeInPast: true };
        };
    }

    private timeExceededValidator(): ValidatorFn {
        return (form) => {
            const startAtControl = form.get("startAt");
            const finishAtControl = form.get("finishAt");

            if (!startAtControl || !finishAtControl) {
                return null;
            }

            const startAtTime = startAtControl.value?.getTime();
            const finishAtTime = finishAtControl.value?.getTime();

            if (startAtTime && finishAtTime && startAtTime >= finishAtTime) {
                return { startTimeExceeded: true };
            }

            return null;
        };
    }
}
