<section class="details-wrapper" *ngrxLet="flight$ as flight">
    <div class="details">
        <dtm-ui-label-value [label]="'datsLibFlights.operationDetails.startLabel' | transloco">
            <div class="time-wrapper">
                <dats-lib-time-label
                    [date]="flight.modification ? flight.modification.modified.plannedStartAt : flight.operation.plannedStartAt"
                    [isIconDisplayed]="false"
                ></dats-lib-time-label>
                <dats-lib-time-label
                    *ngIf="flight.modification"
                    [date]="flight.modification.initial.plannedStartAt"
                    [isObsolete]="true"
                    [isIconDisplayed]="false"
                ></dats-lib-time-label>
            </div>
        </dtm-ui-label-value>

        <dtm-ui-label-value [label]="'datsLibFlights.operationDetails.finishLabel' | transloco">
            <div class="time-wrapper">
                <dats-lib-time-label
                    [date]="flight.modification ? flight.modification.modified.plannedEndAt : flight.operation.plannedEndAt"
                    [isIconDisplayed]="false"
                ></dats-lib-time-label>
                <dats-lib-time-label
                    *ngIf="flight.modification"
                    [date]="flight.modification.initial.plannedEndAt"
                    [isObsolete]="true"
                    [isIconDisplayed]="false"
                ></dats-lib-time-label>
            </div>
        </dtm-ui-label-value>
        <dtm-ui-label-value [label]="'datsLibFlights.operationDetails.heightLabel' | transloco">
            <dats-lib-height-label
                [height]="flight.operation.geography.maxHeight"
                [elevation]="flight.operation.geography.maxElevation"
                [isMaxHeight]="false"
                [isIconDisplayed]="false"
            ></dats-lib-height-label>
        </dtm-ui-label-value>
    </div>
    <div
        class="time-info"
        *ngIf="flight.operation.mission?.endAt"
        [matTooltip]="flight.operation.mission?.endAt | localizeDate : { timeZoneName: 'short', hour: '2-digit', minute: '2-digit' }"
    >
        <dtm-ui-icon name="error-warning-fill"></dtm-ui-icon>
        {{
            "datsLibFlights.operationDetails.timeInfo"
                | transloco
                    : {
                          time: flight.operation.mission?.endAt | localizeDate : { timeStyle: "short", timeZone: "UTC" },
                          date: flight.operation.mission?.endAt | localizeDate : { timeZone: "UTC", timeZoneName: "short" }
                      }
        }}
    </div>
</section>
