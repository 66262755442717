<ng-container
    *ngrxLet="{
        isAlertSelectButtonDisabled: isAlertSelectButtonDisabled$,
        isManageAlertsFeatureAvailable: isManageAlertsFeatureAvailable$,
        heightFilter: heightFilter$,
        connectionStatus: connectionStatus$,
        isClusteringEnabled: isClusteringEnabled$
    } as vm"
>
    <div class="block">
        <dats-lib-flights-navbar-connection-status [connectionStatus]="vm.connectionStatus" />
        <dats-lib-flights-navbar-clock />
    </div>
    <div class="block">
        <dats-lib-height-filter [heightFilter]="vm.heightFilter" (heightChange)="updateHeightFilter($event)" />
        <mat-slide-toggle
            class="clustering-toggle toggle-bar-center"
            labelPosition="before"
            [checked]="vm.isClusteringEnabled"
            (change)="toggleClustering($event)"
        >
            {{ "datsLibFlights.clusteringToggleLabel" | transloco }}
        </mat-slide-toggle>
    </div>
    <div class="block" *ngIf="vm.isManageAlertsFeatureAvailable">
        <dats-lib-alert-select-button
            [isDisabled]="vm.isAlertSelectButtonDisabled"
            (alertGeometrySourceTypeChange)="changeAlertGeometrySourceType($event)"
        />
    </div>
</ng-container>
