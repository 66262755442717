import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DateUtils, ISO8601TimeDuration, LocalComponentStore, MILLISECONDS_IN_SECOND } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { map } from "rxjs/operators";
import { Alert, AlertBadgeType } from "../../../models/alert.models";
import { AlertTimeRange } from "../alert-time-range/alert-time-range.component";

interface ModifyAlertTimeFormComponentState {
    alert: Alert | undefined;
    isLimitedTimeRange: boolean;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-modify-alert-time-form",
    templateUrl: "./modify-alert-time-form.component.html",
    styleUrls: ["./modify-alert-time-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ModifyAlertTimeFormComponent {
    @Input() public set alert(value: Alert) {
        this.localStore.patchState({
            alert: value,
        });

        this.timeRangeControl.setValue({
            startDate: value.startAt,
            duration: value.finishAt
                ? DateUtils.convertSecondsToISO8601Duration((value.finishAt.getTime() - value.startAt.getTime()) / MILLISECONDS_IN_SECOND)
                : ("PT1H" as ISO8601TimeDuration),
        });
    }

    @Output() public readonly actionCancel = new EventEmitter<void>();
    @Output() public readonly actionConfirm = new EventEmitter<{ id: string; timeRange: AlertTimeRange | undefined }>();

    protected readonly timeRangeControl = new FormControl<AlertTimeRange | null>(null);
    protected readonly AlertBadgeType = AlertBadgeType;
    protected readonly alert$ = this.localStore.selectByKey("alert");
    protected readonly isLimitedTimeRange$ = this.localStore.selectByKey("isLimitedTimeRange");
    protected readonly hasStarted$ = this.alert$.pipe(map((alert) => (alert ? new Date(alert.startAt) <= new Date() : false)));

    constructor(private readonly localStore: LocalComponentStore<ModifyAlertTimeFormComponentState>) {
        this.localStore.setState({
            alert: undefined,
            isLimitedTimeRange: true,
        });
    }

    protected tryEmitConfirm(): void {
        const isLimitedTimeRange = this.localStore.selectSnapshotByKey("isLimitedTimeRange");
        const alert = this.localStore.selectSnapshotByKey("alert");
        if (!this.timeRangeControl.value || !alert) {
            return;
        }

        this.actionConfirm.emit({ id: alert.id, timeRange: isLimitedTimeRange ? this.timeRangeControl.value : undefined });
    }

    protected setLimitedTimeRange(value: boolean): void {
        this.localStore.patchState({
            isLimitedTimeRange: value,
        });
    }
}
