import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { AlertGeometrySourceType } from "../../../alert/models/alert.models";
import { AlertActions } from "../../../alert/state/alert.actions";
import { AlertState } from "../../../alert/state/alert.state";
import { DATSFeature } from "../../../capabilities/models/capabilities.model";
import { CapabilitiesState } from "../../../capabilities/state/capabilities.state";
import { FlightsActions } from "../../state/flights.actions";
import { FlightsState } from "../../state/flights.state";

interface NavbarComponentState {
    isAtcController: boolean;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-flights-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NavbarComponent {
    protected readonly isAlertSelectButtonDisabled$ = this.store.select(AlertState.selectedAlertGeometrySourceType);
    protected readonly heightFilter$ = this.store.select(FlightsState.heightFilter);
    protected readonly isManageAlertsFeatureAvailable$ = this.store.select(CapabilitiesState.isFeatureAvailable(DATSFeature.ManageAlerts));
    protected readonly connectionStatus$ = this.websocketService.connectionStatus$;
    protected readonly isClusteringEnabled$ = this.store.select(FlightsState.isClusteringEnabled);

    constructor(
        private readonly localStore: LocalComponentStore<NavbarComponentState>,
        private readonly store: Store,
        private readonly websocketService: WebsocketService
    ) {
        this.localStore.setState({ isAtcController: false });
    }

    protected changeAlertGeometrySourceType(alertGeometrySourceType: AlertGeometrySourceType) {
        this.store.dispatch(new AlertActions.SelectAlertGeometrySourceType(alertGeometrySourceType));
    }

    protected toggleClustering(event: MatSlideToggleChange) {
        this.store.dispatch(new FlightsActions.SetIsClusteringEnabled(event.checked));
    }

    protected updateHeightFilter(height: number | undefined) {
        this.store.dispatch(new FlightsActions.SetHeightFilter(height ?? 0));
    }
}
