import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { LetDirective } from "@ngrx/component";
import { JurisdictionMission } from "../../models/flight.models";

interface MissionItemComponentState {
    mission: JurisdictionMission | undefined;
}

@Component({
    selector: "dats-lib-mission-item",
    standalone: true,
    imports: [CommonModule, SharedUiModule, SharedI18nModule, LetDirective, TranslocoModule, MatTooltipModule],
    templateUrl: "./mission-item.component.html",
    styleUrls: ["./mission-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionItemComponent {
    @Input({ required: true }) public set mission(value: JurisdictionMission) {
        this.localStore.patchState({ mission: value });
    }

    protected readonly mission$ = this.localStore.selectByKey("mission").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<MissionItemComponentState>) {
        this.localStore.setState({
            mission: undefined,
        });
    }

    protected openMissionDetails() {
        // TODO: DTATS-232 Open mission details
        alert("DTATS-232 Open mission details");
    }
}
