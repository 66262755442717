import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";

interface ExpandablePanelComponentState {
    isExpanded: boolean;
    isDisabled: boolean;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-expandable-panel",
    templateUrl: "./expandable-panel.component.html",
    styleUrls: ["./expandable-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimationWithMargin()],
    providers: [LocalComponentStore],
})
export class ExpandablePanelComponent {
    @HostBinding("@blockInitialRenderAnimation") public get blockInitialRenderAnimation() {
        return true;
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set isDisabled(value: BooleanInput) {
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }

    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");

    @Output() public readonly expandChange = new EventEmitter<boolean>();

    constructor(private readonly localStore: LocalComponentStore<ExpandablePanelComponentState>) {
        this.localStore.setState({ isExpanded: false, isDisabled: false });
    }

    protected togglePanel($event: Event, isExpanded: boolean, isDisabled: boolean) {
        if (isDisabled) {
            return;
        }

        $event.preventDefault();
        this.localStore.patchState({ isExpanded: !isExpanded });
        this.expandChange.emit(!isExpanded);
    }
}
