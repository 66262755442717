import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { CapabilitiesState } from "./state/capabilities.state";

@NgModule({
    imports: [CommonModule, NgxsModule.forFeature([CapabilitiesState])],
})
export class CapabilitiesModule {
    public static forRoot(): ModuleWithProviders<CapabilitiesModule> {
        return {
            ngModule: CapabilitiesModule,
            providers: [],
        };
    }
}
