<footer
    class="actions"
    *ngrxLet="{
        confirmButtonType: confirmButtonType$,
        confirmButtonText: confirmButtonText$,
    } as vm"
>
    <ng-content></ng-content>
    <section class="modifications">
        <button type="button" class="button-secondary" (click)="actionCancel.emit()">
            {{ "datsLibShared.actionSidebar.cancelButtonLabel" | transloco }}
        </button>
        <button
            type="button"
            [ngClass]="vm.confirmButtonType === ButtonTheme.Warn ? 'button-warn' : 'button-primary'"
            (click)="actionConfirm.emit()"
        >
            {{ !vm.confirmButtonText ? ("datsLibShared.actionSidebar.saveButtonLabel" | transloco) : vm.confirmButtonText }}
        </button>
    </section>
</footer>
