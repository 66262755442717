import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { Observable, combineLatest, distinctUntilChanged, map, merge, startWith, switchMap } from "rxjs";
import {
    AlertGeometrySourceType,
    AlertPointOfInterest,
    AlertProposalQueryParams,
    AlertTextTemplate,
    AlertType,
} from "../../../models/alert.models";
import { AlertFormComponent, AlertFormGroup } from "../alert-form/alert-form.component";

interface HemsAlertFormComponentState {
    hemsBases: AlertPointOfInterest[];
    templates: AlertTextTemplate[];
    alertFormComponent: AlertFormComponent | undefined;
    selectedAlertType: AlertType;
}

export type HemsAlertFormGroupValue = {
    source: AlertGeometrySourceType.HEMS;
    hemsBase: AlertPointOfInterest;
} & Required<FormGroup<AlertFormGroup>["value"]>;

@UntilDestroy()
@Component({
    selector: "dats-lib-hems-alert-form",
    templateUrl: "./hems-alert-form.component.html",
    styleUrls: ["../../../../shared/components/sidebar/action-sidebar.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HemsAlertFormComponent {
    @Input({ required: true }) public set hemsBases(value: AlertPointOfInterest[] | undefined) {
        this.localStore.patchState({ hemsBases: value ?? [] });
    }
    @Input({ required: true }) public set templates(value: AlertTextTemplate[]) {
        this.localStore.patchState({ templates: value });
    }
    @Input() public set selectedPoi(value: AlertPointOfInterest | undefined) {
        this.hemsBaseFormControl.setValue(value ?? null);
    }
    @Input() public set selectedAlertType(value: AlertType | undefined) {
        this.localStore.patchState({ selectedAlertType: value });
    }

    @ViewChild(AlertFormComponent, { static: false }) public set alertForm(value: AlertFormComponent | undefined) {
        this.localStore.patchState({ alertFormComponent: value });
    }

    private readonly alertFormComponent$ = this.localStore.selectByKey("alertFormComponent");
    private readonly alertFormChanges$ = this.alertFormComponent$.pipe(
        RxjsUtils.filterFalsy(),
        switchMap((form) => form.valueChange),
        startWith<FormGroup<AlertFormGroup>["value"]>({})
    );
    private readonly alertFormTypeChanges$ = this.alertFormComponent$.pipe(
        RxjsUtils.filterFalsy(),
        switchMap((form) => form.typeChange)
    );

    @Output() public readonly actionCancel = new EventEmitter<void>();
    @Output() public readonly actionConfirm = new EventEmitter<HemsAlertFormGroupValue>();
    @Output() public readonly isFormChanged = this.alertFormChanges$.pipe(
        map((value) => !!value.message),
        distinctUntilChanged()
    );

    protected readonly hemsBaseFormControl = new FormControl<AlertPointOfInterest | null>(null, [Validators.required]);
    @Output() public readonly messageTemplatesQueryParamsChange: Observable<AlertProposalQueryParams> = combineLatest([
        this.hemsBaseFormControl.valueChanges.pipe(
            map((value) => value?.id),
            RxjsUtils.filterFalsy()
        ),
        this.alertFormChanges$.pipe(
            map((value) => value?.type),
            RxjsUtils.filterFalsy()
        ),
    ]).pipe(
        map(([poiId, type]) => ({ poiId, type })),
        distinctUntilChanged(equal)
    );

    protected readonly hemsBases$ = this.localStore.selectByKey("hemsBases");
    protected readonly templates$ = this.localStore.selectByKey("templates");
    protected readonly selectedAlertType$ = merge(
        this.localStore.selectByKey("selectedAlertType"),
        this.alertFormChanges$.pipe(map((value) => value?.type))
    ).pipe(
        startWith(AlertType.NoFlyZone),
        map((value) => value ?? AlertType.NoFlyZone)
    );

    constructor(private readonly localStore: LocalComponentStore<HemsAlertFormComponentState>) {
        this.localStore.setState({
            hemsBases: [],
            templates: [],
            alertFormComponent: undefined,
            selectedAlertType: AlertType.NoFlyZone,
        });
    }

    protected tryEmitConfirm(): void {
        const alertFormComponent = this.localStore.selectSnapshotByKey("alertFormComponent");

        if (alertFormComponent?.alertForm === undefined) {
            return;
        }

        alertFormComponent.alertForm.updateValueAndValidity();
        alertFormComponent.alertForm.markAllAsTouched();
        this.hemsBaseFormControl.updateValueAndValidity();
        this.hemsBaseFormControl.markAsTouched();

        if (alertFormComponent.alertForm.valid && this.hemsBaseFormControl.valid && this.hemsBaseFormControl.value !== null) {
            this.actionConfirm.emit({
                ...alertFormComponent.alertForm.getRawValue(),
                source: AlertGeometrySourceType.HEMS,
                hemsBase: this.hemsBaseFormControl.value,
            });
        }
    }
}
