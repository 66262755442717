<ng-container
    *ngrxLet="{
        totalCount: totalCount$,
        chipsFilters: chipsFilters$,
        selectAllValueLabel: selectAllValueLabel$,
        isMultiple: isMultiple$
    } as vm"
>
    <mat-chip-list *ngIf="vm.chipsFilters.length" selectable [multiple]="vm.isMultiple" [formControl]="chipsControl">
        <mat-chip
            *ngIf="vm.selectAllValueLabel"
            [class.selected]="chipsControl.value.length === 0"
            class="chip"
            (click)="resetChipsControl()"
        >
            {{ vm.selectAllValueLabel | transloco : { value: vm.totalCount } }}
        </mat-chip>

        <mat-chip
            #chip="matChip"
            (click)="vm.isMultiple ? handleSelectionMultiple(chip) : handleSelectionSingle(chip)"
            *ngFor="let item of vm.chipsFilters"
            class="chip"
            [value]="item.value"
        >
            <ng-container *ngIf="item.valueLabelTranslationKey; else plainValueTemplate">
                <ng-container *ngIf="item.valueLabelParam !== undefined; else defaultValueLabelTemplate">
                    {{ item.valueLabelTranslationKey | transloco : { value: item.value, valueLabelParam: item.valueLabelParam } }}
                </ng-container>

                <ng-template #defaultValueLabelTemplate>
                    {{ item.valueLabelTranslationKey | transloco : { value: item.value } }}
                </ng-template>
            </ng-container>

            <ng-template #plainValueTemplate> {{ item.value }} </ng-template>
        </mat-chip>
    </mat-chip-list>
</ng-container>
