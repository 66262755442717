<ng-container *ngrxLet="{ alert: alert$, alertsBadgeType: alertsBadgeType$, isMenuOpen: isMenuOpen$ } as vm">
    <div class="alert" [ngClass]="alertBadgesByType[vm.alertsBadgeType].class" *ngIf="vm.alert && vm.alertsBadgeType" #alertItem>
        <div class="alert-content">
            <dtm-ui-icon class="alert-icon" slot="start" [name]="alertBadgesByType[vm.alert.type].icon"></dtm-ui-icon>
            <ng-container [ngSwitch]="true">
                <ng-container *ngSwitchCase="vm.alertsBadgeType === AlertBadgeType.NotStarted && !!vm.alert.startAt">
                    <span class="truncate-text">{{ vm.alert.title }}</span>
                    {{
                        "datsLibAlert.alertList.alertFromLabel"
                            | transloco : { time: vm.alert.startAt | localizeDate : { timeStyle: "short" } }
                    }}
                </ng-container>
                <ng-container *ngSwitchCase="vm.alertsBadgeType !== AlertBadgeType.NotStarted && !!vm.alert.finishAt">
                    <span class="truncate-text">{{ vm.alert.title }}</span>
                    {{
                        "datsLibAlert.alertList.alertToLabel"
                            | transloco : { time: vm.alert.finishAt | localizeDate : { timeStyle: "short" } }
                    }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <span class="truncate-text">{{ vm.alert.title }}</span>
                </ng-container>
            </ng-container>
        </div>
        <button
            type="button"
            class="button-icon button-alert"
            [class.active]="vm.isMenuOpen"
            [ngClass]="alertBadgesByType[vm.alertsBadgeType].class"
            [matMenuTriggerFor]="alertMenu"
            (menuOpened)="onMenuOpened()"
            (menuClosed)="onMenuClosed()"
        >
            <dtm-ui-icon name="more"></dtm-ui-icon>
        </button>

        <mat-menu #alertMenu="matMenu" class="alert-menu" [xPosition]="'before'">
            <div class="button-action-wrapper">
                <button mat-menu-item type="button" (click)="hideBar()" class="button-action">
                    <div class="button-action-content">
                        <dtm-ui-icon name="postpone"></dtm-ui-icon>
                        <span>{{
                            "datsLibAlert.alertList.alertActions.hideBarLabel"
                                | transloco : { value: DEFAULT_HIDE_BAR_TIME | localizeNumber }
                        }}</span>
                    </div>
                </button>
                <button mat-menu-item type="button" (click)="hideBarUntilEnd()" class="button-action">
                    <div class="button-action-content">
                        <dtm-ui-icon name="eye-off"></dtm-ui-icon>
                        <span>{{ "datsLibAlert.alertList.alertActions.hideBarUntilEndLabel" | transloco }}</span>
                    </div>
                </button>
                <button mat-menu-item type="button" (click)="extendAlertTime()" class="button-action">
                    <div class="button-action-content">
                        <dtm-ui-icon name="timer-line"></dtm-ui-icon>
                        <span>{{
                            "datsLibAlert.alertList.alertActions.extendAlertTimeLabel"
                                | transloco : { value: DEFAULT_EXTEND_TIME | localizeNumber }
                        }}</span>
                    </div>
                </button>
                <button mat-menu-item type="button" (click)="modifyAlert()" class="button-action">
                    <div class="button-action-content">
                        <dtm-ui-icon name="equalizer-fill" class="highlight"></dtm-ui-icon>
                        <span>{{ "datsLibAlert.alertList.alertActions.modifyLabel" | transloco }}</span>
                    </div>
                </button>
                <button mat-menu-item type="button" (click)="finishAlert()" class="button-action">
                    <div class="button-action-content">
                        <dtm-ui-icon name="close" class="warning"></dtm-ui-icon>
                        <span>{{ "datsLibAlert.alertList.alertActions.finishLabel" | transloco }}</span>
                    </div>
                </button>
            </div>
        </mat-menu>
    </div>
</ng-container>
