<ng-container *ngrxLet="flightDetails$ as flightDetails">
    <dats-lib-mission-details-basic-data
        *ngIf="flightDetails?.mission"
        [mission]="flightDetails?.mission"
    ></dats-lib-mission-details-basic-data>

    <dats-lib-mission-details-pilot
        *ngIf="flightDetails?.flight?.flightItem?.operation?.pilot"
        [pilot]="flightDetails?.flight?.flightItem?.operation?.pilot"
    ></dats-lib-mission-details-pilot>

    <dats-lib-mission-details-operator
        *ngIf="flightDetails?.flight?.flightItem?.operation?.operator"
        [operator]="flightDetails?.flight?.flightItem?.operation?.operator"
    ></dats-lib-mission-details-operator>

    <dats-lib-mission-details-uav
        *ngIf="flightDetails?.flight?.flightItem?.operation?.uav"
        [uav]="flightDetails?.flight?.flightItem?.operation?.uav"
    ></dats-lib-mission-details-uav>

    <dats-lib-mission-details-archive-reason
        *ngIf="flightDetails?.flight?.archiveReason?.value"
        [archiveReason]="flightDetails?.flight?.archiveReason?.value"
    ></dats-lib-mission-details-archive-reason>

    <dats-lib-mission-details-reject-reason
        *ngIf="flightDetails?.flight?.rejectReason?.value"
        [rejectReason]="flightDetails?.flight?.rejectReason?.value"
    ></dats-lib-mission-details-reject-reason>

    <dats-lib-mission-details-notes></dats-lib-mission-details-notes>
</ng-container>
