import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, QueryList, ViewChildren } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { combineLatest, map } from "rxjs";
import { CheckinAction, FlightCategory, FlightItem } from "../../models/flight.models";
import { FlightItemComponent } from "../flight-item/flight-item.component";

interface CategoryFlightListComponentState {
    category: FlightCategory | undefined;
    flights: FlightItem[];
    total: number;
    isExpanded: boolean;
    isAtcController: boolean;
    selectedFlightId: string | undefined;
    isMaintainFlightsFeatureAvailable: boolean;
    isControlAirTrafficFeatureAvailable: boolean;
}

@Component({
    selector: "dats-lib-category-flight-list",
    templateUrl: "./category-flight-list.component.html",
    styleUrls: ["./category-flight-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CategoryFlightListComponent {
    @Input({ required: true }) public set flights(value: FlightItem[] | undefined) {
        this.localStore.patchState({ flights: value ?? [] });
    }

    @Input() public set total(value: number) {
        this.localStore.patchState({ total: value });
    }

    @Input() public set category(value: FlightCategory | undefined) {
        this.localStore.patchState({ category: value });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    @Input() public set isAtcController(value: BooleanInput) {
        this.localStore.patchState({ isAtcController: coerceBooleanProperty(value) });
    }

    @Input({ required: true }) public set selectedFlightId(value: string | undefined) {
        this.localStore.patchState({ selectedFlightId: value });
    }
    @Input() public set isMaintainFlightsFeatureAvailable(value: BooleanInput) {
        this.localStore.patchState({ isMaintainFlightsFeatureAvailable: coerceBooleanProperty(value) });
    }
    @Input() public set isControlAirTrafficFeatureAvailable(value: BooleanInput) {
        this.localStore.patchState({ isControlAirTrafficFeatureAvailable: coerceBooleanProperty(value) });
    }

    @Output() public readonly confirmationResend = new EventEmitter<string>();
    @Output() public readonly checkinModify = new EventEmitter<CheckinAction>();
    @Output() public readonly selectedFlightChange = new EventEmitter<string>();
    @Output() public readonly expandChange = new EventEmitter<boolean>();

    @HostBinding("class") protected get categoryClassName() {
        return this.getCategoryClassName(this.localStore.selectSnapshotByKey("category"));
    }

    protected readonly flights$ = this.localStore.selectByKey("flights");
    protected readonly total$ = this.localStore.selectByKey("total");
    protected readonly category$ = this.localStore.selectByKey("category").pipe(RxjsUtils.filterFalsy());
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isAtcController$ = this.localStore.selectByKey("isAtcController");
    protected readonly selectedFlightId$ = this.localStore.selectByKey("selectedFlightId");
    protected readonly isMaintainFlightsFeatureAvailable$ = this.localStore.selectByKey("isMaintainFlightsFeatureAvailable");
    protected readonly isControlAirTrafficFeatureAvailable$ = this.localStore.selectByKey("isControlAirTrafficFeatureAvailable");
    protected readonly filteredFlights$ = combineLatest([this.flights$, this.category$]).pipe(
        map(([flights, category]) => flights.filter((flight) => flight.category === category))
    );

    @ViewChildren(FlightItemComponent) private readonly flightItemComponents: QueryList<FlightItemComponent> | undefined;

    constructor(private readonly localStore: LocalComponentStore<CategoryFlightListComponentState>) {
        this.localStore.setState({
            flights: [],
            category: undefined,
            isExpanded: true,
            total: 0,
            isAtcController: false,
            selectedFlightId: undefined,
            isMaintainFlightsFeatureAvailable: false,
            isControlAirTrafficFeatureAvailable: false,
        });
    }

    public scrollFlightIntoView(flightId: string) {
        this.flightItemComponents?.find((flightItem) => flightItem.flightId === flightId)?.scrollIntoView();
    }

    protected changeSelection(isSelected: boolean, flight: FlightItem): void {
        this.selectedFlightChange.emit(isSelected ? flight.id : undefined);
    }

    private getCategoryClassName(category: FlightCategory | undefined): string {
        switch (category) {
            case FlightCategory.Pending_112:
                return "category-pending-112";
            case FlightCategory.Emergency:
                return "category-emergency";
            case FlightCategory.Stop:
                return "category-stop";
            case FlightCategory.AcceptedManually:
            case FlightCategory.Completed:
            case FlightCategory.Rejected:
            case FlightCategory.Canceled:
                return "category-other";
            case FlightCategory.Overdue:
            case FlightCategory.OverdueSystem:
            case FlightCategory.OverdueOther:
                return "category-overdue";
            default:
                return "default";
        }
    }
}
