import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoPipe } from "@jsverse/transloco";
import { LetDirective } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { AlertPointOfInterest } from "../../../../alert/models/alert.models";
import { AlertActions } from "../../../../alert/state/alert.actions";

interface PointsOfInterestComponentState {
    isBookmarkedHospitalsVisible: boolean;
    hospitalList: AlertPointOfInterest[];
    selectedHospitalId: string | undefined;
}

@Component({
    selector: "dats-lib-points-of-interest",
    standalone: true,
    imports: [CommonModule, SharedUiModule, LetDirective, MatTooltipModule, TranslocoPipe],
    templateUrl: "./points-of-interest.component.html",
    styleUrls: ["./points-of-interest.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PointsOfInterestComponent {
    private readonly store = inject(Store);
    private readonly localStore = inject(LocalComponentStore<PointsOfInterestComponentState>);

    @Input() public set hospitalList(value: AlertPointOfInterest[] | undefined) {
        this.localStore.patchState({ hospitalList: value ?? [] });
    }

    @Output() public hospitalClick = new EventEmitter<void>();

    protected readonly isBookmarkedHospitalsVisible$ = this.localStore.selectByKey("isBookmarkedHospitalsVisible");
    protected readonly hospitalList$ = this.localStore.selectByKey("hospitalList");
    protected readonly selectedHospitalId$ = this.localStore.selectByKey("selectedHospitalId");

    constructor() {
        this.localStore.setState({
            isBookmarkedHospitalsVisible: false,
            hospitalList: [],
            selectedHospitalId: undefined,
        });
    }

    protected displayBookmarkedHospitals(): void {
        const isCurrentBookmarkedHospitalsVisible = this.localStore.selectSnapshotByKey("isBookmarkedHospitalsVisible");
        if (!isCurrentBookmarkedHospitalsVisible) {
            this.store.dispatch(new AlertActions.GetAllHospitals());
        }

        this.localStore.patchState(({ isBookmarkedHospitalsVisible }) => ({ isBookmarkedHospitalsVisible: !isBookmarkedHospitalsVisible }));
    }

    protected selectHospital(hospital: AlertPointOfInterest) {
        this.localStore.patchState({
            selectedHospitalId: hospital.id,
        });
    }
}
