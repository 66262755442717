import { GeoJSON } from "@dtm-frontend/shared/ui";
import { LatLngBounds } from "leaflet";

export interface Capabilities {
    permissions: DATSFeature[];
    jurisdiction: Jurisdiction;
    grid: Grid;
}

export interface Jurisdiction {
    data: string;
    name?: string;
    geometry?: GeoJSON.MultiPolygon | undefined;
    bounds?: LatLngBounds;
}

export const enum GridType {
    CTR = "CTR",
    FIS = "FIS",
    OTHER = "OTHER",
}

export interface Grid {
    type: GridType;
    geometry: GeoJSON.MultiPolygon | undefined;
}

export enum DATSFeature {
    ManageAlerts = "MANAGE_ALERTS",
    ServiceFlightInformation = "SERVICE_FLIGHT_INFORMATION",
    ControlAirTraffic = "CONTROL_AIR_TRAFFIC",
    MaintainFlights = "MAINTAIN_FLIGHTS",
    TestSystem = "TEST_SYSTEM",
}
