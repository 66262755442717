import { LatLngBounds, LatLngTuple } from "leaflet";
import WKB from "ol/format/WKB";
import { MultiPolygon } from "ol/geom";
import { Capabilities, DATSFeatures, Jurisdiction } from "../models/capabilities.model";

export interface CapabilitiesResponseBody {
    permissions: DATSFeatures[];
    jurisdiction: JurisdictionResponseBody;
}

export interface JurisdictionResponseBody {
    "@type": string;
    data: string;
}

function convertWkbToLatLngBounds(wkbHex: string) {
    const wkbBuffer = new WKB();
    const geometry = wkbBuffer.readGeometry(wkbHex);

    if (geometry instanceof MultiPolygon) {
        const rawCoordinates = geometry.getCoordinates().flat(2);
        const latLngTuples: LatLngTuple[] = rawCoordinates.map((coordinate) => [+coordinate[1], +coordinate[0]]);

        return new LatLngBounds(latLngTuples);
    }

    return undefined;
}

export function convertJurisdictionResponseBodyToJurisdiction(response: JurisdictionResponseBody): Jurisdiction {
    return {
        data: response.data,
        bounds: convertWkbToLatLngBounds(response.data),
    };
}

export function convertCapabilitiesResponseBodyToCapabilities(response: CapabilitiesResponseBody): Capabilities {
    return {
        permissions: response.permissions,
        jurisdiction: convertJurisdictionResponseBodyToJurisdiction(response.jurisdiction),
    };
}
