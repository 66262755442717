import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AlertFormGroup } from "../alert-form/alert-form.component";

interface AirspaceAlertFormComponentState {
    zones: string[];
    templates: Record<string, string>;
}

export type AirspaceAlertFormGroupValue = {
    zone: string | null;
} & FormGroup<AlertFormGroup>["value"];

@UntilDestroy()
@Component({
    selector: "dats-lib-airspace-alert-form",
    templateUrl: "./airspace-alert-form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AirspaceAlertFormComponent {
    @Input({ required: true }) public set zones(value: string[]) {
        this.localStore.patchState({ zones: value });
    }
    @Input({ required: true }) public set templates(value: Record<string, string>) {
        this.localStore.patchState({ templates: value });
    }

    @Output() public readonly cancel = new EventEmitter<void>();
    @Output() public readonly save = new EventEmitter<AirspaceAlertFormGroupValue>();

    protected readonly zones$ = this.localStore.selectByKey("zones");
    protected readonly templates$ = this.localStore.selectByKey("templates");

    protected readonly zoneFormControl = new FormControl<string | null>(null, [Validators.required]);

    constructor(private readonly localStore: LocalComponentStore<AirspaceAlertFormComponentState>) {
        this.localStore.setState({
            zones: [],
            templates: {},
        });
    }

    protected emitSave(alertFormValue: FormGroup<AlertFormGroup>): void {
        this.save.emit({
            ...alertFormValue.value,
            zone: this.zoneFormControl.value,
        });
    }
}
