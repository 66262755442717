<div class="confirmation-bar">
    <div class="modification-warning">
        <dtm-ui-icon name="timer-flash-fill"></dtm-ui-icon>
        {{ "datsLibFlights.confirmationBar.warningText" | transloco }}
    </div>
    <button type="button" class="button-secondary" (click)="resend.emit()">
        <dtm-ui-icon name="refresh"></dtm-ui-icon>
        <span>{{ "datsLibFlights.confirmationBar.resendButtonLabel" | transloco }}</span>
    </button>
</div>
