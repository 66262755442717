import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Alert, AlertBadgeType } from "../../models/alert.models";

interface AlertsListComponentState {
    alerts: Alert[] | undefined;
    alertsBadgeType: AlertBadgeType;
}

@Component({
    selector: "dats-lib-alerts-list",
    templateUrl: "./alerts-list.component.html",
    styleUrls: ["./alerts-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AlertsListComponent {
    @Input() public set alerts(value: Alert[] | undefined) {
        this.localStore.patchState({ alerts: value ?? [] });
    }
    @Input() public set alertsBadgeType(value: AlertBadgeType) {
        this.localStore.patchState({ alertsBadgeType: value });
    }

    @Output() public readonly finishAlert = new EventEmitter<Alert>();
    @Output() public readonly modifyAlert = new EventEmitter<Alert>();

    private readonly localStore = inject(LocalComponentStore<AlertsListComponentState>);
    protected readonly alerts$ = this.localStore.selectByKey("alerts");
    protected readonly alertsBadgeType$ = this.localStore.selectByKey("alertsBadgeType");

    constructor() {
        this.localStore.setState({ alerts: undefined, alertsBadgeType: AlertBadgeType.NotStarted });
    }
}
