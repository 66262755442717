<dats-lib-alert-form
    [templates]="vm.templates"
    [contentForms]="[zoneFormControl]"
    *ngrxLet="{ zones: zones$, templates: templates$ } as vm"
    (cancel)="cancel.emit()"
    (save)="emitSave($event)"
>
    <ng-container headerSlot>
        {{ "datsLibAlert.alertForms.airspaceAlertForm.header" | transloco }}
    </ng-container>
    <ng-container contentSlot>
        <dtm-ui-select-field
            [placeholder]="'datsLibAlert.alertForms.selectPlaceholder' | transloco"
            [formControl]="zoneFormControl"
            [isClearable]="false"
        >
            <label>{{ "datsLibAlert.alertForms.airspaceAlertForm.zoneLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let zone of vm.zones" [value]="zone">
                {{ zone }}
            </dtm-ui-select-option>

            <div class="field-error" *dtmUiFieldHasError="zoneFormControl; name: 'required'">
                {{ "datsLibAlert.requiredFieldErrorMessage" | transloco }}
            </div>
        </dtm-ui-select-field>
    </ng-container>
</dats-lib-alert-form>
