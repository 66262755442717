import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { GeographicCoordinatesDirection, GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import {
    DateUtils,
    DeepFormType,
    FormStateController,
    HOURS_IN_DAY,
    ISO8601TimeDuration,
    METERS_IN_KILOMETER,
    MINUTES_IN_HOUR,
    SECONDS_IN_MINUTE,
} from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import {
    MissionFlightCategory,
    OpenMissionSubCategory,
    SpecificMissionSubCategory,
    TechnicalCheckinData,
    UavMass,
} from "../../models/flight.models";

type InternalCreateTechnicalCheckinFormGroup = DeepFormType<Omit<TechnicalCheckinData, "duration"> & { duration: number }>;

const MAX_FLIGHT_HEIGHT = 2623; // NOTE: 2623 m is the maximum height of the highest mountain in Poland (Rysy) with 120 m of buffer
const MAX_FLIGHT_RADIUS = 1000 * METERS_IN_KILOMETER;
const DURATION_STEP = 10;
const HEIGHT_STEP = 10;
const RADIUS_STEP = 50;
const UAV_MASS_CATEGORIES = [UavMass.VeryLight, UavMass.Light, UavMass.Medium, UavMass.MediumLight, UavMass.Heavy];
const MAX_DURATION = HOURS_IN_DAY * MINUTES_IN_HOUR;
const PILOT_NUMBER_MAX_LENGTH = 30;
const MISSION_CATEGORIES = [MissionFlightCategory.Open, MissionFlightCategory.Specific];
const OPEN_MISSION_SUB_CATEGORIES = [OpenMissionSubCategory.A1, OpenMissionSubCategory.A2, OpenMissionSubCategory.A3];
const SPECIFIC_MISSION_SUB_CATEGORIES = [
    SpecificMissionSubCategory.STS_PDRA,
    SpecificMissionSubCategory.NSTS,
    SpecificMissionSubCategory.LUC,
    SpecificMissionSubCategory.CAAPermit,
    SpecificMissionSubCategory.ModellingClub,
];

const DEFAULT_PILOT_ID = "POL-RP-1234567890";
const DEFAULT_UAV_MASS = UavMass.Light;
const DEFAULT_FLIGHT_HEIGHT = 100;
const DEFAULT_FLIGHT_RADIUS = 500;
const DEFAULT_CATEGORY = MissionFlightCategory.Open;
const DEFAULT_OPEN_SUB_CATEGORY = OpenMissionSubCategory.A1;
const DEFAULT_SPECIFIC_SUB_CATEGORY = SpecificMissionSubCategory.STS_PDRA;
const DEFAULT_DURATION_MINUTES = 30;
const DEFAULT_LATITUDE = 50;
const DEFAULT_LONGITUDE = 19;

@UntilDestroy()
@Component({
    selector: "dats-lib-create-technical-checkin-form",
    templateUrl: "./create-technical-checkin-form.component.html",
    styleUrls: ["../../../shared/components/sidebar/action-sidebar.scss", "./create-technical-checkin-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTechnicalCheckinFormComponent implements AfterViewInit {
    @Input({ required: true }) public set initialFormData(value: Partial<TechnicalCheckinData> | undefined) {
        if (!value) {
            return;
        }

        this.technicalCheckinDataForm.patchValue({
            ...value,
            duration: this.convertDurationToMinutes(value.duration) ?? DEFAULT_DURATION_MINUTES,
        });
        this.technicalCheckinFormStateController.save();
    }

    protected technicalCheckinDataForm = new FormGroup<InternalCreateTechnicalCheckinFormGroup>({
        latitude: new FormControl(DEFAULT_LATITUDE, { nonNullable: true, validators: Validators.required }),
        longitude: new FormControl(DEFAULT_LONGITUDE, { nonNullable: true, validators: Validators.required }),
        pilotId: new FormControl(DEFAULT_PILOT_ID, {
            nonNullable: true,
            validators: [Validators.required, Validators.maxLength(PILOT_NUMBER_MAX_LENGTH)],
        }),
        uavMass: new FormControl(DEFAULT_UAV_MASS, { nonNullable: true, validators: Validators.required }),
        flightHeight: new FormControl(DEFAULT_FLIGHT_HEIGHT, {
            nonNullable: true,
            validators: [Validators.required, Validators.min(1), Validators.max(MAX_FLIGHT_HEIGHT)],
        }),
        flightRadius: new FormControl(DEFAULT_FLIGHT_RADIUS, {
            nonNullable: true,
            validators: [Validators.required, Validators.min(1), Validators.max(MAX_FLIGHT_RADIUS)],
        }),
        category: new FormControl(DEFAULT_CATEGORY, { nonNullable: true, validators: Validators.required }),
        subCategory: new FormControl(DEFAULT_OPEN_SUB_CATEGORY, { nonNullable: true, validators: Validators.required }),
        duration: new FormControl(DEFAULT_DURATION_MINUTES, {
            nonNullable: true,
            validators: [Validators.required, Validators.min(1), Validators.max(MAX_DURATION)],
        }),
    });

    private readonly technicalCheckinFormStateController = new FormStateController(this.technicalCheckinDataForm);

    protected readonly MAX_FLIGHT_HEIGHT = MAX_FLIGHT_HEIGHT;
    protected readonly MAX_FLIGHT_RADIUS = MAX_FLIGHT_RADIUS;
    protected readonly UAV_MASS_CATEGORIES = UAV_MASS_CATEGORIES;
    protected readonly MISSION_CATEGORIES = MISSION_CATEGORIES;
    protected readonly OPEN_MISSION_SUB_CATEGORIES = OPEN_MISSION_SUB_CATEGORIES;
    protected readonly SPECIFIC_MISSION_SUB_CATEGORIES = SPECIFIC_MISSION_SUB_CATEGORIES;
    protected readonly MAX_DURATION = MAX_DURATION;
    protected readonly DURATION_STEP = DURATION_STEP;
    protected readonly HEIGHT_STEP = HEIGHT_STEP;
    protected readonly RADIUS_STEP = RADIUS_STEP;
    protected readonly MINUTES_IN_HOUR = MINUTES_IN_HOUR;
    protected readonly GeographicCoordinatesType = GeographicCoordinatesType;
    protected readonly GeographicCoordinatesDirection = GeographicCoordinatesDirection;
    protected readonly MissionFlightCategory = MissionFlightCategory;

    protected readonly hasFormChanged$ = this.technicalCheckinFormStateController.isEditing$;

    @Output() public readonly actionConfirm = new EventEmitter<TechnicalCheckinData>();
    @Output() public readonly actionCancel = new EventEmitter<void>();
    @Output() public readonly isFormChanged = this.hasFormChanged$;

    private convertDurationToMinutes(duration: ISO8601TimeDuration | undefined): number {
        if (!duration) {
            return DEFAULT_DURATION_MINUTES;
        }

        const seconds = DateUtils.convertISO8601DurationToSeconds(duration);
        if (seconds === null) {
            return DEFAULT_DURATION_MINUTES;
        }

        return Math.round(seconds / SECONDS_IN_MINUTE);
    }

    public ngAfterViewInit(): void {
        this.technicalCheckinFormStateController.save();
    }

    protected restoreInitialData(): void {
        this.technicalCheckinFormStateController.restore();
    }

    protected getISO8601TimeDuration(value: number) {
        return DateUtils.convertSecondsToISO8601Duration(value * SECONDS_IN_MINUTE);
    }

    protected tryConfirm(): void {
        this.technicalCheckinDataForm.markAllAsTouched();
        this.technicalCheckinDataForm.updateValueAndValidity();
        if (this.technicalCheckinDataForm.invalid) {
            return;
        }

        const formValue = this.technicalCheckinDataForm.getRawValue();

        this.actionConfirm.emit({
            ...formValue,
            duration: this.getISO8601TimeDuration(formValue.duration),
        });
    }

    protected setDefaultSubcategory(category: MissionFlightCategory): void {
        if (category === MissionFlightCategory.Open) {
            this.technicalCheckinDataForm.controls.subCategory.setValue(DEFAULT_OPEN_SUB_CATEGORY);
        } else {
            this.technicalCheckinDataForm.controls.subCategory.setValue(DEFAULT_SPECIFIC_SUB_CATEGORY);
        }
    }
}
