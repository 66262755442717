import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LocalComponentStore, MILLISECONDS_IN_MINUTE, MILLISECONDS_IN_SECOND, SECONDS_IN_MINUTE } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { interval, map, startWith, switchMap, timer } from "rxjs";

interface NavbarComponentState {
    time: Date;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-flights-navbar-clock",
    templateUrl: "./clock.component.html",
    styleUrls: ["./clock.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NavbarClockComponent {
    protected readonly time$ = this.localStore.selectByKey("time");

    constructor(private readonly localStore: LocalComponentStore<NavbarComponentState>) {
        this.localStore.setState({
            time: new Date(),
        });

        timer(this.timeUntilNextMinute())
            .pipe(
                switchMap(() => interval(MILLISECONDS_IN_MINUTE).pipe(startWith(new Date()))),
                map(() => new Date()),
                untilDestroyed(this)
            )
            .subscribe((time) =>
                this.localStore.patchState({
                    time,
                })
            );
    }

    private timeUntilNextMinute() {
        return (SECONDS_IN_MINUTE - new Date().getSeconds()) * MILLISECONDS_IN_SECOND;
    }
}
