import { inject, Injectable } from "@angular/core";
import { HttpResponse } from "@capacitor/core";
import { AuthState } from "@dtm-frontend/shared/auth";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, Store } from "@ngxs/store";
import { catchError, EMPTY, first, tap } from "rxjs";
import { Capabilities, DATSFeature, Grid, Jurisdiction } from "../models/capabilities.model";
import { CapabilitiesApiService } from "../services/capabilities-api.service";
import { CapabilitiesActions } from "./capabilities.actions";

export interface CapabilitiesStateModel {
    permissions: DATSFeature[];
    jurisdiction: Jurisdiction | undefined;
    grid: Grid | undefined;
    error: HttpResponse | undefined;
}

const defaultState: CapabilitiesStateModel = {
    permissions: [],
    jurisdiction: undefined,
    grid: undefined,
    error: undefined,
};

@State<CapabilitiesStateModel>({
    name: "capabilities",
    defaults: defaultState,
})
@UntilDestroy()
@Injectable()
export class CapabilitiesState implements NgxsOnInit {
    private readonly capabilitiesApiService = inject(CapabilitiesApiService);
    private readonly store = inject(Store);

    @Selector()
    public static permissions(state: CapabilitiesStateModel) {
        return state.permissions;
    }

    @Selector()
    public static jurisdiction(state: CapabilitiesStateModel) {
        return state.jurisdiction;
    }

    @Selector()
    public static grid(state: CapabilitiesStateModel) {
        return state.grid;
    }

    public ngxsOnInit() {
        this.store
            .select(AuthState.isLoggedIn)
            .pipe(RxjsUtils.filterFalsy(), first(), untilDestroyed(this))
            .subscribe(() => this.store.dispatch(new CapabilitiesActions.GetCapabilities()));
    }

    public static isFeatureAvailable(feature: DATSFeature) {
        return createSelector([CapabilitiesState], (state: CapabilitiesStateModel) => state.permissions.includes(feature));
    }

    @Action(CapabilitiesActions.GetCapabilities, { cancelUncompleted: true })
    public getCapabilities(context: StateContext<CapabilitiesStateModel>) {
        context.patchState({
            error: undefined,
        });

        return this.capabilitiesApiService.getCapabilities().pipe(
            tap(({ permissions, jurisdiction, grid }: Capabilities) => {
                context.patchState({
                    permissions,
                    jurisdiction,
                    grid,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            })
        );
    }
}
