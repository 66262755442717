import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ButtonTheme } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface ActionSidebarComponentState {
    isOpen: boolean;
    isProcessing: boolean;
}

@Component({
    selector: "dats-lib-action-sidebar",
    templateUrl: "./action-sidebar.component.html",
    styleUrls: ["./action-sidebar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ActionSidebarComponent {
    @Input() public set isOpen(value: BooleanInput) {
        this.localStore.patchState({ isOpen: coerceBooleanProperty(value) });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    protected readonly isOpen$ = this.localStore.selectByKey("isOpen");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");

    protected readonly ButtonTheme = ButtonTheme;

    constructor(private readonly localStore: LocalComponentStore<ActionSidebarComponentState>) {
        this.localStore.setState({
            isOpen: false,
            isProcessing: false,
        });
    }
}
