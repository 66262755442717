import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { Observable, combineLatest, distinctUntilChanged, map, merge, startWith, switchMap } from "rxjs";
import { AlertGeometrySourceType, AlertProposalQueryParams, AlertTextTemplate, AlertType, Zone } from "../../../models/alert.models";
import { AlertFormComponent, AlertFormGroup } from "../alert-form/alert-form.component";

interface ZoneAlertFormComponentState {
    templates: AlertTextTemplate[];
    alertFormComponent: AlertFormComponent | undefined;
    zoneAutocompletionList: Zone[];
    isZoneAutocompletionProcessing: boolean;
    selectedAlertType: AlertType;
}

export type ZoneAlertFormGroupValue = {
    source: AlertGeometrySourceType.Zone;
    zone: Zone;
} & Required<FormGroup<AlertFormGroup>["value"]>;

@UntilDestroy()
@Component({
    selector: "dats-lib-zone-alert-form",
    templateUrl: "./zone-alert-form.component.html",
    styleUrls: ["./zone-alert-form.component.scss", "../../../../shared/components/sidebar/action-sidebar.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ZoneAlertFormComponent {
    @Input({ required: true }) public set templates(value: AlertTextTemplate[]) {
        this.localStore.patchState({ templates: value });
    }
    @Input() public set zoneAutocompletionList(value: Zone[] | undefined) {
        this.localStore.patchState({ zoneAutocompletionList: value ?? [] });
    }
    @Input() public set isZoneAutocompletionProcessing(value: boolean) {
        this.localStore.patchState({ isZoneAutocompletionProcessing: value });
    }
    @Input() public set selectedAlertType(value: AlertType | undefined) {
        this.localStore.patchState({ selectedAlertType: value });
    }
    @Input() public set selectedAlertZone(value: Zone | undefined) {
        this.zoneFormControl.setValue(value ?? null);
    }

    @ViewChild(AlertFormComponent, { static: false }) public set alertForm(value: AlertFormComponent | undefined) {
        this.localStore.patchState({ alertFormComponent: value });
    }

    private readonly alertFormComponent$ = this.localStore.selectByKey("alertFormComponent");
    private readonly alertFormChanges$ = this.alertFormComponent$.pipe(
        RxjsUtils.filterFalsy(),
        switchMap((form) => form.valueChange),
        startWith<FormGroup<AlertFormGroup>["value"]>({})
    );

    private readonly alertFormTypeChanges$ = this.alertFormComponent$.pipe(
        RxjsUtils.filterFalsy(),
        switchMap((form) => form.typeChange)
    );

    @Output() public readonly actionCancel = new EventEmitter<void>();
    @Output() public readonly actionConfirm = new EventEmitter<ZoneAlertFormGroupValue>();
    @Output() public readonly isFormChanged = this.alertFormChanges$.pipe(
        map((value) => !!value.message),
        distinctUntilChanged()
    );
    @Output() public readonly zoneSearchTextChange = new EventEmitter<string>();

    protected readonly zoneFormControl = new FormControl<Zone | null>(null, [Validators.required]);
    @Output() public readonly messageTemplatesQueryParamsChange: Observable<AlertProposalQueryParams> = combineLatest([
        this.zoneFormControl.valueChanges.pipe(
            map((value) => value?.designator),
            RxjsUtils.filterFalsy()
        ),
        this.alertFormChanges$.pipe(
            map((value) => value?.type),
            RxjsUtils.filterFalsy()
        ),
    ]).pipe(
        map(([designator, type]) => ({ designator, type })),
        distinctUntilChanged(equal)
    );

    protected readonly templates$ = this.localStore.selectByKey("templates");
    protected readonly zoneAutocompletionList$ = this.localStore.selectByKey("zoneAutocompletionList");
    protected readonly isZoneAutocompletionProcessing$ = this.localStore.selectByKey("isZoneAutocompletionProcessing");
    protected readonly selectedAlertType$ = merge(this.localStore.selectByKey("selectedAlertType"), this.alertFormTypeChanges$).pipe(
        map((value) => value ?? AlertType.NoFlyZone)
    );

    constructor(private readonly localStore: LocalComponentStore<ZoneAlertFormComponentState>) {
        this.localStore.setState({
            templates: [],
            alertFormComponent: undefined,
            zoneAutocompletionList: [],
            isZoneAutocompletionProcessing: false,
            selectedAlertType: AlertType.NoFlyZone,
        });
    }

    protected tryEmitConfirm(): void {
        const alertFormComponent = this.localStore.selectSnapshotByKey("alertFormComponent");

        if (alertFormComponent?.alertForm === undefined) {
            return;
        }

        alertFormComponent.alertForm.updateValueAndValidity();
        alertFormComponent.alertForm.markAllAsTouched();
        this.zoneFormControl.updateValueAndValidity();
        this.zoneFormControl.markAsTouched();

        if (alertFormComponent.alertForm.valid && this.zoneFormControl.valid && this.zoneFormControl.value !== null) {
            this.actionConfirm.emit({
                ...alertFormComponent.alertForm.getRawValue(),
                source: AlertGeometrySourceType.Zone,
                zone: this.zoneFormControl.value,
            });
        }
    }

    protected searchTextChange(value: string): void {
        this.zoneSearchTextChange.emit(value);
    }
}
