import { Injectable, inject } from "@angular/core";
import { ArrayUtils } from "@dtm-frontend/shared/utils";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, catchError, finalize, tap } from "rxjs";
import {
    AlertError,
    AlertGeometrySourceType,
    AlertPointOfInterest,
    AlertProposal,
    CategorizedAlertsByBadge,
    PoiType,
} from "../models/alert.models";
import { AlertApiService } from "../services/alert-api.service";
import { AlertActions } from "./alert.actions";

export interface AlertStateModel {
    error: AlertError | undefined;
    isActionProcessing: boolean;
    selectedAlertGeometrySourceType: AlertGeometrySourceType | undefined;
    alerts: CategorizedAlertsByBadge | undefined;
    alertProposal: AlertProposal | undefined;
    hospitalList: AlertPointOfInterest[] | undefined;
    zoneList: AlertPointOfInterest[] | undefined;
    hemsBaseList: AlertPointOfInterest[] | undefined;
}

const defaultState: AlertStateModel = {
    error: undefined,
    isActionProcessing: false,
    selectedAlertGeometrySourceType: undefined,
    alerts: undefined,
    alertProposal: undefined,
    hospitalList: undefined,
    zoneList: undefined,
    hemsBaseList: undefined,
};

@State<AlertStateModel>({
    name: "alert",
    defaults: defaultState,
})
@Injectable()
export class AlertState {
    private alertApi = inject(AlertApiService);

    @Selector()
    public static error(state: AlertStateModel): AlertError | undefined {
        return state.error;
    }

    @Selector()
    public static isActionProcessing(state: AlertStateModel): boolean {
        return state.isActionProcessing;
    }

    @Selector()
    public static selectedAlertGeometrySourceType(state: AlertStateModel): AlertGeometrySourceType | undefined {
        return state.selectedAlertGeometrySourceType;
    }

    @Selector()
    public static alerts(state: AlertStateModel) {
        return state.alerts;
    }

    @Selector()
    public static alertProposals(state: AlertStateModel): AlertProposal | undefined {
        return state.alertProposal;
    }

    @Selector()
    public static hospitalList(state: AlertStateModel): AlertPointOfInterest[] | undefined {
        return state.hospitalList;
    }

    @Selector()
    public static hemsBaseList(state: AlertStateModel): AlertPointOfInterest[] | undefined {
        return state.hemsBaseList;
    }

    @Selector()
    public static zoneList(state: AlertStateModel): AlertPointOfInterest[] | undefined {
        return state.zoneList;
    }

    @Action(AlertActions.SelectAlertGeometrySourceType)
    public selectAlertGeometrySourceType(context: StateContext<AlertStateModel>, action: AlertActions.SelectAlertGeometrySourceType) {
        context.patchState({ selectedAlertGeometrySourceType: action.type });
    }

    @Action(AlertActions.GetAlerts)
    public getAlerts(context: StateContext<AlertStateModel>) {
        context.patchState({ error: undefined, alertProposal: undefined, isActionProcessing: true });

        return this.alertApi.getAlerts().pipe(
            tap((alerts) => {
                context.patchState({ alerts });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAlertProposals)
    public getAlertProposals(context: StateContext<AlertStateModel>, action: AlertActions.GetAlertProposals) {
        context.patchState({ error: undefined, alertProposal: undefined, isActionProcessing: true });

        return this.alertApi.getAlertProposals(action.type, action.poiId, action.designator).pipe(
            tap((alertProposal) => {
                context.patchState({ alertProposal });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.CreateAlert)
    public createAlert(context: StateContext<AlertStateModel>, action: AlertActions.CreateAlert) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.createAlert(action.alertEntity).pipe(
            tap(() => context.dispatch(new AlertActions.GetAlerts())),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAllHospitals)
    public getAllHospitals(context: StateContext<AlertStateModel>) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.getPoiList(PoiType.Hospital).pipe(
            tap(({ content: hospitalList }) => {
                context.patchState({
                    hospitalList,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAllZones)
    public getAllZones(context: StateContext<AlertStateModel>) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.getPoiList(PoiType.Airspace).pipe(
            tap(({ content: zoneList }) => {
                context.patchState({
                    zoneList,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAllHemsBases)
    public getAllHemsBases(context: StateContext<AlertStateModel>) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.getPoiList(PoiType.Hems).pipe(
            tap(({ content: hemsBaseList }) => {
                context.patchState({
                    hemsBaseList,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAllPois)
    public getAllPois(context: StateContext<AlertStateModel>) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.getPoiList().pipe(
            tap(({ content }) => {
                const [zoneList, poisWithoutZones] = ArrayUtils.partition(content, (poi) => poi.type === PoiType.Airspace);
                const [hospitalList, poisWithoutHospitals] = ArrayUtils.partition(poisWithoutZones, (poi) => poi.type === PoiType.Hospital);
                const hemsBaseList = poisWithoutHospitals.filter((poi) => poi.type === PoiType.Hems);

                context.patchState({
                    zoneList,
                    hospitalList,
                    hemsBaseList,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.SetBookmarkedHospitals)
    public setBookmarkedHospitals(context: StateContext<AlertStateModel>, action: AlertActions.SetBookmarkedHospitals) {
        const bookmarkedHospitals = context.getState().hospitalList;

        if (!bookmarkedHospitals) {
            return;
        }

        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.setBookmarkedHospitals(bookmarkedHospitals, action.newBookmarkedHospitals).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }
}
