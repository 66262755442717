import { PageResponseBody, UavType } from "@dtm-frontend/shared/ui";
import {
    Airspace,
    AirspaceType,
    FlightAcceptance,
    FlightAcceptancePhase,
    FlightAcceptanceType,
    FlightCategory,
    FlightItem,
    FlightMission,
    FlightModification,
    FlightOperation,
    FlightOperationType,
    FlightProgress,
    FlightProgressPhase,
    Geography,
    JurisdictionMission,
    MissionType,
    ModifyCheckinFormValues,
    Operator,
    Pilot,
    Uav,
} from "../models/flight.models";
import { OperationTypeFilter } from "../models/flights-filters.models";

export interface GetFlightsResponseBody {
    items: FlightItemResponseBody[];
}

export interface ModifyCheckinRequestPayload {
    plannedStartAt?: string;
    plannedEndAt?: string;
    maxHeight?: number;
}

export interface ArchiveCheckinRequestPayload {
    reason: {
        id: string;
        args: { [key: string]: string };
    };
}

export interface FlightItemResponseBody {
    id: string;
    createdAt: string;
    emergencyAt: string;
    estimatedEndAt: string;
    completedAt: string;
    acceptance: FlightAcceptanceResponseBody;
    progress: FlightProgressResponseBody;
    modifications: FlightModificationResponseBody[];
    operation: FlightOperationResponseBody;
    unacknowledged: boolean;
    archived: boolean;
}

export interface PageableRequestPayload {
    size: number;
    page: number;
    sort: string;
}

export interface PageableResponseBody<T> extends Omit<PageResponseBody<T>, "hasContent"> {
    sort: SortResponseBody[];
    empty: boolean;
}

export interface SortResponseBody {
    direction: string;
    nullHandling: string;
    ascending: boolean;
    property: string;
    ignoreCase: boolean;
}

export interface JurisdictionMissionResponseBody {
    missionId: string;
    operatorName: string;
    type: MissionType;
    startDateTime: string;
    endDateTime: string;
    maxHeight: number;
}

interface FlightAcceptanceResponseBody {
    /**
     * @deprecated The property is deprecated, use unacknowledged from FlightItemResponseBody
     */
    acknowledged?: boolean;
    phase: FlightAcceptancePhase;
    type: FlightAcceptanceType;
    system: boolean;
}

interface FlightProgressResponseBody {
    /**
     * @deprecated The property is deprecated, use unacknowledged from FlightItemResponseBody
     */
    acknowledged?: boolean;
    phase: FlightProgressPhase;
}

interface FlightModificationResponseBody {
    createdAt: string;
    /**
     * @deprecated The property is deprecated, use unacknowledged from FlightItemResponseBody
     */
    acknowledged?: boolean;
    plannedStartAt: string;
    plannedEndAt: string;
    maxHeight: number;
}

interface FlightOperationResponseBody {
    plannedStartAt: string;
    plannedEndAt: string;
    bvlos: boolean;
    mission?: FlightMissionResponseBody;
    pilot: Pilot;
    operator: Operator;
    uav: FlightUavResponseBody;
    airspace: Airspace;
    geography: Geography;
    type: FlightOperationType;
}

interface FlightMissionResponseBody {
    id: string;
    startAt: string;
    endAt: string;
    maxHeight: number;
}

interface FlightUavResponseBody {
    model: string;
    type: UavType;
    swarm: boolean;
    takeOffMass: number;
    takeOffMassLimit: number;
}

function convertFlightModificationsResponseBodyToFlightModification(
    response: FlightModificationResponseBody[]
): FlightModification | undefined {
    if (response.length === 0) {
        return undefined;
    }

    const initial = {
        maxHeight: response[0].maxHeight,
        createdAt: new Date(response[0].createdAt),
        plannedStartAt: new Date(response[0].plannedStartAt),
        plannedEndAt: new Date(response[0].plannedEndAt),
    };

    const modified = response.reduce(
        (acc, mod) => ({
            maxHeight: mod.maxHeight ?? acc.maxHeight,
            createdAt: mod.createdAt ? new Date(mod.createdAt) : acc.createdAt,
            plannedStartAt: mod.plannedStartAt ? new Date(mod.plannedStartAt) : acc.plannedStartAt,
            plannedEndAt: mod.plannedEndAt ? new Date(mod.plannedEndAt) : acc.plannedEndAt,
        }),
        initial
    );

    return {
        initial,
        modified,
        isTimeModified:
            modified.plannedStartAt.getTime() !== initial.plannedStartAt.getTime() ||
            modified.plannedEndAt.getTime() !== initial.plannedEndAt.getTime(),
        isHeightModified: modified.maxHeight !== initial.maxHeight,
    };
}

export function prepareCheckinModificationPayload(formValues: ModifyCheckinFormValues): ModifyCheckinRequestPayload {
    return {
        plannedStartAt: formValues.startAt?.toISOString(),
        plannedEndAt: formValues.finishAt?.toISOString(),
        maxHeight: formValues?.customHeight || undefined,
    };
}

function convertFlightAcceptanceResponseBodyToFlightAcceptance(response: FlightAcceptanceResponseBody): FlightAcceptance {
    return {
        phase: response.phase,
        type: response.type,
        isSystem: response.system,
    };
}

function convertFlightProgressResponseBodyToFlightProgress(response: FlightProgressResponseBody): FlightProgress {
    return {
        phase: response.phase,
    };
}

function convertFlightMissionResponseBodyToFlightMission(response: FlightMissionResponseBody): FlightMission {
    return {
        ...response,
        startAt: new Date(response.startAt),
        endAt: new Date(response.endAt),
    };
}

function convertFlightUavResponseBodyToUav(response: FlightUavResponseBody): Uav {
    return {
        model: response.model,
        type: response.type,
        isSwarm: response.swarm,
        takeOffMass: response.takeOffMass ?? undefined,
        takeOffMassLimit: response.takeOffMassLimit,
    };
}

function convertFlightOperationResponseBodyToFlightOperation(response: FlightOperationResponseBody): FlightOperation {
    return {
        plannedStartAt: new Date(response.plannedStartAt),
        plannedEndAt: new Date(response.plannedEndAt),
        isBvlos: response.bvlos,
        mission: response.mission ? convertFlightMissionResponseBodyToFlightMission(response.mission) : undefined,
        pilot: response.pilot,
        operator: response.operator,
        uav: convertFlightUavResponseBodyToUav(response.uav),
        airspace: response.airspace,
        geography: response.geography,
        type: response.type,
    };
}

function isFlight112(type: FlightOperationType): boolean {
    if (!type) {
        return false;
    }

    return type === FlightOperationType.Emergency112 || type === FlightOperationType.Ghost112;
}

function getAttributes(response: FlightItemResponseBody) {
    const attributes: (AirspaceType | OperationTypeFilter)[] = [];
    if (isFlight112(response.operation.type)) {
        attributes.push(OperationTypeFilter.Type112);
    }
    if (response.operation.airspace.elements.some((element) => element.type === AirspaceType.CTR)) {
        attributes.push(AirspaceType.CTR);
    }
    if (response.operation.airspace.elements.some((element) => element.type === AirspaceType.MCTR)) {
        attributes.push(AirspaceType.MCTR);
    }

    return attributes;
}

function getPendingCategory(operation: FlightOperationResponseBody): FlightCategory {
    if (operation.type !== FlightOperationType.Standard) {
        return FlightCategory.Pending_112;
    }

    return FlightCategory.Pending;
}

function getAcceptedCategory(acceptance: FlightAcceptanceResponseBody): FlightCategory {
    if (acceptance.system) {
        return FlightCategory.AcceptedSystem;
    }
    switch (acceptance.type) {
        case FlightAcceptanceType.ATC:
        case FlightAcceptanceType.DTM:
            return FlightCategory.AcceptedManually;
        default:
            return FlightCategory.AcceptedOther;
    }
}

function getCompletedCategory(acceptance: FlightAcceptanceResponseBody): FlightCategory {
    if (acceptance.system) {
        return FlightCategory.CompletedSystem;
    }
    switch (acceptance.type) {
        case FlightAcceptanceType.ATC:
        case FlightAcceptanceType.DTM:
            return FlightCategory.Completed;
        default:
            return FlightCategory.CompletedOther;
    }
}

function getOverdueCategory(acceptance: FlightAcceptanceResponseBody): FlightCategory {
    if (acceptance.system) {
        return FlightCategory.OverdueSystem;
    }
    switch (acceptance.type) {
        case FlightAcceptanceType.ATC:
        case FlightAcceptanceType.DTM:
            return FlightCategory.Overdue;
        default:
            return FlightCategory.OverdueOther;
    }
}

function getProgressUncompletedCategory(acceptance: FlightAcceptanceResponseBody, operation: FlightOperationResponseBody): FlightCategory {
    switch (acceptance.phase) {
        case FlightAcceptancePhase.Accepted:
            return getAcceptedCategory(acceptance);
        case FlightAcceptancePhase.Pending:
            return getPendingCategory(operation);
        case FlightAcceptancePhase.Rejected:
            return FlightCategory.Rejected;
        case FlightAcceptancePhase.Canceled:
            return FlightCategory.Canceled;
        case FlightAcceptancePhase.Standby:
            return FlightCategory.Standby;
        default:
            return FlightCategory.Other;
    }
}

export function getFlightCategory({ acceptance, progress, operation }: FlightItemResponseBody): FlightCategory {
    switch (progress.phase) {
        case FlightProgressPhase.Uncompleted:
            return getProgressUncompletedCategory(acceptance, operation);
        case FlightProgressPhase.Completed:
            return getCompletedCategory(acceptance);
        case FlightProgressPhase.Emergency:
            return FlightCategory.Emergency;
        case FlightProgressPhase.Overdue:
            return getOverdueCategory(acceptance);
        case FlightProgressPhase.Stop:
            return FlightCategory.Stop;
        default:
            return FlightCategory.Other;
    }
}

function convertFlightItemResponseBodyToFlightItem(response: FlightItemResponseBody): FlightItem {
    return {
        id: response.id,
        createdAt: new Date(response.createdAt),
        emergencyAt: response.emergencyAt ? new Date(response.emergencyAt) : undefined,
        estimatedEndAt: response.estimatedEndAt ? new Date(response.estimatedEndAt) : undefined,
        completedAt: response.completedAt ? new Date(response.completedAt) : undefined,
        acceptance: convertFlightAcceptanceResponseBodyToFlightAcceptance(response.acceptance),
        progress: convertFlightProgressResponseBodyToFlightProgress(response.progress),
        modification: convertFlightModificationsResponseBodyToFlightModification(response.modifications),
        operation: convertFlightOperationResponseBodyToFlightOperation(response.operation),
        category: getFlightCategory(response),
        isUnacknowledged: response.unacknowledged,
        isArchived: response.archived,
        attributes: getAttributes(response),
    };
}

export function convertGetFlightItemsResponseBodyToFlightItems(response: FlightItemResponseBody[]): FlightItem[] {
    return response.map((flight) => convertFlightItemResponseBodyToFlightItem(flight));
}

export function convertJurisdictionMissionResponseBodyToJurisdictionMission(
    response: JurisdictionMissionResponseBody
): JurisdictionMission {
    return {
        missionId: response.missionId,
        startDateTime: new Date(response.startDateTime),
        endDateTime: new Date(response.endDateTime),
        maxHeight: response.maxHeight,
        operatorName: response.operatorName,
        type: response.type,
    };
}
