import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, METERS_IN_FOOT } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { LetDirective } from "@ngrx/component";
import { combineLatest, map } from "rxjs";

interface HeightLabelComponentState {
    isIconDisplayed: boolean;
    height: number | undefined;
    elevation: number | undefined;
    isMaxHeight: boolean;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-height-label",
    standalone: true,
    imports: [CommonModule, LetDirective, SharedI18nModule, SharedUiModule, MatTooltipModule],
    templateUrl: "./height-label.component.html",
    styleUrls: ["./height-label.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HeightLabelComponent {
    @Input() public set height(value: number | undefined) {
        this.localStore.patchState({ height: value });
    }
    @Input() public set elevation(value: number | undefined) {
        this.localStore.patchState({ elevation: value });
    }
    @Input() public set isIconDisplayed(value: BooleanInput) {
        this.localStore.patchState({ isIconDisplayed: coerceBooleanProperty(value) });
    }
    @Input() public set isMaxHeight(value: BooleanInput) {
        this.localStore.patchState({ isMaxHeight: coerceBooleanProperty(value) });
    }

    private readonly elevation$ = this.localStore.selectByKey("elevation");
    protected readonly isIconDisplayed$ = this.localStore.selectByKey("isIconDisplayed");
    protected readonly height$ = this.localStore.selectByKey("height");
    protected readonly tooltipValue$ = combineLatest([this.height$, this.elevation$]).pipe(
        map(([height, elevation]) => {
            if (height === undefined || elevation === undefined) {
                return;
            }

            return Math.ceil((height + elevation) / METERS_IN_FOOT);
        })
    );
    protected readonly isMaxHeight$ = this.localStore.selectByKey("isMaxHeight");

    constructor(private readonly localStore: LocalComponentStore<HeightLabelComponentState>) {
        this.localStore.setState({ isIconDisplayed: true, height: undefined, elevation: undefined, isMaxHeight: false });
    }
}
