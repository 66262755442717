<ng-container
    *ngrxLet="{ isIconDisplayed: isIconDisplayed$, height: height$, isMaxHeight: isMaxHeight$, tooltipValue: tooltipValue$ } as vm"
>
    <dtm-ui-icon name="complex-top-height" *ngIf="vm.isIconDisplayed"></dtm-ui-icon>
    <span
        [matTooltip]="
            (vm.tooltipValue
                ? 'datsLibFlights.timeHeightDisplay.heightFeetAmslLabel'
                : 'datsLibFlights.timeHeightDisplay.heightFeetAmslLabelError'
            ) | transloco : { value: vm.tooltipValue | localizeNumber }
        "
    >
        {{
            (vm.isMaxHeight ? "datsLibFlights.timeHeightDisplay.maxHeightLabel" : "datsLibFlights.timeHeightDisplay.heightLabel")
                | transloco : { value: vm.height | localizeNumber }
        }}
    </span>
</ng-container>
