<ng-container *ngrxLet="{ alerts: alerts$, activeAlertBadge: activeAlertBadge$ } as vm">
    <div class="badge" *ngFor="let alertBadge of alertBadges">
        <button
            type="button"
            class="button-alert icon"
            [ngClass]="alertBadge.class"
            [class.button-alert-active]="alertBadge.type === vm.activeAlertBadge"
            *ngIf="vm.alerts?.[alertBadge.type]?.length"
            (click)="setActiveAlertBadge(alertBadge.type)"
            [matTooltip]="alertBadge.tooltipKey | transloco"
            matTooltipPosition="left"
        >
            {{ vm.alerts?.[alertBadge.type]?.length }}
            <dtm-ui-icon slot="start" [name]="alertBadge.icon"></dtm-ui-icon>
        </button>
        <dats-lib-alerts-list
            *ngIf="alertBadge.type === vm.activeAlertBadge"
            class="menu"
            [alertsBadgeType]="alertBadge.type"
            [alerts]="vm.alerts?.[alertBadge.type]"
            @slideInOut
        ></dats-lib-alerts-list>
    </div>
</ng-container>
