import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Alert, ALERT_BADGES, AlertBadgeType } from "../../models/alert.models";

interface AlertsListComponentState {
    alerts: Alert[] | undefined;
    alertsBadgeType: AlertBadgeType;
}

@Component({
    selector: "dats-lib-alerts-list",
    templateUrl: "./alerts-list.component.html",
    styleUrls: ["../alert-badges.scss", "./alerts-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AlertsListComponent {
    @Input() public set alerts(value: Alert[] | undefined) {
        this.localStore.patchState({ alerts: value ?? [] });
    }
    @Input() public set alertsBadgeType(value: AlertBadgeType) {
        this.localStore.patchState({ alertsBadgeType: value });
    }

    private readonly localStore = inject(LocalComponentStore<AlertsListComponentState>);
    protected readonly AlertBadgeType = AlertBadgeType;
    protected readonly alertBadges = ALERT_BADGES;
    protected readonly alertBadgesByType: Record<AlertBadgeType, { class: string; icon: string }> = ALERT_BADGES.reduce(
        (result: Record<string, { class: string; icon: string }>, badge) => {
            result[badge.type] = badge;

            return result;
        },
        {}
    );
    protected readonly alerts$ = this.localStore.selectByKey("alerts");
    protected readonly alertsBadgeType$ = this.localStore.selectByKey("alertsBadgeType");

    constructor() {
        this.localStore.setState({ alerts: undefined, alertsBadgeType: AlertBadgeType.NotStarted });
    }
}
