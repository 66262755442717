<dats-lib-action-sidebar-header
    [headerText]="'datsLibFlights.flightsContainer.hospitalsSidebarTitle' | transloco"
    (actionCancel)="actionCancel.emit()"
>
</dats-lib-action-sidebar-header>

<div class="action-sidebar-content scroll-shadows">
    <div class="list" *ngrxLet="hospitalList$ as hospitalList">
        <div
            class="hospital-chip"
            [ngClass]="{ bookmarked: hospital.isBookmarked }"
            *ngFor="let hospital of hospitalList"
            (click)="markHospital(hospital)"
        >
            <span class="name">{{ hospital.title }}</span>
            <dtm-ui-icon [name]="hospital.isBookmarked ? 'star-fill' : 'star'"></dtm-ui-icon>
        </div>
    </div>
</div>

<dats-lib-action-sidebar-footer (actionCancel)="actionCancel.emit()" (actionConfirm)="confirm()"> </dats-lib-action-sidebar-footer>
