import { Directive, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { LEAFLET_MAP_PROVIDER, LeafletMapProvider } from "@dtm-frontend/shared/map/leaflet";
import { ArrayUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { DomEvent, FeatureGroup, Icon, LatLngBounds, Layer, Map as LeafletMap, Marker } from "leaflet";
import { combineLatest, throttleTime } from "rxjs";
import { FlightCategory, FlightItem, FlightList } from "../../models/flight.models";

const ICON_SIZE_DEFAULT = 44;
const ICON_SIZE_HIGHLIGHTED = 60;
const ICON_SIZE_SELECTED = 56;
const ICON_ANCHOR_MODIFIER = 0.5;
// NOTE: each flight have different z-index to be safe it must be greater than amount of flights on the map
const Z_INDEX_OFFSET = 100000;

const LAYER_REFRESH_THROTTLE_TIME = 300;
const FLY_TO_ANIMATION_DURATION = 0.3;
const DEFAULT_ZOOM_LEVEL = 12;
const SUBDUED_ICON_OPACITY = 0.35;

interface FlightsLayerDirectiveState {
    flightList: FlightList | undefined;
    selectedFlightId: string | undefined;
    previouslySelectedFlightId: string | undefined;
    heightFilter: number | undefined;
    previousHeightFilter: number | undefined;
}

enum FlightIconState {
    Default = "default",
    Highlighted = "highlighted",
    Selected = "selected",
}

interface FlightItemMarker {
    item: FlightItem;
    iconState: FlightIconState;
}

const MarkerOptions = {
    [FlightIconState.Default]: {
        iconSize: ICON_SIZE_DEFAULT,
        zIndexOffset: Z_INDEX_OFFSET,
        className: "",
    },
    [FlightIconState.Highlighted]: {
        iconSize: ICON_SIZE_HIGHLIGHTED,
        zIndexOffset: 100 * Z_INDEX_OFFSET,
        className: "flight-marker-highlighted",
    },
    [FlightIconState.Selected]: {
        iconSize: ICON_SIZE_SELECTED,
        zIndexOffset: 1000 * Z_INDEX_OFFSET,
        className: "flight-marker-highlighted-selected",
    },
} as const;

const FlightCategoryIcons: Record<FlightCategory, { icon: string; isAnimated: boolean }> = {
    [FlightCategory.Pending_112]: { icon: "Pending112.svg", isAnimated: true },
    [FlightCategory.Emergency]: { icon: "Emergency.svg", isAnimated: true },
    [FlightCategory.Stop]: { icon: "Land.svg", isAnimated: false },
    [FlightCategory.Pending]: { icon: "Pending.svg", isAnimated: true },
    [FlightCategory.AcceptedManually]: { icon: "AcceptedManually.svg", isAnimated: false },
    [FlightCategory.AcceptedSystem]: { icon: "AcceptedSystem.svg", isAnimated: false },
    [FlightCategory.AcceptedOther]: { icon: "Other.svg", isAnimated: false },
    [FlightCategory.Completed]: { icon: "AcceptedManually.svg", isAnimated: false },
    [FlightCategory.CompletedSystem]: { icon: "AcceptedSystem.svg", isAnimated: false },
    [FlightCategory.CompletedOther]: { icon: "Other.svg", isAnimated: false },
    [FlightCategory.Rejected]: { icon: "Other.svg", isAnimated: false },
    [FlightCategory.Overdue]: { icon: "Overdue.svg", isAnimated: false },
    [FlightCategory.OverdueSystem]: { icon: "Overdue.svg", isAnimated: false },
    [FlightCategory.OverdueOther]: { icon: "Overdue.svg", isAnimated: false },
    [FlightCategory.Standby]: { icon: "Standby.svg", isAnimated: true },
    [FlightCategory.Canceled]: { icon: "Other.svg", isAnimated: false },
    [FlightCategory.Other]: { icon: "Other.svg", isAnimated: false },
    [FlightCategory.Mission]: { icon: "Other.svg", isAnimated: false },
};

const FlightCategoryNotAckIcons: Record<FlightCategory, { icon: string; isAnimated: boolean }> = {
    ...FlightCategoryIcons,
    [FlightCategory.Stop]: { icon: "Land_NotAck.svg", isAnimated: true },
    [FlightCategory.AcceptedManually]: { icon: "Modified_NotAck.svg", isAnimated: true },
    [FlightCategory.AcceptedSystem]: { icon: "Modified_NotAck.svg", isAnimated: true },
    [FlightCategory.AcceptedOther]: { icon: "Modified_NotAck.svg", isAnimated: true },
};

@UntilDestroy()
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "dats-lib-flights-layer",
    providers: [LocalComponentStore],
})
export class FlightsLayerDirective implements OnInit, OnDestroy {
    @Input({ required: true }) public set flightList(value: FlightList | undefined) {
        this.localStore.patchState({ flightList: value });
    }

    @Input() public set selectedFlightId(value: string | undefined) {
        this.localStore.patchState((state) => ({
            previouslySelectedFlightId: state.selectedFlightId,
            selectedFlightId: value,
        }));
    }

    @Input() public set zoom(value: { bounds: LatLngBounds } | undefined) {
        if (value) {
            this.zoomToBounds(value.bounds);
        }
    }

    @Input() public set heightFilter(value: number | undefined) {
        this.localStore.patchState({ heightFilter: value });
    }

    @Output() public readonly flightClick = new EventEmitter<string>();

    private readonly flightList$ = this.localStore.selectByKey("flightList");

    private readonly flightIconsLayer = new FeatureGroup();
    private readonly flightsMap = new Map<string, { flight: FlightItemMarker; layer: Layer }>();

    constructor(
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider,
        private readonly localStore: LocalComponentStore<FlightsLayerDirectiveState>
    ) {
        this.localStore.setState({
            flightList: undefined,
            selectedFlightId: undefined,
            previouslySelectedFlightId: undefined,
            heightFilter: undefined,
            previousHeightFilter: undefined,
        });
    }

    public async ngOnInit() {
        const map: LeafletMap = await this.mapProvider.getMap();
        map.addLayer(this.flightIconsLayer);

        combineLatest([this.flightList$, this.localStore.selectByKey("selectedFlightId"), this.localStore.selectByKey("heightFilter")])
            .pipe(throttleTime(LAYER_REFRESH_THROTTLE_TIME, undefined, { leading: true, trailing: true }), untilDestroyed(this))
            .subscribe(([flightList, selectedFlightId, heightFilter]) => {
                this.updateMarkers(
                    flightList,
                    selectedFlightId,
                    this.localStore.selectSnapshotByKey("previouslySelectedFlightId"),
                    heightFilter,
                    this.localStore.selectSnapshotByKey("previousHeightFilter")
                );
                this.localStore.patchState({ previousHeightFilter: heightFilter });
            });
    }

    public async ngOnDestroy() {
        // NOTE: when map will be removed by other source we can skip cleanup
        try {
            const map: LeafletMap = await this.mapProvider.getMap();

            map.removeLayer(this.flightIconsLayer);
        } catch (error) {}
    }

    public async zoomToBounds(bounds: LatLngBounds) {
        const map: LeafletMap = await this.mapProvider.getMap();
        map.fitBounds(bounds);
    }

    public async zoomToFlightItem(flightId: string, leftPaddingPixels: number = 0, topPaddingPixels: number = 0) {
        const flightMapItem = this.flightsMap.get(flightId);

        if (!flightMapItem) {
            return;
        }

        const map: LeafletMap = await this.mapProvider.getMap();

        const { latitude, longitude } = flightMapItem.flight.item.operation.geography;
        const point = map.project([latitude, longitude], DEFAULT_ZOOM_LEVEL);
        const shiftedPoint = point.add([-leftPaddingPixels / 2, -topPaddingPixels / 2]);
        const shiftedLatLng = map.unproject(shiftedPoint, DEFAULT_ZOOM_LEVEL);

        map.flyTo(shiftedLatLng, DEFAULT_ZOOM_LEVEL, {
            animate: true,
            duration: FLY_TO_ANIMATION_DURATION,
        });
    }

    private async updateMarkers(
        flightList: FlightList | undefined,
        selectedFlightId: string | undefined,
        previouslySelectedFlightId: string | undefined,
        heightFilter: number | undefined,
        previousHeightFilter: number | undefined
    ) {
        const newFlightsMap = new Map<string, FlightItemMarker>([
            ...(flightList?.filteredOutFlights ?? []).map(
                (flightItem) =>
                    [
                        flightItem.id,
                        {
                            item: flightItem,
                            iconState: this.getFlightIconState(
                                flightItem.id,
                                selectedFlightId,
                                true,
                                !!flightList?.filteredOutFlights.length
                            ),
                        },
                    ] as [string, FlightItemMarker]
            ),
            ...(flightList?.visibleFlights ?? []).map(
                (flightItem) =>
                    [
                        flightItem.id,
                        {
                            item: flightItem,
                            iconState: this.getFlightIconState(
                                flightItem.id,
                                selectedFlightId,
                                false,
                                !!flightList?.filteredOutFlights.length
                            ),
                        },
                    ] as [string, FlightItemMarker]
            ),
        ]);
        const [flightsToUpdateOrSkip, flightsToAdd] = ArrayUtils.partition([...newFlightsMap.keys()], (id) => this.flightsMap.has(id));
        const [flightsToRemove] = ArrayUtils.partition([...this.flightsMap.keys()], (id) => !newFlightsMap.has(id));
        const flightsToUpdate = flightsToUpdateOrSkip.filter(
            (id) =>
                id === selectedFlightId ||
                id === previouslySelectedFlightId ||
                !equal(this.flightsMap.get(id)?.flight.item, newFlightsMap.get(id)?.item) ||
                !equal(this.flightsMap.get(id)?.flight.iconState, newFlightsMap.get(id)?.iconState) ||
                this.isHeightFilterImpactingOpacity(this.flightsMap.get(id)?.flight, heightFilter, previousHeightFilter)
        );
        [...flightsToRemove, ...flightsToUpdate].forEach((id) => {
            const flight = this.flightsMap.get(id);

            if (flight) {
                this.flightIconsLayer.removeLayer(flight.layer);
            }

            this.flightsMap.delete(id);
        });
        [...flightsToAdd, ...flightsToUpdate].forEach((id) => {
            const flight = newFlightsMap.get(id);

            if (!flight) {
                return;
            }

            const flightMarker = this.createFlightMarker(flight, heightFilter);

            this.flightsMap.set(id, { flight, layer: flightMarker });
            this.flightIconsLayer.addLayer(flightMarker);
        });

        this.flightIconsLayer.bringToBack();
    }

    private isHeightFilterImpactingOpacity(
        flight: FlightItemMarker | undefined,
        heightFilter: number | undefined,
        previousHeightFilter: number | undefined
    ) {
        return (
            flight &&
            previousHeightFilter !== heightFilter &&
            (previousHeightFilter === undefined ||
                heightFilter === undefined ||
                flight.item.operation.geography.maxHeight < previousHeightFilter !==
                    flight.item.operation.geography.maxHeight < heightFilter)
        );
    }

    private getFlightIconOpacity(flight: FlightItemMarker, heightFilter: number = 0): number {
        return flight.item.operation.geography.maxHeight < heightFilter && flight.iconState !== FlightIconState.Selected
            ? SUBDUED_ICON_OPACITY
            : 1;
    }

    private getFlightIconState(
        flightId: string,
        selectedFlightId: string | undefined,
        isFilteredOut: boolean,
        isAnyFlightFilteredOut: boolean
    ) {
        if (flightId === selectedFlightId) {
            return FlightIconState.Selected;
        }
        if (isAnyFlightFilteredOut && !isFilteredOut) {
            return FlightIconState.Highlighted;
        }

        return FlightIconState.Default;
    }

    private createFlightMarker(flight: FlightItemMarker, heightFilter: number | undefined): Layer {
        const icon = this.getIcon(flight.item);
        const iconUrl = `assets/images/${icon.icon}`;
        const opacity = this.getFlightIconOpacity(flight, heightFilter);

        let flightMarkerLayer: Layer;

        // if (isSelectedFlight || icon.isAnimated) {
        // TODO: DTATS-210 add support for animated canvas icons, remove one comment line below
        // eslint-disable-next-line prefer-const
        flightMarkerLayer = new Marker([flight.item.operation.geography.latitude, flight.item.operation.geography.longitude], {
            icon: new Icon({
                iconUrl,
                iconSize: [MarkerOptions[flight.iconState].iconSize, MarkerOptions[flight.iconState].iconSize],
                iconAnchor: [
                    MarkerOptions[flight.iconState].iconSize * ICON_ANCHOR_MODIFIER,
                    MarkerOptions[flight.iconState].iconSize * ICON_ANCHOR_MODIFIER,
                ],
                className: MarkerOptions[flight.iconState].className,
            }),
            zIndexOffset: opacity === SUBDUED_ICON_OPACITY ? 0 : MarkerOptions[flight.iconState].zIndexOffset,
            opacity: opacity,
        });
        // TODO: DTATS-210
        // } else {
        // flightMarkerLayer = createCanvasIconMarker(
        //     [flight.item.operation.geography.latitude, flight.item.operation.geography.longitude],
        //     {
        //         icon: {
        //             url: iconUrl,
        //             size: ICON_OPTIONS.iconSize as [number, number],
        //         },
        //         fillOpacity: this.getFlightIconOpacity(flight, selectedFlightId),
        //     }
        // );
        // }

        flightMarkerLayer.on("click", (event) => {
            this.flightClick.emit(flight.item.id);
            DomEvent.stopPropagation(event);
        });

        return flightMarkerLayer;
    }

    private getIcon(flightItem: FlightItem) {
        if (flightItem.modification && flightItem.isUnacknowledged) {
            return FlightCategoryNotAckIcons[flightItem.category];
        }

        return FlightCategoryIcons[flightItem.category];
    }
}
