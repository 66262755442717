import { createDynamicEnvironment } from "@dtm-frontend/shared/utils";
import type DynamicConfiguation from "../assets/dynamic.config.json";
import { DroneAirTrafficServicesEnvironment } from "./environment.model";

export const dynamicEnvironment = createDynamicEnvironment<DroneAirTrafficServicesEnvironment, typeof DynamicConfiguation>(
    fetch("../assets/dynamic.config.json").then((config) => config.json()),
    ({ apiDomain, keycloakConfig }) => {
        const apiUrl = `https://${apiDomain}/api/dats`;

        return {
            production: false,
            name: "dev",
            keycloakConfig,
            azureMapsSubscriptionKey: "HS9JYfhTrM0Ud2smxePEEpkueCl81_SAD2T-VU-ZNFk",
            bearerExcludedUrls: [],
            leafletMapConfig: {
                defaultPosition: {
                    lat: 52.115,
                    lng: 19.424,
                },
                zoom: {
                    min: 6,
                    max: 18,
                    initial: 7,
                    userPosition: 17,
                },
            },
            flightsEndpoints: {
                getFlights: `${apiUrl}/flights`,
                standbyCheckin: `${apiUrl}/flights/{{id}}/standbys`,
                acceptCheckin: `${apiUrl}/flights/{{id}}/acceptances`,
                modifyCheckin: `${apiUrl}/flights/{{id}}/modifications`,
                stopCheckin: `${apiUrl}/flights/{{id}}/stop`,
                getJurisdictionMissions: `${apiUrl}/jurisdiction-missions`,
            },
            alertEndpoints: {
                manageAlerts: `${apiUrl}/alerts`,
                getAlertProposals: `${apiUrl}/alerts/proposals`,
                managePoiList: `${apiUrl}/alerts/poi`,
            },
        };
    }
);
