import { AlertGeometrySourceType, AlertPointOfInterest, PoiAlertEntity, WkbAlertEntity, ZoneAlertEntity } from "../models/alert.models";

export namespace AlertActions {
    export class SelectAlertGeometrySourceType {
        public static readonly type = "[Alert] Select alert type";
        constructor(public type: AlertGeometrySourceType | undefined) {}
    }

    export class GetAlerts {
        public static readonly type = "[Alert] Get alerts";
    }

    export class GetAlertProposals {
        public static readonly type = "[Alert] Get alert proposals";
        constructor(public type: AlertGeometrySourceType, public poiId?: string, public designator?: string) {}
    }

    export class CreateZoneAlerts {
        public static readonly type = "[Alert] Create Zone alerts";
        constructor(public zoneAlertEntity: ZoneAlertEntity) {}
    }

    export class CreatePoiAlerts {
        public static readonly type = "[Alert] Create POI alerts";
        constructor(public poiAlertEntity: PoiAlertEntity) {}
    }

    export class CreateWkbAlerts {
        public static readonly type = "[Alert] Create WKB alerts";
        constructor(public poiAlertEntity: WkbAlertEntity) {}
    }

    export class GetAllHospitals {
        public static readonly type = "[Alert] Get all hospitals";
    }

    export class SetBookmarkedHospitals {
        public static readonly type = "[Alert] Set bookmarked hospitals";
        constructor(public newBookmarkedHospitals: AlertPointOfInterest[]) {}
    }
}
