import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, Output } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatMenuModule } from "@angular/material/menu";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { UntilDestroy } from "@ngneat/until-destroy";
import { LetDirective } from "@ngrx/component";
import { delay, distinctUntilChanged, map, tap } from "rxjs";

interface HeightFilterComponentState {
    currentHeightFilter: number;
    isMenuOpen: boolean;
}

export interface HeightFilterGroup {
    height: FormControl<number>;
    customHeight: FormControl<number>;
}

// eslint-disable-next-line no-magic-numbers
const PREDEFINED_HEIGHT_VALUES = [60, 90, 120];
const MAX_CHECKIN_HEIGHT = 120;
const SLIDER_SHOW_TICKS_VALUES_DELAY_MILLISECONDS = 150;
const HEIGHT_SLIDER_OPTIONS = {
    floor: 0,
    ceil: MAX_CHECKIN_HEIGHT,
    vertical: true,
    // eslint-disable-next-line no-magic-numbers
    tickStep: MAX_CHECKIN_HEIGHT / 4,
    showSelectionBar: true,
    showTicksValues: true,
    reversedControls: true,
};

@UntilDestroy()
@Component({
    selector: "dats-lib-height-filter",
    standalone: true,
    imports: [
        CommonModule,
        TranslocoModule,
        SharedUiModule,
        SharedI18nModule,
        NgxSliderModule,
        ReactiveFormsModule,
        MatMenuModule,
        LetDirective,
    ],
    templateUrl: "./height-filter.component.html",
    styleUrls: ["./height-filter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HeightFilterComponent {
    @Input({ required: true }) public set heightFilter(value: number | undefined) {
        this.prepareForm(value ?? 0);
    }

    protected readonly heightFilterFormControl = new FormControl(0, {
        nonNullable: true,
        validators: [Validators.max(MAX_CHECKIN_HEIGHT), Validators.min(0)],
    });

    protected readonly currentHeightFilter$ = this.localStore.selectByKey("currentHeightFilter");

    protected readonly sliderOptions$ = this.localStore.selectByKey("isMenuOpen").pipe(
        map((isMenuOpen) => (isMenuOpen ? HEIGHT_SLIDER_OPTIONS : { ...HEIGHT_SLIDER_OPTIONS, showTicksValues: false })),
        delay(SLIDER_SHOW_TICKS_VALUES_DELAY_MILLISECONDS)
        // NOTE: this is needed to fix the issue with the wrong rendering of the slider ticks values when the menu is opening
    );

    protected readonly PREDEFINED_HEIGHT_VALUES = PREDEFINED_HEIGHT_VALUES;

    @Output() public readonly heightChange = this.heightFilterFormControl.valueChanges.pipe(
        distinctUntilChanged(),
        map((value: number) => Math.max(Math.min(value, MAX_CHECKIN_HEIGHT), 0)),
        tap((value) => this.localStore.patchState({ currentHeightFilter: value }))
    );

    constructor(private readonly localStore: LocalComponentStore<HeightFilterComponentState>) {
        this.localStore.setState({
            currentHeightFilter: 0,
            isMenuOpen: false,
        });
    }

    private prepareForm(filterHeight: number) {
        this.localStore.patchState({ currentHeightFilter: filterHeight });
        this.heightFilterFormControl.setValue(filterHeight, { emitEvent: false });
    }

    protected setMenuOpenState(isMenuOpen: boolean) {
        this.localStore.patchState({ isMenuOpen });

        if (isMenuOpen) {
            this.heightFilterFormControl.setValue(this.localStore.selectSnapshotByKey("currentHeightFilter"), { emitEvent: false });
        }
    }
}
