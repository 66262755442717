<ng-container *ngrxLet="{ progress: progress$, isUnacknowledged: isUnacknowledged$, isSystem: isSystem$ } as vm">
    <ng-container [ngSwitch]="vm.progress?.phase">
        <span *ngSwitchCase="FlightProgressPhase.Emergency" class="chip emergency">
            <dtm-ui-icon name="alert-fill"></dtm-ui-icon>
            {{ "datsLibFlights.flightProgressBadge.statusLabel.Emergency" | transloco }}
        </span>
        <ng-container *ngSwitchCase="FlightProgressPhase.Stop">
            <span class="chip stop" [ngClass]="vm.isUnacknowledged ? 'stop-not-acknowledged' : 'stop-acknowledged'">
                <dtm-ui-icon name="arrow-down-circle-fill"></dtm-ui-icon>
                {{
                    (vm.isUnacknowledged
                        ? "datsLibFlights.flightProgressBadge.statusLabel.StopNotAcknowledged"
                        : "datsLibFlights.flightProgressBadge.statusLabel.StopAcknowledged"
                    ) | transloco
                }}
            </span>
        </ng-container>
        <span *ngSwitchCase="FlightProgressPhase.Overdue" class="chip overdue">
            <dtm-ui-icon name="hourglass-fill"></dtm-ui-icon>
            {{ "datsLibFlights.flightProgressBadge.statusLabel.Overdue" | transloco }}
        </span>
        <span
            *ngSwitchCase="FlightProgressPhase.Completed"
            class="chip"
            [class.completed-system]="vm.isSystem"
            [class.completed-default]="!vm.isSystem"
        >
            <dtm-ui-icon name="focus-2-fill"></dtm-ui-icon>
            {{ "datsLibFlights.flightProgressBadge.statusLabel.Completed" | transloco }}
        </span>
        <span *ngSwitchDefault class="chip default capitalize">
            {{ vm.progress?.phase }}
        </span>
    </ng-container>
</ng-container>
