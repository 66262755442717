<ul class="wrapper" *ngrxLet="{ alerts: alerts$, alertsBadgeType: alertsBadgeType$ } as vm">
    <li class="alert" *ngFor="let alert of vm.alerts">
        <dats-lib-alerts-list-item
            [alert]="alert"
            [alertsBadgeType]="vm.alertsBadgeType"
            (finish)="finishAlert.emit($event)"
            (modify)="modifyAlert.emit($event)"
        ></dats-lib-alerts-list-item>
    </li>
</ul>
