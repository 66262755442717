<ng-container *ngrxLet="{ currentHeightFilter: currentHeightFilter$, sliderOptions: sliderOptions$ } as vm">
    <button
        type="button"
        class="height-filter-button"
        [class.active]="vm.currentHeightFilter > 0"
        [matMenuTriggerFor]="heightFilterMenu"
        (menuOpened)="setMenuOpenState(true)"
        (menuClosed)="setMenuOpenState(false)"
    >
        {{ "datsLibFlights.heightFilter.heightFilterButtonLabel" | transloco : { value: vm.currentHeightFilter } }}
        <dtm-ui-icon name="filter" />
    </button>

    <mat-menu #heightFilterMenu="matMenu">
        <div class="height-filter-panel-content" (click)="$event.stopPropagation()">
            <button
                type="button"
                class="selectable-tile"
                (click)="heightFilterFormControl.setValue(0)"
                [class.selected]="vm.currentHeightFilter === 0"
            >
                {{ "datsLibFlights.heightFilter.allButtonLabel" | transloco }}
            </button>
            <section class="section">
                <dtm-ui-label-value [label]="'datsLibFlights.heightFilter.heightLabel' | transloco"></dtm-ui-label-value>
                <section class="modify-height">
                    <!-- TODO: DTM-5197 - Replace ngx-slider after create custom slider component -->
                    <ngx-slider
                        (userChangeEnd)="heightFilterFormControl.setValue($event.value)"
                        [value]="vm.currentHeightFilter"
                        class="dtm-slider-vertical-height"
                        [options]="vm.sliderOptions"
                    ></ngx-slider>

                    <dtm-ui-expanded-number-input
                        [predefinedOptions]="PREDEFINED_HEIGHT_VALUES"
                        predefinedOptionsPlacement="before"
                        [formControl]="heightFilterFormControl"
                        [minValue]="vm.sliderOptions.floor"
                        [maxValue]="vm.sliderOptions.ceil"
                        [stepValue]="10"
                    >
                        <div class="field-error" *dtmUiFieldHasError="heightFilterFormControl; name: 'max'; error as error">
                            {{
                                "datsLibFlights.heightFilter.maxHeightErrorMessage"
                                    | transloco
                                        : {
                                              max: error.max,
                                          }
                            }}
                        </div>
                    </dtm-ui-expanded-number-input>
                </section>
            </section>
        </div>
    </mat-menu>
</ng-container>
