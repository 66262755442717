<ng-container *ngrxLet="{ isDisabled: isDisabled$ } as vm">
    <button type="button" class="alert-button dropdown-button" [matMenuTriggerFor]="alertDropdownMenu" [disabled]="vm.isDisabled">
        <dtm-ui-icon name="alert-fill"></dtm-ui-icon>
        <span class="label">{{ "datsLibAlert.alertButton.label" | transloco }}</span>
        <dtm-ui-icon name="arrow-down"></dtm-ui-icon>
    </button>

    <mat-menu #alertDropdownMenu="matMenu" xPosition="before">
        <button mat-menu-item type="button" (click)="alertGeometrySourceTypeChange.emit(AlertGeometrySourceType.AreaCircle)">
            <dtm-ui-icon name="pen-nib"></dtm-ui-icon>
            <span>{{ "datsLibAlert.alertButton.areaLabel" | transloco }}</span>
        </button>
        <button mat-menu-item type="button" (click)="alertGeometrySourceTypeChange.emit(AlertGeometrySourceType.Zone)">
            <dtm-ui-icon name="collage"></dtm-ui-icon>
            <span>{{ "datsLibAlert.alertButton.airspaceLabel" | transloco }}</span>
        </button>
        <button mat-menu-item type="button" (click)="alertGeometrySourceTypeChange.emit(AlertGeometrySourceType.Hospital)">
            <dtm-ui-icon name="first-aid-kit-line"></dtm-ui-icon>
            <span>{{ "datsLibAlert.alertButton.hospitalLabel" | transloco }}</span>
        </button>
        <button mat-menu-item type="button" (click)="alertGeometrySourceTypeChange.emit(AlertGeometrySourceType.HEMS)">
            <dtm-ui-icon name="helicopter-lpr"></dtm-ui-icon>
            <span>{{ "datsLibAlert.alertButton.hemsLabel" | transloco }}</span>
        </button>
    </mat-menu>
</ng-container>
