import { LatLngBounds } from "leaflet";

export interface Capabilities {
    permissions: DATSFeatures[];
    jurisdiction: Jurisdiction;
}

export interface Jurisdiction {
    data: string;
    bounds?: LatLngBounds;
}

export enum DATSFeatures {
    ManageAlerts = "MANAGE_ALERTS",
    ServiceFlightInformation = "SERVICE_FLIGHT_INFORMATION",
    ControlAirTraffic = "CONTROL_AIR_TRAFFIC",
    MaintainFlights = "MAINTAIN_FLIGHTS",
    TechnicalCheckin = "TECHNICAL_CHECKIN",
}
