<ul class="wrapper" *ngrxLet="{ alerts: alerts$, alertsBadgeType: alertsBadgeType$ } as vm">
    <li class="alert" [ngClass]="alertBadgesByType[vm.alertsBadgeType].class" *ngFor="let alert of vm.alerts">
        <dtm-ui-icon slot="start" [name]="alertBadgesByType[alert.type].icon"></dtm-ui-icon>
        <ng-container *ngrxLet="alert.title | ellipsifyText : 15 as title" [ngSwitch]="true">
            <ng-container *ngSwitchCase="vm.alertsBadgeType === AlertBadgeType.NotStarted">
                {{
                    "datsLibAlert.alertList.alertFromLabel"
                        | transloco : { title, time: alert.startAt | localizeDate : { timeStyle: "short" } }
                }}
            </ng-container>
            <ng-container *ngSwitchCase="vm.alertsBadgeType !== AlertBadgeType.NotStarted && !!alert.finishAt">
                {{
                    "datsLibAlert.alertList.alertToLabel"
                        | transloco : { title, time: alert.finishAt | localizeDate : { timeStyle: "short" } }
                }}
            </ng-container>
            <ng-container *ngSwitchDefault>{{ title }}</ng-container>
        </ng-container>
    </li>
</ul>
