import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { FlightAcceptance, FlightAcceptancePhase, FlightOperationType } from "../../models/flight.models";

interface FlightAcceptanceBadgeComponentState {
    acceptance: FlightAcceptance | undefined;
    operationType: FlightOperationType | undefined;
}

@Component({
    selector: "dats-lib-flight-acceptance-badge[acceptance]",
    templateUrl: "./flight-acceptance-badge.component.html",
    styleUrls: ["./flight-acceptance-badge.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightAcceptanceBadgeComponent {
    @Input() public set acceptance(value: FlightAcceptance) {
        this.localStore.patchState({ acceptance: value });
        this.badgeClassName = this.getBadgeClassName(value);
    }

    @Input() public set operationType(value: FlightOperationType | undefined) {
        this.localStore.patchState({ operationType: value });
    }

    @HostBinding("class") private badgeClassName = "";

    protected readonly acceptance$ = this.localStore.selectByKey("acceptance");
    protected readonly operationType$ = this.localStore.selectByKey("operationType");
    protected readonly FlightAcceptancePhase = FlightAcceptancePhase;
    protected readonly FlightOperationType = FlightOperationType;

    constructor(private readonly localStore: LocalComponentStore<FlightAcceptanceBadgeComponentState>) {
        this.localStore.setState({
            acceptance: undefined,
            operationType: undefined,
        });
    }

    private getBadgeClassName(acceptance: FlightAcceptance): string {
        let className = "";

        if (acceptance?.type && acceptance.phase !== FlightAcceptancePhase.Standby && acceptance.phase !== FlightAcceptancePhase.Pending) {
            className = acceptance.isSystem ? "system" : "default";
        }

        if (acceptance.phase === FlightAcceptancePhase.Accepted) {
            className += " fill";
        }

        return className;
    }
}
