import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { LanguageCode, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { NgxsModule } from "@ngxs/store";
import { SettingsComponent } from "./components/settings/settings.component";
import { UserSettingsState } from "./state/user-settings.state";

@NgModule({
    imports: [CommonModule, NgxsModule.forFeature([UserSettingsState]), SettingsComponent],
    exports: [SettingsComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "datsLibUserSettings",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class UserSettingsModule {
    public static forRoot(): ModuleWithProviders<UserSettingsModule> {
        return {
            ngModule: UserSettingsModule,
            providers: [],
        };
    }

    public static forTest(): ModuleWithProviders<UserSettingsModule> {
        return {
            ngModule: UserSettingsModule,
            providers: [],
        };
    }
}
