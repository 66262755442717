import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AlertGeometrySourceType } from "../../models/alert.models";

interface AlertSelectButtonComponentState {
    isDisabled: boolean;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-alert-select-button",
    templateUrl: "./alert-select-button.component.html",
    styleUrls: ["./alert-select-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AlertSelectButtonComponent {
    protected readonly AlertGeometrySourceType = AlertGeometrySourceType;

    @Input() public set isDisabled(value: BooleanInput) {
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }

    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");

    @Output() public readonly alertGeometrySourceTypeChange = new EventEmitter<AlertGeometrySourceType>();

    constructor(private readonly localStore: LocalComponentStore<AlertSelectButtonComponentState>) {
        this.localStore.setState({
            isDisabled: false,
        });
    }
}
