<div class="container" *ngrxLet="{ notes: notes$, isEditMode: isEditMode$ } as vm">
    <div class="header">
        <span class="title">{{ "datsLibFlights.missionDetails.notesLabel" | transloco }}</span>
        <button *ngIf="vm.isEditMode" type="button" class="button-icon small" (click)="patchIsEditMode(!vm.isEditMode)">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
        <button *ngIf="!vm.isEditMode" type="button" class="button-secondary" (click)="patchIsEditMode(!vm.isEditMode)">
            <dtm-ui-icon name="add"></dtm-ui-icon>
            {{ "datsLibFlights.missionDetails.addNoteButtonLabel" | transloco }}
        </button>
    </div>
    <div class="content" *ngIf="vm.notes.length > 0">
        <ul *ngFor="let note of vm.notes">
            <li class="note-author">{{ note.timestamp | localizeDate : { timeStyle: "short" } }}, {{ note.author }}</li>
            <li class="note-content">{{ note.note }}</li>
        </ul>
    </div>
    <div class="content" *ngIf="vm.isEditMode" @slideInWithMargin>
        <dtm-ui-textarea-field [maxLength]="MAX_NOTE_LENGTH">
            <label>{{ "datsLibFlights.missionDetails.notesControlLabel" | transloco }}</label>
            <textarea [formControl]="noteFormControl" matInput></textarea>
            <div class="field-error" *dtmUiFieldHasError="noteFormControl; name: 'maxlength'; error as error">
                {{ "datsLibFlights.missionDetails.maxLengthErrorMessage" | transloco : { maxLength: error.requiredLength } }}
            </div>
        </dtm-ui-textarea-field>
        <div class="control-buttons">
            <button type="button" class="button-secondary" (click)="patchIsEditMode(false)">
                {{ "datsLibFlights.missionDetails.cancelButtonLabel" | transloco }}
            </button>
            <button type="button" class="button-primary" (click)="submitNote()">
                {{ "datsLibFlights.missionDetails.saveButtonLabel" | transloco }}
            </button>
        </div>
    </div>
</div>
