import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule, importProvidersFrom } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterLink } from "@angular/router";
import { LeafletMapModule } from "@dtm-frontend/shared/map/leaflet";
import { IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { IonicModule } from "@ionic/angular";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { AlertModule } from "../alert";
import { SharedModule } from "../shared";
import { TimeLabelComponent } from "../shared/components/time-label/time-label.component";
import { CategoryFlightListComponent } from "./components/category-flight-list/category-flight-list.component";
import { ConfirmationBarComponent } from "./components/confirmation-bar/confirmation-bar.component";
import { FisZonesBadgeComponent } from "./components/fis-zones-badge/fis-zones-badge.component";
import { FlightAcceptanceBadgeComponent } from "./components/flight-acceptance-badge/flight-acceptance-badge.component";
import { FlightItemActionsComponent } from "./components/flight-item-actions/flight-item-actions.component";
import { FlightItemComponent } from "./components/flight-item/flight-item.component";
import { FlightProgressBadgeComponent } from "./components/flight-progress-badge/flight-progress-badge.component";
import { FlightProgressBarComponent } from "./components/flight-progress-bar/flight-progress-bar.component";
import { FlightsContainerComponent } from "./components/flights-container/flights-container.component";
import { FlightsFiltersComponent } from "./components/flights-filters/flights-filters.component";
import { FlightsLayerDirective } from "./components/flights-layer/flights-layer.directive";
import { FlightsPanelComponent } from "./components/flights-panel/flights-panel.component";
import { FlightsTabItemComponent } from "./components/flights-tab-item/flights-tab-item.component";
import { MissionListComponent } from "./components/mission-list/mission-list.component";
import { ModifyCheckinFormComponent } from "./components/modify-checkin-form/modify-checkin-form.component";
import { NavbarClockComponent } from "./components/navbar/clock/clock.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { OperationDetailsComponent } from "./components/operation-details/operation-details.component";
import { BookmarkedHospitalListComponent } from "./components/poi/bookmarked-hospitals-list/bookmarked-hospital-list.component";
import { PointsOfInterestComponent } from "./components/poi/points-of-interest/points-of-interest.component";
import { RpaBadgeComponent } from "./components/rpa-badge/rpa-badge.component";
import { ActionSidebarFooterComponent } from "./components/sidebar/action-sidebar-footer/action-sidebar-footer.component";
import { ActionSidebarHeaderComponent } from "./components/sidebar/action-sidebar-header/action-sidebar-header.component";
import { ActionSidebarComponent } from "./components/sidebar/action-sidebar/action-sidebar.component";
import { StandbyCheckinFormComponent } from "./components/standby-checkin-form/standby-checkin-form.component";
import { TimeHeightDisplayComponent } from "./components/time-height-display/time-height-display.component";
import { UavInfoComponent } from "./components/uav-info/uav-info.component";
import { UavMassLimitPipe } from "./pipes/uav-mass-limit.pipe";
import { UavMassPipe } from "./pipes/uav-mass.pipe";
import { FlightsApiService } from "./services/flights-api.service";
import { FlightsState } from "./state/flights.state";

@NgModule({
    declarations: [
        FlightsContainerComponent,
        FlightsPanelComponent,
        FlightsTabItemComponent,
        FlightAcceptanceBadgeComponent,
        FlightProgressBadgeComponent,
        FlightItemComponent,
        FlightItemActionsComponent,
        TimeHeightDisplayComponent,
        UavInfoComponent,
        UavMassPipe,
        UavMassLimitPipe,
        StandbyCheckinFormComponent,
        OperationDetailsComponent,
        ActionSidebarComponent,
        RpaBadgeComponent,
        ModifyCheckinFormComponent,
        FlightProgressBarComponent,
        CategoryFlightListComponent,
        FisZonesBadgeComponent,
        ConfirmationBarComponent,
        FlightsFiltersComponent,
        BookmarkedHospitalListComponent,
        FlightsLayerDirective,
        NavbarClockComponent,
        NavbarComponent,
    ],
    imports: [
        CommonModule,
        TranslocoModule,
        LeafletMapModule,
        LetModule,
        IconDirective,
        IonicModule,
        RouterLink,
        PushModule,
        SharedUiModule,
        MatTooltipModule,
        SharedI18nModule,
        ReactiveFormsModule,
        NgxSliderModule,
        MatInputModule,
        NgxsModule.forFeature([FlightsState]),
        MatProgressBarModule,
        MatSlideToggleModule,
        SharedModule,
        MatChipsModule,
        MatMenuModule,
        PointsOfInterestComponent,
        ActionSidebarHeaderComponent,
        ActionSidebarFooterComponent,
        AlertModule.forRoot(),
        MissionListComponent,
        TimeLabelComponent,
    ],
    exports: [
        FlightsPanelComponent,
        FlightsTabItemComponent,
        FlightAcceptanceBadgeComponent,
        FlightProgressBadgeComponent,
        FlightItemComponent,
        FlightItemActionsComponent,
        TimeHeightDisplayComponent,
        UavInfoComponent,
        StandbyCheckinFormComponent,
        OperationDetailsComponent,
        ActionSidebarComponent,
        RpaBadgeComponent,
        ModifyCheckinFormComponent,
        FlightProgressBarComponent,
        CategoryFlightListComponent,
        FisZonesBadgeComponent,
        ConfirmationBarComponent,
        FlightsFiltersComponent,
        BookmarkedHospitalListComponent,
        FlightsLayerDirective,
        NavbarClockComponent,
        NavbarComponent,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "datsLibFlights",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class FlightsModule {
    public static forRoot(): ModuleWithProviders<FlightsModule> {
        return {
            ngModule: FlightsModule,
            providers: [FlightsApiService],
        };
    }

    public static forTest(): ModuleWithProviders<FlightsModule> {
        return {
            ngModule: FlightsModule,
            providers: [
                {
                    provide: FlightsApiService,
                    useValue: null,
                },
                importProvidersFrom(AlertModule.forTest()),
            ],
        };
    }
}
