import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { UserSettingsActions } from "./user-settings.actions";

export interface UserSettingsStateModel {
    screenBrightness: number;
    systemVolume: number;
}

const defaultState: UserSettingsStateModel = {
    screenBrightness: 50,
    systemVolume: 50,
};

@State<UserSettingsStateModel>({
    name: "userSettings",
    defaults: defaultState,
})
@Injectable()
export class UserSettingsState {
    @Selector()
    public static screenBrightness(state: UserSettingsStateModel) {
        return state.screenBrightness;
    }

    @Selector()
    public static systemVolume(state: UserSettingsStateModel) {
        return state.systemVolume;
    }

    @Action(UserSettingsActions.SetScreenBrightness)
    public setScreenBrightness(context: StateContext<UserSettingsStateModel>, action: UserSettingsActions.SetScreenBrightness) {
        context.patchState({ screenBrightness: action.screenBrightness });
    }

    @Action(UserSettingsActions.SetSystemVolume)
    public setSystemVolume(context: StateContext<UserSettingsStateModel>, action: UserSettingsActions.SetSystemVolume) {
        context.patchState({ systemVolume: action.systemVolume });
    }
}
