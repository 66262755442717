import { FlightCategory, FlightItem } from "../models/flight.models";

import { Pipe, PipeTransform } from "@angular/core";
import { ORDERED_FLIGHT_CATEGORIES } from "../utils/defaults";

@Pipe({
    name: "sortFlightsPanels",
})
export class SortFlightsPanelsPipe implements PipeTransform {
    public transform(
        categorizedFlights?: Partial<Record<FlightCategory, { visibleItems: FlightItem[]; total: number }>> | null
    ): { key: FlightCategory; value: { visibleItems: FlightItem[]; total: number } }[] {
        if (!categorizedFlights) {
            return [];
        }

        return Object.entries(categorizedFlights)
            .map(([key, value]) => ({ key: key as FlightCategory, value }))
            .sort((left, right) => ORDERED_FLIGHT_CATEGORIES.indexOf(left.key) - ORDERED_FLIGHT_CATEGORIES.indexOf(right.key));
    }
}
