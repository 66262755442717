import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { Observable, catchError, map, throwError } from "rxjs";
import { FLIGHTS_ENDPOINTS, FlightsEndpoints } from "../flights.tokens";
import {
    ArchiveCheckinFormValues,
    FlightDetails,
    FlightEventMessage,
    FlightItem,
    FlightsErrorType,
    JurisdictionMission,
    ModifyCheckinFormValues,
    TechnicalCheckinData,
} from "../models/flight.models";
import {
    ArchiveCheckinRequestPayload,
    FlightDetailsResponseBody,
    GetFlightsResponseBody,
    JurisdictionMissionResponseBody,
    ModifyCheckinRequestPayload,
    PageableResponseBody,
    convertGetFlightDetailsResponseBodyToFlightDetails,
    convertGetFlightItemsResponseBodyToFlightItems,
    convertJurisdictionMissionResponseBodyToJurisdictionMission,
    convertTechnicalCheckinDataToRequestPayload,
    prepareCheckinModificationPayload,
} from "./flights-api.converters";

@Injectable({
    providedIn: "root",
})
export class FlightsApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(FLIGHTS_ENDPOINTS) private readonly endpoints: FlightsEndpoints,
        private readonly websocketService: WebsocketService
    ) {}

    public getFlights(): Observable<FlightItem[]> {
        return this.httpClient.get<GetFlightsResponseBody>(this.endpoints.getFlights).pipe(
            map((response) => convertGetFlightItemsResponseBodyToFlightItems(response?.items)),
            catchError((error) => throwError(() => ({ type: FlightsErrorType.CannotGetFlights, error })))
        );
    }

    public getFlightDetails(id: string): Observable<FlightDetails> {
        return this.httpClient
            .get<FlightDetailsResponseBody>(StringUtils.replaceInTemplate(this.endpoints.manageFlightDetails, { id }))
            .pipe(
                map((response) => convertGetFlightDetailsResponseBodyToFlightDetails(response)),
                catchError((error) => throwError(() => ({ type: FlightsErrorType.CannotGetFlightDetails, error })))
            );
    }

    public getJurisdictionMissionList(): Observable<JurisdictionMission[]> {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const params = new HttpParams().set("startBefore", tomorrow.toISOString());

        return this.httpClient
            .get<PageableResponseBody<JurisdictionMissionResponseBody>>(this.endpoints.getJurisdictionMissionList, { params })
            .pipe(
                map((response) => response.content.map((mission) => convertJurisdictionMissionResponseBodyToJurisdictionMission(mission))),
                catchError((error) => throwError(() => ({ type: FlightsErrorType.CannotGetJurisdictionMissions, error })))
            );
    }

    public getJurisdictionMission(id: string): Observable<JurisdictionMission> {
        return this.httpClient
            .get<JurisdictionMissionResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getJurisdictionMission, { id }))
            .pipe(
                map((response) => convertJurisdictionMissionResponseBodyToJurisdictionMission(response)),
                catchError((error) => throwError(() => ({ type: FlightsErrorType.CannotGetJurisdictionMission, error })))
            );
    }

    public standbyCheckin(id: string, duration: number): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.standbyCheckin, { id }), {
                duration: `PT${duration}M`,
            })
            .pipe(catchError((error) => throwError(() => ({ type: FlightsErrorType.CannotStandbyCheckin, error }))));
    }

    public modifyCheckin(id: string, formValues: ModifyCheckinFormValues) {
        const requestBody: ModifyCheckinRequestPayload = prepareCheckinModificationPayload(formValues);

        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.modifyCheckin, { id }), requestBody)
            .pipe(catchError((error) => throwError(() => ({ type: FlightsErrorType.CannotModifyCheckin, error }))));
    }

    public modifyCheckinAndAccept(id: string, formValues: ModifyCheckinFormValues) {
        const requestBody: ModifyCheckinRequestPayload = prepareCheckinModificationPayload(formValues);

        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.acceptCheckin, { id }), requestBody)
            .pipe(catchError((error) => throwError(() => ({ type: FlightsErrorType.CannotModifyCheckin, error }))));
    }

    public acceptCheckin(id: string) {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.acceptCheckin, { id }), {})
            .pipe(catchError((error) => throwError(() => ({ type: FlightsErrorType.CannotAcceptCheckin, error }))));
    }

    public stopCheckin(id: string) {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.stopCheckin, { id }), {})
            .pipe(catchError((error) => throwError(() => ({ type: FlightsErrorType.CannotStopCheckin, error }))));
    }

    public rejectCheckin(id: string) {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.acceptCheckin, { id }))
            .pipe(catchError((error) => throwError(() => ({ type: FlightsErrorType.CannotRejectCheckin, error }))));
    }

    public archiveCheckin(id: string, formValues: ArchiveCheckinFormValues) {
        const body: ArchiveCheckinRequestPayload = {
            reason: {
                id: formValues.id,
                value: formValues.description,
            },
        };

        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.manageFlightDetails, { id }), { body })
            .pipe(catchError((error) => throwError(() => ({ type: FlightsErrorType.CannotArchiveCheckin, error }))));
    }

    public resendCheckinConfirmation(id: string): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.modifyCheckin, { id }), {})
            .pipe(catchError((error) => throwError(() => ({ type: FlightsErrorType.CannotResendCheckinConfirmation, error }))));
    }

    public createTechnicalCheckin(checkinData: TechnicalCheckinData): Observable<void> {
        return this.httpClient
            .post<void>(this.endpoints.createTechnicalCheckin, convertTechnicalCheckinDataToRequestPayload(checkinData), {
                headers: {
                    "Content-Type": "application/vnd.pansa.dats.v1+json",
                    Accept: "application/vnd.pansa.dats.v1+json",
                },
            })
            .pipe(catchError((error) => throwError(() => ({ type: FlightsErrorType.CannotCreateTechnicalCheckin, error }))));
    }

    public startFlightsWatch(): Observable<FlightEventMessage | undefined> {
        return this.websocketService.watchTopic(this.endpoints.wsFlightsTopic);
    }
}
