<ng-container *ngrxLet="{ acceptance: acceptance$, operationType: operationType$ } as vm">
    <ng-container [ngSwitch]="vm.acceptance?.phase">
        <ng-container *ngSwitchCase="FlightAcceptancePhase.Accepted">
            <span class="accepted chip">
                <dtm-ui-icon name="checkbox-circle-fill"></dtm-ui-icon>
                {{ "datsLibFlights.flightAcceptanceBadge.statusLabelValue" | transloco : { value: vm.acceptance?.phase } }}
            </span>
        </ng-container>
        <span
            *ngSwitchCase="FlightAcceptancePhase.Pending"
            class="chip pending"
            [class.type-112]="vm.operationType === FlightOperationType.Emergency112 || vm.operationType === FlightOperationType.Ghost112"
        >
            <dtm-ui-icon name="loader"></dtm-ui-icon>
            {{ "datsLibFlights.flightAcceptanceBadge.statusLabelValue" | transloco : { value: vm.acceptance?.phase } }}
        </span>
        <span *ngSwitchCase="FlightAcceptancePhase.Standby" class="chip standby">
            <dtm-ui-icon name="pause-circle-fill"></dtm-ui-icon>
            {{ "datsLibFlights.flightAcceptanceBadge.statusLabelValue" | transloco : { value: vm.acceptance?.phase } }}
        </span>
        <span *ngSwitchCase="FlightAcceptancePhase.Rejected" class="chip rejected">
            <dtm-ui-icon name="close-circle-fill"></dtm-ui-icon>
            {{ "datsLibFlights.flightAcceptanceBadge.statusLabelValue" | transloco : { value: vm.acceptance?.phase } }}
        </span>
        <span *ngSwitchCase="FlightAcceptancePhase.Canceled" class="chip canceled">
            <dtm-ui-icon name="close-circle-fill"></dtm-ui-icon>
            {{ "datsLibFlights.flightAcceptanceBadge.statusLabelValue" | transloco : { value: vm.acceptance?.phase } }}
        </span>
        <span *ngSwitchDefault class="chip default capitalize">
            {{ vm.acceptance?.phase }}
        </span>
    </ng-container>
</ng-container>
