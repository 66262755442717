<dtm-ui-input-field matAutocompleteOrigin #autocompleteOrigin="matAutocompleteOrigin" [isClearable]="false">
    <label>{{ "datsLibAlert.alertForms.zoneAlertForm.zoneLabel" | transloco }}</label>
    <input
        matInput
        [matAutocomplete]="auto"
        [matAutocompleteConnectedTo]="autocompleteOrigin"
        [formControl]="zoneFormControl"
        [placeholder]="'datsLibAlert.alertForms.zoneAlertForm.zonePlaceholder' | transloco"
        (focusout)="propagateTouch()"
    />
    <mat-autocomplete
        (optionSelected)="selectValue($event)"
        [displayWith]="displayAutocompleteValueFn"
        #auto="matAutocomplete"
        [hideSingleSelectionIndicator]="true"
    >
        <ng-container *ngrxLet="{ options: options$, isProcessing: isProcessing$, selectedValue: selectedValue$ } as vm">
            <ng-container *ngIf="!vm.isProcessing; else loaderTemplate">
                <mat-option *ngIf="!vm.options.length" class="empty-options" disabled>
                    {{ "datsLibAlert.alertForms.zoneAlertForm.noValueLabel" | transloco }}
                </mat-option>
                <mat-option *ngFor="let option of vm.options" [value]="option">
                    <dtm-ui-radio-field [checked]="option.designator === vm.selectedValue?.designator">
                        {{ option.designator }}
                    </dtm-ui-radio-field>
                </mat-option>
            </ng-container>
            <ng-template #loaderTemplate>
                <mat-option class="options-loader" disabled>
                    <mat-spinner diameter="20" color="accent"></mat-spinner>
                </mat-option>
            </ng-template>
        </ng-container>
    </mat-autocomplete>
    <ng-content select=".field-error" ngProjectAs=".field-error"></ng-content>
</dtm-ui-input-field>
