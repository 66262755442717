<ng-container
    *ngrxLet="{
        templates: templates$,
        selectedAlertType: selectedAlertType$,
        zoneAutocompletionList: zoneAutocompletionList$,
        isZoneAutocompletionProcessing: isZoneAutocompletionProcessing$
    } as vm"
>
    <dats-lib-action-sidebar-header (actionCancel)="actionCancel.emit()">
        {{ "datsLibAlert.alertForms.zoneAlertForm.header" | transloco }}
    </dats-lib-action-sidebar-header>
    <dats-lib-alert-form
        class="action-sidebar-content scroll-shadows"
        [templates]="vm.templates"
        [alertType]="vm.selectedAlertType"
        [isMessageTemplateIdSelectionDisabled]="!zoneFormControl.value"
        cdkScrollable
    >
        <dats-lib-alert-zone
            class="alert-zone"
            contentSlot
            [options]="vm.zoneAutocompletionList"
            [isProcessing]="vm.isZoneAutocompletionProcessing"
            [formControl]="zoneFormControl"
            (searchTextChange)="searchTextChange($event)"
        >
            <div class="field-error" *dtmUiFieldHasError="zoneFormControl; name: 'required'">
                {{ "datsLibAlert.requiredFieldErrorMessage" | transloco }}
            </div>
        </dats-lib-alert-zone>
    </dats-lib-alert-form>
    <dats-lib-action-sidebar-footer
        (actionCancel)="actionCancel.emit()"
        (actionConfirm)="tryEmitConfirm()"
        [confirmButtonText]="'datsLibAlert.alertForms.saveButtonLabel' | transloco : { value: vm.selectedAlertType }"
    >
    </dats-lib-action-sidebar-footer>
</ng-container>
