import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { PushPipe } from "@ngrx/component";

interface ActionSidebarHeaderComponentState {
    headerText: string;
}

@Component({
    selector: "dats-lib-action-sidebar-header",
    standalone: true,
    imports: [SharedUiModule, PushPipe],
    templateUrl: "./action-sidebar-header.component.html",
    styleUrls: ["./action-sidebar-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ActionSidebarHeaderComponent {
    @Input({ required: true }) public set headerText(value: string) {
        this.localStore.patchState({ headerText: value });
    }
    @Output() public readonly actionCancel = new EventEmitter<void>();

    protected readonly headerText$ = this.localStore.selectByKey("headerText");

    constructor(private readonly localStore: LocalComponentStore<ActionSidebarHeaderComponentState>) {
        this.localStore.setState({
            headerText: "",
        });
    }
}
