<ng-container *ngrxLet="{ flightsTab: flightsTab$ } as vm">
    <div class="content">
        <ng-container [ngSwitch]="vm.flightsTab?.type">
            <dtm-ui-icon
                *ngSwitchCase="FlightsTabType.UTM_ATC"
                [name]="vm.flightsTab?.isActionRequired ? 'notification-2-fill' : 'notification'"
                [class.action-required]="vm.flightsTab?.isActionRequired"
            ></dtm-ui-icon>
            <dtm-ui-icon
                *ngSwitchCase="FlightsTabType.UTM_FIS"
                [name]="vm.flightsTab?.isActionRequired ? 'notification-2-fill' : 'notification'"
                [class.action-required]="vm.flightsTab?.isActionRequired"
            ></dtm-ui-icon>
            <dtm-ui-icon *ngSwitchCase="FlightsTabType.AcceptedManually" name="user-follow-fill" class="accepted-manually"></dtm-ui-icon>
            <dtm-ui-icon *ngSwitchCase="FlightsTabType.SystemAccepted" name="window-fill"></dtm-ui-icon>
            <dtm-ui-icon *ngSwitchCase="FlightsTabType.FIS" name="information-fill"></dtm-ui-icon>
            <span *ngSwitchCase="FlightsTabType.TWR">{{ "datsLibFlights.flightsTabItem.typeLabel.TWR" | transloco }}</span>
            <span *ngSwitchCase="FlightsTabType.DTM">{{ "datsLibFlights.flightsTabItem.typeLabel.DTM" | transloco }}</span>
            <dtm-ui-icon *ngSwitchCase="FlightsTabType.Mission" name="treasure-map-fill"></dtm-ui-icon>
            <dtm-ui-icon *ngSwitchCase="FlightsTabType.Archive" name="archive-fill"></dtm-ui-icon>
        </ng-container>

        <span>{{ vm.flightsTab?.total }}</span>
    </div>
</ng-container>
