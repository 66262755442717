import { createDynamicEnvironment } from "@dtm-frontend/shared/utils";
import type DynamicConfiguation from "../assets/dynamic.config.json";
import { DroneAirTrafficServicesEnvironment } from "./environment.model";

export const dynamicEnvironment = createDynamicEnvironment<DroneAirTrafficServicesEnvironment, typeof DynamicConfiguation>(
    fetch("../assets/dynamic.config.json").then((config) => config.json()),
    ({ apiDomain, keycloakConfig }) => {
        const apiUrl = `https://${apiDomain}/api`;
        const apiDatsUrl = `${apiUrl}/dats`;
        const webSocketApiUrl = `wss://${apiDomain}/api/ws`;

        return {
            production: false,
            name: "dev",
            webSocketEndpoint: webSocketApiUrl,
            keycloakConfig,
            azureMapsSubscriptionKey: "HS9JYfhTrM0Ud2smxePEEpkueCl81_SAD2T-VU-ZNFk",
            bearerExcludedUrls: [],
            leafletMapConfig: {
                defaultPosition: {
                    lat: 52.115,
                    lng: 19.424,
                },
                zoom: {
                    min: 6,
                    max: 18,
                    initial: 7,
                    userPosition: 17,
                },
            },
            flightsEndpoints: {
                getFlights: `${apiDatsUrl}/flights`,
                manageFlightDetails: `${apiDatsUrl}/flights/{{id}}`,
                standbyCheckin: `${apiDatsUrl}/flights/{{id}}/standbys`,
                acceptCheckin: `${apiDatsUrl}/flights/{{id}}/acceptances`,
                modifyCheckin: `${apiDatsUrl}/flights/{{id}}/modifications`,
                stopCheckin: `${apiDatsUrl}/flights/{{id}}/stop`,
                createTechnicalCheckin: `${apiDatsUrl}/test/checkins`,
                getJurisdictionMissionList: `${apiDatsUrl}/jurisdiction-missions`,
                getJurisdictionMission: `${apiDatsUrl}/jurisdiction-missions/{{id}}`,
                wsFlightsTopic: "/websocket/topic/dats/dats/all",
            },
            alertEndpoints: {
                manageAlerts: `${apiDatsUrl}/alerts`,
                alertManagement: `${apiDatsUrl}/alerts/{{id}}`,
                getAlertProposal: `${apiDatsUrl}/alerts/proposal`,
                managePoiList: `${apiDatsUrl}/alerts/poi`,
                zoneAutocompletion: `${apiDatsUrl}/airspace/autocompletion`,
            },
            capabilitiesEndpoints: {
                getCapabilities: `${apiDatsUrl}/organization/capabilities`,
            },
            translationsEndpoint: {
                getSystemTranslations: `${apiUrl}/system/translations/{{locale}}`,
            },
        };
    }
);
