import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { PushPipe } from "@ngrx/component";
import { JurisdictionMission } from "../../models/flight.models";
import { MissionItemComponent } from "../mission-item/mission-item.component";

interface MissionListComponentState {
    missions: JurisdictionMission[];
}

@Component({
    selector: "dats-lib-mission-list",
    standalone: true,
    imports: [CommonModule, SharedUiModule, PushPipe, MissionItemComponent],
    templateUrl: "./mission-list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionListComponent {
    @Input({ required: true }) public set missions(value: JurisdictionMission[]) {
        this.localStore.patchState({ missions: value });
    }

    protected readonly missions$ = this.localStore.selectByKey("missions");

    constructor(private readonly localStore: LocalComponentStore<MissionListComponentState>) {
        this.localStore.setState({
            missions: [],
        });
    }
}
