import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { LetDirective } from "@ngrx/component";

const MAX_NOTE_LENGTH = 200;

interface MissionDetailsNotesComponentState {
    // TODO: DTATS-499 Implement note integration, model currently only for demo
    notes: { note: string; timestamp: Date; author: string }[];
    isEditMode: boolean;
}

@Component({
    selector: "dats-lib-mission-details-notes",
    standalone: true,
    imports: [CommonModule, LetDirective, SharedUiModule, SharedI18nModule, TranslocoModule, ReactiveFormsModule, MatInputModule],
    templateUrl: "./mission-details-notes.component.html",
    styleUrls: ["./mission-details-notes.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimationWithMargin()],
    providers: [LocalComponentStore],
})
export class MissionDetailsNotesComponent {
    @Input() public set notes(value: { note: string; timestamp: Date; author: string }[]) {
        this.localStore.patchState({ notes: value });
    }

    protected readonly notes$ = this.localStore.selectByKey("notes");
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");

    protected readonly MAX_NOTE_LENGTH = MAX_NOTE_LENGTH;
    protected readonly noteFormControl = new FormControl("", [Validators.maxLength(MAX_NOTE_LENGTH)]);

    constructor(private readonly localStore: LocalComponentStore<MissionDetailsNotesComponentState>) {
        this.localStore.setState({
            notes: [],
            isEditMode: false,
        });
    }

    protected patchIsEditMode(isEditMode: boolean) {
        this.localStore.patchState({ isEditMode });
    }

    protected submitNote() {
        if (this.noteFormControl.invalid) {
            this.noteFormControl.markAsTouched();

            return;
        }

        alert("TODO: DTATS-499 Implement note integration");
    }
}
