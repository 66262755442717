import { ChangeDetectionStrategy, Component, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FormUtils, ISO8601TimeDuration, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { distinctUntilChanged } from "rxjs";
import { AlertTextTemplate, AlertType } from "../../../models/alert.models";
import { AlertTimeRange } from "../alert-time-range/alert-time-range.component";

interface AlertFormComponentState {
    templates: AlertTextTemplate[];
}

export interface AlertFormGroup {
    type: FormControl<AlertType>;
    timeRange: FormControl<AlertTimeRange | null>;
    message: FormControl<string | null>;
}

const MAX_MESSAGE_LENGTH = 200;

@UntilDestroy()
@Component({
    selector: "dats-lib-alert-form",
    templateUrl: "./alert-form.component.html",
    styleUrls: ["./alert-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AlertFormComponent {
    @Input() public set alertType(value: AlertType) {
        this.alertForm.controls.type.setValue(value);
    }
    @Input({ required: true }) public set templates(value: AlertTextTemplate[]) {
        this.localStore.patchState({ templates: value });
    }

    public readonly alertForm = new FormGroup<AlertFormGroup>({
        type: new FormControl<AlertType>(AlertType.NoFlyZone, { nonNullable: true }),
        message: new FormControl<string | null>(null, [Validators.maxLength(MAX_MESSAGE_LENGTH)]),
        timeRange: new FormControl<AlertTimeRange | null>(null, { nonNullable: true }),
    });

    @Output() public readonly valueChange = this.alertForm.valueChanges.pipe(distinctUntilChanged(equal));
    @Output() public readonly statusChange = this.alertForm.statusChanges.pipe(distinctUntilChanged());

    protected readonly AlertType = AlertType;
    protected readonly MAX_MESSAGE_LENGTH = MAX_MESSAGE_LENGTH;

    protected readonly templates$ = this.localStore.selectByKey("templates");

    constructor(private readonly localStore: LocalComponentStore<AlertFormComponentState>) {
        this.localStore.setState({
            templates: [],
        });
    }

    protected setMessageFromTemplate(templateContent: string) {
        this.alertForm.controls.message.setValue(templateContent);
    }

    protected setAlertType(type: AlertType) {
        if (type === AlertType.Message) {
            this.alertForm.controls.message.addValidators(FormUtils.trimmedRequiredValidator);
            this.alertForm.controls.message.updateValueAndValidity();
            this.alertForm.controls.message.markAsUntouched();
        } else {
            this.alertForm.controls.message.removeValidators(FormUtils.trimmedRequiredValidator);
            this.alertForm.controls.message.updateValueAndValidity();
            this.alertForm.controls.message.markAsTouched();
        }
    }

    protected setLimitedTimeRange(): void {
        this.alertForm.controls.timeRange.setValue({
            startDate: this.alertForm.controls.timeRange.value?.startDate ?? new Date(),
            duration: "PT1H" as ISO8601TimeDuration,
        });
    }
}
