import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatLegacyChip as MatChip } from "@angular/material/legacy-chips";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { ChipFilter } from "../../models/flights-filters.models";

interface FlightsFiltersComponentState<T> {
    chipFilters: ChipFilter<T>[];
    totalCount: number | undefined;
    selectAllValueLabel: string | undefined;
    isMultiple: boolean;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-flights-filters",
    templateUrl: "./flights-filters.component.html",
    styleUrls: ["./flights-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightsFiltersComponent<T> {
    @Input() public set chipFilters(value: ChipFilter<T>[] | undefined) {
        const chipFilters = value ?? [];
        this.localStore.patchState({ chipFilters });

        const newChipValues = chipFilters.map((chip) => chip.value);
        if (this.chipsControl.value.some((selectedChip) => !newChipValues.includes(selectedChip))) {
            this.resetChipsControl();
        }
    }

    @Input() public set totalCount(value: number | undefined) {
        this.localStore.patchState({ totalCount: value ?? 0 });
    }

    @Input() public set selectAllValueLabel(value: string | undefined) {
        this.localStore.patchState({ selectAllValueLabel: value });
    }

    @Input() public set isMultiple(value: BooleanInput) {
        this.localStore.patchState({ isMultiple: coerceBooleanProperty(value) });
    }

    @Input({ required: true }) public set appliedFilters(value: T[] | undefined) {
        if (value?.length === this.localStore.selectSnapshotByKey("chipFilters").length) {
            this.chipsControl.setValue([], { emitEvent: false });
        } else {
            this.chipsControl.setValue(value ?? [], { emitEvent: false });
        }
    }

    protected readonly chipsControl: FormControl<T[]> = new FormControl<T[]>([], { nonNullable: true });

    @Output() public filtersChange: Observable<T[]> = this.chipsControl.valueChanges;

    protected readonly chipsFilters$ = this.localStore.selectByKey("chipFilters");
    protected readonly totalCount$ = this.localStore.selectByKey("totalCount");
    protected readonly selectAllValueLabel$ = this.localStore.selectByKey("selectAllValueLabel");
    protected readonly isMultiple$ = this.localStore.selectByKey("isMultiple");

    constructor(private readonly localStore: LocalComponentStore<FlightsFiltersComponentState<T>>) {
        this.localStore.setState({
            chipFilters: [],
            totalCount: undefined,
            selectAllValueLabel: undefined,
            isMultiple: false,
        });
    }

    protected resetChipsControl() {
        this.chipsControl.setValue([]);
    }

    protected handleSelectionMultiple(chip: MatChip) {
        if (this.chipsControl.value?.length === 0) {
            this.chipsControl.setValue([chip.value]);

            return;
        }

        if (chip.selected) {
            if (this.chipsControl.value?.length === 1) {
                return this.resetChipsControl();
            }

            return this.deselectChip(chip.value);
        }

        chip.selectViaInteraction();
    }

    protected handleSelectionSingle(chip: MatChip) {
        if (chip.selected) {
            return this.deselectChip(chip.value);
        }

        this.chipsControl.setValue([chip.value]);
    }

    private deselectChip(chip: T) {
        const selected = this.chipsControl.value;
        this.chipsControl.setValue(selected?.filter((selectedChip) => selectedChip !== chip) ?? null);
    }
}
