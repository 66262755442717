<ng-container
    *ngrxLet="{
        selectedTabType: selectedTabType$,
        actionSidebarType: actionSidebarType$,
        isPanelProcessing: isPanelProcessing$,
        isAtcController: isAtcController$,
        flightList: flightList$,
        tabs: tabs$,
        jurisdictionMissions: jurisdictionMissions$,
        zoom: zoom$,
        selectedFlightId: selectedFlightId$,
        hospitalList: hospitalList$,
        appliedFilters: appliedFilters$,
        selectedAlertGeometrySourceType: selectedAlertGeometrySourceType$,
    } as vm"
>
    <dtm-map-leaflet-map [mapOptions]="mapOptions" (mapClick)="selectFlight(undefined, 'map')">
        <dats-lib-flights-layer
            [flightList]="vm.flightList"
            [selectedFlightId]="vm.selectedFlightId"
            [zoom]="vm.zoom"
            (flightClick)="selectFlight($event, 'map')"
        ></dats-lib-flights-layer>

        <ng-container mapControls>
            <button class="leaflet-button" type="button" (click)="zoomToJurisdiction()">
                <dtm-ui-icon name="home-2"></dtm-ui-icon>
            </button>
        </ng-container>
    </dtm-map-leaflet-map>
    <dats-lib-alert-geometry-panel
        [selectedAlertGeometrySourceType]="vm.selectedAlertGeometrySourceType"
        (alertGeometrySourceTypeChange)="changeAlertGeometrySourceType($event)"
    />
    <section class="sidebar">
        <dats-lib-points-of-interest
            (hospitalClick)="manageHospitalListPanel(true)"
            [hospitalList]="vm.hospitalList"
        ></dats-lib-points-of-interest>
        <dats-lib-alert-badges></dats-lib-alert-badges>
    </section>
    <section class="alert-form-panel-wrapper" [ngSwitch]="vm.selectedAlertGeometrySourceType">
        <dats-lib-airspace-alert-form
            *ngSwitchCase="AlertGeometrySourceType.Airspace"
            [zones]="[]"
            [templates]="{}"
            (cancel)="changeAlertGeometrySourceType(undefined)"
        />
    </section>
    <section class="flights-panel-wrapper">
        <dats-lib-flights-panel
            [selectedTabType]="vm.selectedTabType"
            [isProcessing]="vm.isPanelProcessing"
            [flightList]="vm.flightList"
            [tabs]="vm.tabs"
            [jurisdictionMissions]="vm.jurisdictionMissions"
            [isAtcController]="vm.isAtcController"
            [appliedFilters]="vm.appliedFilters"
            [selectedFlightId]="vm.selectedFlightId"
            (selectedTabUpdate)="updateSelectedTab($event)"
            (checkinModify)="handleCheckinAction($event)"
            (confirmationResend)="resendConfirmation($event)"
            (selectedFlightChange)="selectFlight($event, 'list')"
            (filtersChange)="applyFilters($event)"
        ></dats-lib-flights-panel>
    </section>
    <section class="action-sidebar">
        <dats-lib-action-sidebar [isOpen]="vm.actionSidebarType" [isProcessing]="isActionProcessing$ | ngrxPush">
            <ng-container
                *ngrxLet="{
                    checkinAction: checkinAction$,
                    flight: flight$
                } as sidebar"
            >
                <dats-lib-standby-checkin-form
                    *ngIf="vm.actionSidebarType === ActionSidebarType.Checkin && sidebar.checkinAction.type === CheckinActionType.Standby"
                    [flight]="sidebar.flight"
                    (actionCancel)="tryToCloseActionSidebar()"
                    (actionConfirm)="delayOrRejectCheckin($event)"
                    (isFormChanged)="markFormAsChanged($event)"
                ></dats-lib-standby-checkin-form>
                <dats-lib-modify-checkin-form
                    *ngIf="vm.actionSidebarType === ActionSidebarType.Checkin && sidebar.checkinAction.type === CheckinActionType.Modify"
                    [flight]="sidebar.flight"
                    (actionCancel)="tryToCloseActionSidebar()"
                    (actionConfirm)="modifyCheckin($event)"
                    (isFormChanged)="markFormAsChanged($event)"
                ></dats-lib-modify-checkin-form>
            </ng-container>

            <dats-lib-bookmarked-hospital-list
                *ngIf="vm.actionSidebarType === ActionSidebarType.Hospital"
                [hospitalList]="vm.hospitalList"
                (actionCancel)="closeActionSidebar()"
                (actionConfirm)="dispatchHospitalList($event)"
            ></dats-lib-bookmarked-hospital-list>
        </dats-lib-action-sidebar>
    </section>
</ng-container>
