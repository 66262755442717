import { AnimationEvent } from "@angular/animations";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from "@angular/core";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { combineLatest, map, startWith } from "rxjs";
import {
    AirspaceType,
    CheckinAction,
    FlightCategory,
    FlightList,
    FlightsTab,
    FlightsTabType,
    JurisdictionMission,
} from "../../models/flight.models";
import { ChipFilter, FlightFilterType, OperationTypeFilter } from "../../models/flights-filters.models";
import { AVAILABLE_AIRSPACE_TYPE_FILTERS, AVAILABLE_CATEGORY_FILTERS, ORDERED_FLIGHT_CATEGORIES } from "../../utils/defaults";
import { CategoryFlightListComponent } from "../category-flight-list/category-flight-list.component";

interface FlightsPanelComponentState {
    tabs: FlightsTab[];
    jurisdictionMissions: JurisdictionMission[];
    selectedTabType: FlightsTabType | undefined;
    isProcessing: boolean;
    isAtcController: boolean;
    isMainPanelExpanded: boolean;
    flightList: FlightList | undefined;
    appliedFilters: FlightFilterType[];
    selectedFlightId: string | undefined;
    isMaintainFlightsFeatureAvailable: boolean;
    isControlAirTrafficFeatureAvailable: boolean;
}

const ANIMATION_DURATION = 300;
const ANIMATION_DELAY = 0;
const FOLD_OFFSET = "628px";

@Component({
    selector: "dats-lib-flights-panel",
    templateUrl: "./flights-panel.component.html",
    styleUrls: ["./flights-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.foldAnimation(ANIMATION_DURATION, ANIMATION_DELAY, FOLD_OFFSET)],
})
export class FlightsPanelComponent {
    @Input() public set selectedTabType(value: FlightsTabType | undefined) {
        this.localStore.patchState({ selectedTabType: value, isMainPanelExpanded: !!value });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set appliedFilters(value: FlightFilterType[] | undefined) {
        this.localStore.patchState({ appliedFilters: value ?? [] });
    }
    @Input({ required: true }) public set tabs(value: FlightsTab[] | undefined) {
        this.localStore.patchState({ tabs: value ?? [] });
    }
    @Input({ required: true }) public set jurisdictionMissions(value: JurisdictionMission[] | undefined) {
        this.localStore.patchState({ jurisdictionMissions: value ?? [] });
    }
    @Input({ required: true }) public set flightList(value: FlightList | undefined) {
        this.localStore.patchState({ flightList: value });
    }
    @Input({ required: true }) public set selectedFlightId(value: string | undefined) {
        this.localStore.patchState({ selectedFlightId: value });
    }
    @Input() public set isAtcController(value: BooleanInput) {
        this.localStore.patchState({ isAtcController: coerceBooleanProperty(value) });
    }
    @Input() public set isMaintainFlightsFeatureAvailable(value: BooleanInput) {
        this.localStore.patchState({ isMaintainFlightsFeatureAvailable: coerceBooleanProperty(value) });
    }
    @Input() public set isControlAirTrafficFeatureAvailable(value: BooleanInput) {
        this.localStore.patchState({ isControlAirTrafficFeatureAvailable: coerceBooleanProperty(value) });
    }

    @Output() public readonly selectedTabUpdate: EventEmitter<FlightsTab> = new EventEmitter<FlightsTab>();
    @Output() public readonly checkinModify: EventEmitter<CheckinAction> = new EventEmitter<CheckinAction>();
    @Output() public readonly confirmationResend = new EventEmitter<string>();
    @Output() public readonly selectedFlightChange = new EventEmitter<string>();
    @Output() public readonly filtersChange = new EventEmitter<FlightFilterType[]>();
    @Output() public readonly extendedMissionDataOpen = new EventEmitter<string>();

    public get clientWidth(): number {
        return this.flightsPanelWrapperElement?.nativeElement.clientWidth ?? 0;
    }

    protected readonly tabs$ = this.localStore.selectByKey("tabs").pipe(map((tabs) => tabs.filter((tab) => tab.total)));
    protected readonly jurisdictionMissions$ = this.localStore.selectByKey("jurisdictionMissions");
    protected readonly isMainPanelExpanded$ = this.localStore.selectByKey("isMainPanelExpanded");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isAtcController$ = this.localStore.selectByKey("isAtcController");
    protected readonly flightList$ = this.localStore.selectByKey("flightList");
    protected readonly chipFilters$ = this.flightList$.pipe(map((flights) => this.getChipFilters(flights)));
    protected readonly appliedFilters$ = this.localStore.selectByKey("appliedFilters");
    protected readonly selectedFlightId$ = this.localStore.selectByKey("selectedFlightId");
    protected readonly isMaintainFlightsFeatureAvailable$ = this.localStore.selectByKey("isMaintainFlightsFeatureAvailable");
    protected readonly isControlAirTrafficFeatureAvailable$ = this.localStore.selectByKey("isControlAirTrafficFeatureAvailable");

    protected readonly selectedTab$ = combineLatest([this.localStore.selectByKey("selectedTabType"), this.tabs$]).pipe(
        map(([selectedTabType, tabs]) => tabs?.find((tab) => tab.type === selectedTabType))
    );

    protected readonly lastSelectedFlight$ = combineLatest([this.flightList$, this.selectedFlightId$]).pipe(
        map(([flights, selectedFlightId]) =>
            [...(flights?.visibleFlights ?? []), ...(flights?.filteredOutFlights ?? [])].find((flight) => flight.id === selectedFlightId)
        ),
        RxjsUtils.filterFalsy(),
        startWith(undefined)
    );

    protected readonly FlightCategory = FlightCategory;
    protected readonly FlightsTabType = FlightsTabType;

    @ViewChildren(CategoryFlightListComponent) private readonly categoryFlightListComponent:
        | QueryList<CategoryFlightListComponent>
        | undefined;
    @ViewChild("flightsPanelWrapper") private readonly flightsPanelWrapperElement: ElementRef<HTMLElement> | undefined;
    @ViewChild("flightsList") private readonly flightsList: ElementRef<HTMLElement> | undefined;

    constructor(private readonly localStore: LocalComponentStore<FlightsPanelComponentState>) {
        this.localStore.setState({
            tabs: [],
            jurisdictionMissions: [],
            selectedTabType: undefined,
            isProcessing: false,
            isAtcController: false,
            isMainPanelExpanded: false,
            flightList: undefined,
            appliedFilters: [],
            selectedFlightId: undefined,
            isMaintainFlightsFeatureAvailable: false,
            isControlAirTrafficFeatureAvailable: false,
        });
    }

    public scrollFlightIntoView(flightId: string) {
        this.categoryFlightListComponent?.forEach((list) => list.scrollFlightIntoView(flightId));
    }

    protected selectTab(tab: FlightsTab) {
        if (tab.total === 0) {
            return;
        }

        this.selectedTabUpdate.emit(tab);
    }

    protected closePanel() {
        this.localStore.patchState({ isMainPanelExpanded: false });
    }

    protected handleFoldAnimationStart(event: AnimationEvent) {
        if (event.toState === "open") {
            this.flightsList?.nativeElement.scrollTo({
                top: 0,
            });
        }
    }

    protected handleFoldAnimationDone(event: AnimationEvent) {
        if (event.fromState !== "void" && event.toState === "closed") {
            this.selectedTabUpdate.emit(undefined);
        }
    }

    private getChipFilters(flights: FlightList | undefined): ChipFilter<FlightFilterType>[] {
        if (!flights) {
            return [];
        }

        const chips: ChipFilter<FlightFilterType>[] = [];

        for (const [key, items] of Object.entries(flights.categorizedFlights).sort(
            ([left], [right]) =>
                ORDERED_FLIGHT_CATEGORIES.indexOf(left as FlightCategory) - ORDERED_FLIGHT_CATEGORIES.indexOf(right as FlightCategory)
        )) {
            const category = key as FlightCategory;

            if (AVAILABLE_CATEGORY_FILTERS.includes(category)) {
                chips.push(this.prepareFilterChip(category, "datsLibFlights.flightsPanel.categoryFilterValueLabel", items?.total));
            }
        }

        const operationType112FilterCount = flights.filters.Type112?.total;

        if (operationType112FilterCount) {
            chips.unshift(
                this.prepareFilterChip(
                    OperationTypeFilter.Type112,
                    "datsLibFlights.flightsPanel.operationTypeFilterValueLabel",
                    operationType112FilterCount
                )
            );
        }

        if (this.localStore.selectSnapshotByKey("selectedTabType") === FlightsTabType.TWR) {
            AVAILABLE_AIRSPACE_TYPE_FILTERS.forEach((airspaceType) => {
                const airspaceFilterCount =
                    flights.filters[airspaceType === AirspaceType.CTR ? AirspaceType.CTR : AirspaceType.MCTR]?.total;

                if (!airspaceFilterCount) {
                    return;
                }

                chips.push(
                    this.prepareFilterChip(airspaceType, "datsLibFlights.flightsPanel.airspaceTypeFilterValueLabel", airspaceFilterCount)
                );
            });
        }

        return chips;
    }

    private prepareFilterChip(
        value: FlightFilterType,
        valueLabelTranslationKey?: string,
        valueLabelParam?: number
    ): ChipFilter<FlightFilterType> {
        return {
            value,
            valueLabelTranslationKey,
            valueLabelParam: valueLabelParam,
        };
    }

    protected changeAppliedFilters(
        isExpanded: boolean,
        appliedFilters: FlightFilterType[],
        chipFilters: ChipFilter<FlightFilterType>[],
        category: FlightCategory
    ) {
        if (isExpanded && appliedFilters.length === 0) {
            return;
        }

        if (!isExpanded && appliedFilters.length === 0) {
            this.filtersChange.emit(chipFilters.map((chip) => chip.value).filter((filter) => filter !== category));

            return;
        }

        if (isExpanded && appliedFilters.length > 0 && !appliedFilters.includes(category)) {
            this.filtersChange.emit(chipFilters.length === appliedFilters.length + 1 ? [] : [...appliedFilters, category]);

            return;
        }

        if (!isExpanded && appliedFilters.length > 0 && appliedFilters.includes(category)) {
            this.filtersChange.emit(appliedFilters.filter((filter) => filter !== category));

            return;
        }
    }

    protected checkIfIsCategoryExpanded(appliedFilters: FlightFilterType[], category: FlightCategory, list?: FlightList): boolean {
        if (appliedFilters.length === 0) {
            return true;
        }

        return appliedFilters.some(
            (appliedFilter) => appliedFilter === category || list?.filters[appliedFilter]?.categories.includes(category)
        );
    }
}
