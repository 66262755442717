import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { FormUtils, ISO8601TimeDuration, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AlertType } from "../../../models/alert.models";
import { AlertTimeRange } from "../alert-time-range/alert-time-range.component";

interface AlertFormComponentState {
    templates: Record<string, string>;
    contentForms: Set<AbstractControl>;
}

export interface AlertFormGroup {
    type: FormControl<AlertType>;
    timeRange: FormControl<AlertTimeRange | null>;
    message: FormControl<string | null>;
}

const MAX_MESSAGE_LENGTH = 200;

@UntilDestroy()
@Component({
    selector: "dats-lib-alert-form",
    templateUrl: "./alert-form.component.html",
    styleUrls: ["./alert-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AlertFormComponent {
    @Input() public set alertType(value: AlertType) {
        this.alertForm.controls.type.setValue(value);
    }
    @Input({ required: true }) public set templates(value: Record<string, string>) {
        this.localStore.patchState({ templates: value });
    }
    @Input() public set contentForms(value: AbstractControl[]) {
        this.localStore.patchState({
            contentForms: new Set([this.alertForm, ...value]),
        });
    }

    @Output() public readonly cancel = new EventEmitter<void>();
    @Output() public readonly save = new EventEmitter<FormGroup<AlertFormGroup>>();

    protected readonly AlertType = AlertType;
    protected readonly MAX_MESSAGE_LENGTH = MAX_MESSAGE_LENGTH;

    protected readonly alertForm = new FormGroup<AlertFormGroup>({
        type: new FormControl<AlertType>(AlertType.NoFlyZone, { nonNullable: true }),
        message: new FormControl<string | null>(null, [Validators.maxLength(MAX_MESSAGE_LENGTH)]),
        timeRange: new FormControl<AlertTimeRange | null>(null, { nonNullable: true }),
    });

    protected readonly templates$ = this.localStore.selectByKey("templates");

    constructor(
        private readonly localStore: LocalComponentStore<AlertFormComponentState>,
        private readonly dialogService: DialogService,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({
            templates: {},
            contentForms: new Set([this.alertForm]),
        });
    }

    protected checkFormValidityAndSave(): void {
        const forms = Array.from(this.localStore.selectSnapshotByKey("contentForms").values());
        forms.forEach((form) => {
            form.updateValueAndValidity();
            form.markAllAsTouched();
        });

        if (forms.every((form) => form.valid)) {
            this.save.emit(this.alertForm);
        }
    }

    protected setMessageFromTemplate(templateContent: string) {
        this.alertForm.controls.message.setValue(templateContent);
    }

    protected setAlertType(type: AlertType) {
        if (type === AlertType.Message) {
            this.alertForm.controls.message.addValidators(FormUtils.trimmedRequiredValidator);
            this.alertForm.controls.message.updateValueAndValidity();
            this.alertForm.controls.message.markAsUntouched();
        } else {
            this.alertForm.controls.message.removeValidators(FormUtils.trimmedRequiredValidator);
            this.alertForm.controls.message.updateValueAndValidity();
            this.alertForm.controls.message.markAsTouched();
        }
    }

    protected setLimitedTimeRange(): void {
        this.alertForm.controls.timeRange.setValue({
            startDate: this.alertForm.controls.timeRange.value?.startDate ?? new Date(),
            duration: "PT1H" as ISO8601TimeDuration,
        });
    }

    protected tryCancelForm(): void {
        if (this.alertForm.controls.message.value !== null) {
            const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("datsLibAlert.alertForms.discardAlertDialogTitle"),
                    confirmationText: this.translocoService.translate("datsLibAlert.alertForms.discardAlertDialogConfirmationText"),
                    declineButtonLabel: this.translocoService.translate("datsLibAlert.alertForms.cancelDiscardLabel"),
                    confirmButtonLabel: this.translocoService.translate("datsLibAlert.alertForms.confirmDiscardLabel"),
                    theme: ButtonTheme.Warn,
                },
            });

            dialogRef
                .afterClosed()
                .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
                .subscribe(() => {
                    this.cancel.emit();
                });
        } else {
            this.cancel.emit();
        }
    }
}
