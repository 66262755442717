<ng-container *ngIf="alert$ | ngrxPush as alert">
    <dats-lib-action-sidebar-header (actionCancel)="actionCancel.emit()">
        {{ "datsLibAlert.alertForms.modifyAlertTimeForm.header" | transloco : { value: alert.type } }}
    </dats-lib-action-sidebar-header>
    <div class="wrapper">
        <section class="section">
            <dtm-ui-label-value
                [label]="'datsLibAlert.alertForms.modifyAlertTimeForm.sourceTypeLabel' | transloco : { value: alert.sourceType }"
                [value]="alert.title"
                *ngIf="alert.title"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'datsLibAlert.alertForms.modifyAlertTimeForm.templateLabel' | transloco"
                [value]="alert.templateId | systemTranslation"
                *ngIf="alert.templateId"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'datsLibAlert.alertForms.modifyAlertTimeForm.messageLabel' | transloco"
                [value]="alert.messageContent"
                *ngIf="alert.messageContent"
            ></dtm-ui-label-value>
        </section>
        <section class="section" *ngrxLet="isLimitedTimeRange$ as isLimitedTimeRange">
            <span class="sub-header">{{
                "datsLibAlert.alertForms.modifyAlertTimeForm.timeRangeHeader" | transloco : { value: alert.type }
            }}</span>
            <div *ngIf="alert.type === AlertBadgeType.Message">
                <div class="message-duration-header">
                    {{ "datsLibAlert.alertForms.modifyAlertTimeForm.messageTimeRangeHeader" | transloco }}
                </div>
                <dtm-ui-radio-tile-group class="message-duration-type-tiles" [value]="isLimitedTimeRange">
                    <dtm-ui-radio-tile [value]="false" (click)="setLimitedTimeRange(false)">
                        <p class="selectable-tile-label">
                            {{ "datsLibAlert.alertForms.untilCancelledTileLabel" | transloco }}
                        </p>
                    </dtm-ui-radio-tile>
                    <dtm-ui-radio-tile [value]="true" (click)="setLimitedTimeRange(true)">
                        <p class="selectable-tile-label">
                            {{ "datsLibAlert.alertForms.limitedTimeTileLabel" | transloco }}
                        </p>
                    </dtm-ui-radio-tile>
                </dtm-ui-radio-tile-group>
            </div>
            <dats-lib-alert-time-range
                *ngIf="isLimitedTimeRange"
                [formControl]="timeRangeControl"
                [isStartTimeDisabled]="(hasStarted$ | ngrxPush) ?? false"
                [oldStartTime]="alert.startAt"
                [oldEndTime]="alert.finishAt"
            >
            </dats-lib-alert-time-range>
        </section>
    </div>
    <dats-lib-action-sidebar-footer
        (actionCancel)="actionCancel.emit()"
        (actionConfirm)="tryEmitConfirm()"
        [confirmButtonText]="'datsLibAlert.alertForms.modifyAlertTimeForm.editButtonLabel' | transloco : { value: alert.type }"
    >
    </dats-lib-action-sidebar-footer>
</ng-container>
