<ng-container
    *ngrxLet="{
        selectedTabType: selectedTabType$,
        actionSidebarType: actionSidebarType$,
        isPanelProcessing: isPanelProcessing$,
        isAtcController: isAtcController$,
        flightList: flightList$,
        flightDetails: flightDetails$,
        tabs: tabs$,
        jurisdiction: jurisdiction$,
        jurisdictionMissions: jurisdictionMissions$,
        zoom: zoom$,
        selectedFlightId: selectedFlightId$,
        displayDetailsId: displayDetailsId$,
        hospitalList: hospitalList$,
        hemsBaseList: hemsBaseList$,
        appliedFilters: appliedFilters$,
        selectedAlertGeometrySourceType: selectedAlertGeometrySourceType$,
        heightFilter: heightFilter$,
        zoneAutocompletionList: zoneAutocompletionList$,
        isZoneAutocompletionProcessing: isZoneAutocompletionProcessing$,
        isManageAlertsFeatureAvailable: isManageAlertsFeatureAvailable$,
        isMaintainFlightsFeatureAvailable: isMaintainFlightsFeatureAvailable$,
        isControlAirTrafficFeatureAvailable: isControlAirTrafficFeatureAvailable$,
        isTestSystemFeatureAvailable: isTestSystemFeatureAvailable$,
        technicalCheckinInitialFormData: technicalCheckinInitialFormData$,
        templates: templates$,
        alerts: alerts$,
        alert: alert$,
        isAlertActionProcessing: isAlertActionProcessing$,
        isActionProcessing: isActionProcessing$,
        lastSelectedAlertPoi: lastSelectedAlertPoi$,
        lastSelectedAlertZone: lastSelectedAlertZone$,
        lastSelectedAlertType: lastSelectedAlertType$,
        grid: grid$,
        isClusteringEnabled: isClusteringEnabled$
    } as vm"
>
    <dtm-map-leaflet-map [mapOptions]="mapOptions" (mapClick)="selectFlight(undefined, 'map')">
        <dats-lib-flights-layer
            [flightList]="vm.flightList"
            [selectedFlightId]="vm.selectedFlightId"
            [heightFilter]="vm.heightFilter"
            [grid]="vm.grid"
            [isClusteringEnabled]="vm.isClusteringEnabled"
            (flightClick)="selectFlight($event, 'map')"
        ></dats-lib-flights-layer>

        <dats-lib-jurisdiction-layer [jurisdiction]="vm.jurisdiction" [zoom]="vm.zoom"></dats-lib-jurisdiction-layer>

        <dtm-map-leaflet-context-menu #leafletContextMenu *ngIf="vm.isTestSystemFeatureAvailable || vm.isManageAlertsFeatureAvailable">
            <mat-menu #contextMenuRoot="matMenu" dtmMapLeafletContextMenuRoot>
                <button
                    type="button"
                    class="technical-menu-item"
                    *ngIf="vm.isTestSystemFeatureAvailable"
                    mat-menu-item
                    (click)="openTechnicalCheckinForm(leafletContextMenu.coordinates)"
                >
                    <dtm-ui-icon name="add" />
                    {{ "datsLibFlights.flightsContainer.contextMenu.createTechnicalCheckinMenuItem" | transloco }}
                </button>
                <dats-lib-alert-zones-context-menu
                    *ngIf="vm.isManageAlertsFeatureAvailable"
                    [coordinates]="leafletContextMenu.coordinates"
                    (createAlert)="openZoneAlertForm($event.zone, $event.type)"
                />
            </mat-menu>
        </dtm-map-leaflet-context-menu>

        <ng-container mapControls>
            <button class="leaflet-button" type="button" (click)="zoomToJurisdiction()">
                <dtm-ui-icon name="home-2"></dtm-ui-icon>
            </button>
        </ng-container>
    </dtm-map-leaflet-map>
    <dats-lib-alert-geometry-panel
        [selectedAlertGeometrySourceType]="vm.selectedAlertGeometrySourceType"
        (alertGeometrySourceTypeChange)="changeAlertGeometrySourceType($event)"
    />
    <section class="sidebar" *ngIf="vm.isManageAlertsFeatureAvailable">
        <dats-lib-points-of-interest
            (favoriteHospitalsClick)="manageHospitalListPanel(true)"
            (favoriteHemsBasesClick)="manageHemsBaseListPanel(true)"
            (poiClick)="openPoiAlertForm($event)"
            [hospitalList]="vm.hospitalList"
            [hemsBaseList]="vm.hemsBaseList"
        ></dats-lib-points-of-interest>
        <dats-lib-alert-badges [alerts]="vm.alerts" (finishAlert)="finishAlert($event)" (modifyAlert)="openModifyAlertForm($event)" />
    </section>
    <section class="flights-panel-wrapper">
        <dats-lib-flights-panel
            [selectedTabType]="vm.selectedTabType"
            [isProcessing]="vm.isPanelProcessing"
            [flightList]="vm.flightList"
            [tabs]="vm.tabs"
            [jurisdictionMissions]="vm.jurisdictionMissions"
            [isAtcController]="vm.isAtcController"
            [appliedFilters]="vm.appliedFilters"
            [selectedFlightId]="vm.selectedFlightId"
            [displayDetailsId]="vm.displayDetailsId"
            [flightDetails]="vm.flightDetails"
            [isProcessing]="vm.isPanelProcessing"
            [isMaintainFlightsFeatureAvailable]="vm.isMaintainFlightsFeatureAvailable"
            [isControlAirTrafficFeatureAvailable]="vm.isControlAirTrafficFeatureAvailable"
            (selectedTabUpdate)="updateSelectedTab($event)"
            (checkinModify)="handleCheckinAction($event)"
            (confirmationResend)="resendConfirmation($event)"
            (selectedFlightChange)="selectFlight($event, 'list')"
            (filtersChange)="applyFilters($event)"
            (extendedMissionDataOpen)="loadExtendedMissionData($event)"
            (displayDetailsIdChanged)="handleDetailsDisplay($event)"
        ></dats-lib-flights-panel>
    </section>
    <section class="action-sidebar">
        <dats-lib-action-sidebar [isOpen]="vm.actionSidebarType" [isProcessing]="vm.isActionProcessing || vm.isAlertActionProcessing">
            <ng-container
                *ngrxLet="{
                    checkinAction: checkinAction$,
                    flight: flight$
                } as sidebar"
            >
                <dats-lib-standby-checkin-form
                    *ngIf="vm.actionSidebarType === ActionSidebarType.Checkin && sidebar.checkinAction.type === CheckinActionType.Standby"
                    [flight]="sidebar.flight"
                    (actionCancel)="tryToCloseActionSidebar()"
                    (actionConfirm)="delayOrRejectCheckin($event)"
                    (isFormChanged)="markFormAsChanged($event)"
                ></dats-lib-standby-checkin-form>
                <dats-lib-modify-checkin-form
                    *ngIf="vm.actionSidebarType === ActionSidebarType.Checkin && sidebar.checkinAction.type === CheckinActionType.Modify"
                    [flight]="sidebar.flight"
                    (actionCancel)="tryToCloseActionSidebar()"
                    (actionConfirm)="modifyCheckin($event)"
                    (isFormChanged)="markFormAsChanged($event)"
                ></dats-lib-modify-checkin-form>
            </ng-container>

            <ng-container *ngIf="vm.actionSidebarType === ActionSidebarType.Alert && vm.isManageAlertsFeatureAvailable">
                <dats-lib-zone-alert-form
                    *ngIf="vm.selectedAlertGeometrySourceType === AlertGeometrySourceType.Zone"
                    [templates]="vm.templates"
                    [selectedAlertZone]="vm.lastSelectedAlertZone"
                    [selectedAlertType]="vm.lastSelectedAlertType"
                    [zoneAutocompletionList]="vm.zoneAutocompletionList"
                    [isZoneAutocompletionProcessing]="vm.isZoneAutocompletionProcessing"
                    (actionCancel)="tryToCloseActionSidebar()"
                    (actionConfirm)="createAlert($event)"
                    (isFormChanged)="markFormAsChanged($event)"
                    (messageTemplatesQueryParamsChange)="getAlertProposal($event)"
                    (zoneSearchTextChange)="searchZones($event)"
                />
                <dats-lib-hospital-alert-form
                    *ngIf="vm.selectedAlertGeometrySourceType === AlertGeometrySourceType.Hospital"
                    [hospitals]="vm.hospitalList"
                    [templates]="vm.templates"
                    [selectedPoi]="
                        vm.lastSelectedAlertPoi && vm.lastSelectedAlertPoi.type === PoiType.Hospital ? vm.lastSelectedAlertPoi : undefined
                    "
                    [selectedAlertType]="vm.lastSelectedAlertType"
                    (actionCancel)="tryToCloseActionSidebar()"
                    (actionConfirm)="createAlert($event)"
                    (isFormChanged)="markFormAsChanged($event)"
                    (messageTemplatesQueryParamsChange)="getAlertProposal($event)"
                />
                <dats-lib-hems-alert-form
                    *ngIf="vm.selectedAlertGeometrySourceType === AlertGeometrySourceType.HEMS"
                    [hemsBases]="vm.hemsBaseList"
                    [templates]="vm.templates"
                    [selectedPoi]="
                        vm.lastSelectedAlertPoi && vm.lastSelectedAlertPoi.type === PoiType.Hems ? vm.lastSelectedAlertPoi : undefined
                    "
                    [selectedAlertType]="vm.lastSelectedAlertType"
                    (actionCancel)="tryToCloseActionSidebar()"
                    (actionConfirm)="createAlert($event)"
                    (isFormChanged)="markFormAsChanged($event)"
                    (messageTemplatesQueryParamsChange)="getAlertProposal($event)"
                />
            </ng-container>

            <ng-container *ngIf="vm.actionSidebarType === ActionSidebarType.ModifyAlert && vm.alert">
                <dats-lib-modify-alert-time-form
                    [alert]="vm.alert"
                    (actionCancel)="tryToCloseActionSidebar()"
                    (actionConfirm)="modifyAlert($event)"
                />
            </ng-container>

            <dats-lib-bookmarked-poi-list
                *ngIf="vm.actionSidebarType === ActionSidebarType.Hospital"
                [poiList]="vm.hospitalList"
                [headerText]="'datsLibFlights.flightsContainer.hospitalsSidebarTitle' | transloco"
                (actionCancel)="closeActionSidebar()"
                (actionConfirm)="dispatchHospitalList($event)"
            ></dats-lib-bookmarked-poi-list>

            <dats-lib-bookmarked-poi-list
                *ngIf="vm.actionSidebarType === ActionSidebarType.HemsBase"
                [poiList]="vm.hemsBaseList"
                [headerText]="'datsLibFlights.flightsContainer.hemsBasesSidebarTitle' | transloco"
                (actionCancel)="closeActionSidebar()"
                (actionConfirm)="dispatchHemsBaseList($event)"
            ></dats-lib-bookmarked-poi-list>

            <dats-lib-create-technical-checkin-form
                *ngIf="vm.actionSidebarType === ActionSidebarType.CreateTechnicalCheckin && vm.isTestSystemFeatureAvailable"
                [initialFormData]="vm.technicalCheckinInitialFormData"
                (actionConfirm)="createTechnicalCheckin($event)"
                (actionCancel)="tryToCloseActionSidebar()"
                (isFormChanged)="markFormAsChanged($event)"
            />
        </dats-lib-action-sidebar>
    </section>
</ng-container>
