import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { HOURS_IN_DAY, LocalComponentStore, MILLISECONDS_IN_HOUR } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { LetDirective } from "@ngrx/component";
import { combineLatest, map } from "rxjs";
import { JurisdictionMission, MissionType } from "../../models/flight.models";
import { ChipFilter } from "../../models/flights-filters.models";
import { FlightsFiltersComponent } from "../flights-filters/flights-filters.component";
import { MissionItemComponent } from "../mission-item/mission-item.component";

interface MissionListComponentState {
    missions: JurisdictionMission[];
    missionTypeOptions: ChipFilter<MissionType>[];
    appliedTimeFilters: FilterTimeOption[];
    appliedMissionTypeFilters: MissionType[];
}

// eslint-disable-next-line no-magic-numbers
const FILTER_TIME_OPTIONS = [HOURS_IN_DAY, HOURS_IN_DAY / 2, HOURS_IN_DAY / 3] as const;
type FilterTimeOption = (typeof FILTER_TIME_OPTIONS)[number];

@Component({
    selector: "dats-lib-mission-list",
    standalone: true,
    imports: [CommonModule, SharedUiModule, MissionItemComponent, FlightsFiltersComponent, TranslocoModule, LetDirective],
    templateUrl: "./mission-list.component.html",
    styleUrls: ["./mission-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionListComponent {
    @Input({ required: true }) public set missions(value: JurisdictionMission[]) {
        this.localStore.patchState({
            missions: value,
            missionTypeOptions: this.prepareMissionTypeOptions(value),
        });
    }

    @Output() protected readonly extendedMissionDataOpen = new EventEmitter<string>();

    protected readonly filterTimeOptions = FILTER_TIME_OPTIONS.map((value) => this.prepareFilterChip(value));
    protected readonly missions$ = this.localStore.selectByKey("missions");
    protected readonly appliedTimeFilters$ = this.localStore.selectByKey("appliedTimeFilters");
    protected readonly appliedMissionTypeFilters$ = this.localStore.selectByKey("appliedMissionTypeFilters");
    protected readonly missionTypeOptions$ = this.localStore.selectByKey("missionTypeOptions");

    protected readonly filteredMissions$ = combineLatest([this.missions$, this.appliedMissionTypeFilters$, this.appliedTimeFilters$]).pipe(
        map(([missions, appliedMissionTypeFilters, appliedTimeFilters]) =>
            this.filterMissions(missions, appliedMissionTypeFilters, appliedTimeFilters)
        )
    );

    constructor(private readonly localStore: LocalComponentStore<MissionListComponentState>) {
        this.localStore.setState({
            missions: [],
            missionTypeOptions: [],
            appliedTimeFilters: [],
            appliedMissionTypeFilters: [],
        });
    }

    protected trackByMissionId(index: number, mission: JurisdictionMission): string {
        return mission.missionId;
    }

    protected prepareMissionTypeOptions(missions: JurisdictionMission[]): ChipFilter<MissionType>[] {
        return Object.values(MissionType).map((type) => ({
            value: type,
            valueLabelTranslationKey: "datsLibFlights.missionList.typeOptionLabel",
            valueLabelParam: missions.filter((mission) => mission.type === type).length,
        }));
    }

    protected applyMissionTypeFilters(appliedMissionTypeFilters: MissionType[]) {
        this.localStore.patchState({
            appliedMissionTypeFilters,
        });
    }

    protected applyTimeFilters(appliedTimeFilters: FilterTimeOption[]) {
        this.localStore.patchState({
            appliedTimeFilters,
        });
    }

    private filterMissions(
        missions: JurisdictionMission[],
        appliedMissionTypeFilters: MissionType[],
        appliedTimeFilters: FilterTimeOption[]
    ): JurisdictionMission[] {
        if (appliedMissionTypeFilters.length > 0) {
            missions = missions.filter((mission) => appliedMissionTypeFilters.includes(mission.type));
        }
        if (appliedTimeFilters.length > 0) {
            missions = missions.filter(
                (mission) => mission.startDateTime.getTime() < new Date(Date.now() + appliedTimeFilters[0] * MILLISECONDS_IN_HOUR).getTime()
            );
        }

        return missions;
    }

    private prepareFilterChip(value: FilterTimeOption): ChipFilter<FilterTimeOption> {
        return {
            value,
            valueLabelTranslationKey: "datsLibFlights.missionList.timeOptionLabel",
            valueLabelParam: value,
        };
    }
}
