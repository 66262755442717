import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ButtonTheme, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoPipe } from "@jsverse/transloco";
import { LetDirective } from "@ngrx/component";

interface ActionSidebarFooterComponentState {
    confirmButtonText: string | undefined;
    confirmButtonTheme: ButtonTheme;
}

@Component({
    selector: "dats-lib-action-sidebar-footer",
    standalone: true,
    imports: [CommonModule, SharedUiModule, LetDirective, TranslocoPipe],
    templateUrl: "./action-sidebar-footer.component.html",
    styleUrls: ["./action-sidebar-footer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ActionSidebarFooterComponent {
    @Input() public set confirmButtonText(value: string | undefined) {
        this.localStore.patchState({ confirmButtonText: value });
    }
    @Input() public set confirmButtonTheme(value: ButtonTheme | undefined) {
        this.localStore.patchState({ confirmButtonTheme: value });
    }
    @Output() public readonly actionConfirm = new EventEmitter<void>();
    @Output() public readonly actionCancel = new EventEmitter<void>();

    protected readonly confirmButtonType$ = this.localStore.selectByKey("confirmButtonTheme");
    protected readonly confirmButtonText$ = this.localStore.selectByKey("confirmButtonText");

    protected readonly ButtonTheme = ButtonTheme;

    constructor(private readonly localStore: LocalComponentStore<ActionSidebarFooterComponentState>) {
        this.localStore.setState({
            confirmButtonText: undefined,
            confirmButtonTheme: ButtonTheme.Primary,
        });
    }
}
