<ng-container
    *ngrxLet="{
        isStartTimeTomorrow: isStartTimeTomorrow$,
        isEndTimeEarlierThanStartTime: isEndTimeEarlierThanStartTime$,
        isEndTimeDayAfterTomorrow: isEndTimeDayAfterTomorrow$,
        minimumStartTimeConstraint: minimumStartTimeConstraint$,
        oldStartTime: oldStartTime$,
        oldEndTime: oldEndTime$,
    } as vm"
>
    <form class="form-content">
        <div class="time-fields-container">
            <dtm-ui-time-field required [isUtcTime]="true" [formControl]="startTimeControl" [referenceDate]="vm.minimumStartTimeConstraint">
                <label>{{ "datsLibAlert.alertTimeRange.startLabel" | transloco }}</label>
                <div class="field-hint old-time" *ngIf="vm.oldStartTime">
                    <span *ngIf="vm.oldEndTime && (vm.oldStartTime | invoke : isTimesDifferent : startTimeControl.value)">
                        {{ vm.oldStartTime | localizeDate : { timeStyle: "short", timeZone: "UTC" } }}
                    </span>
                    <span *ngIf="!vm.oldEndTime">
                        {{ "datsLibAlert.alertTimeRange.untilCancelledLabel" | transloco }}
                    </span>
                </div>
                <span class="field-hint" *ngIf="vm.isStartTimeTomorrow">
                    {{
                        "datsLibAlert.alertTimeRange.tomorrowTimeLabel"
                            | transloco
                                : {
                                      time:
                                          startTimeControl.value
                                          | invoke : getDateShiftedByDays : 1
                                          | localizeDate
                                              : {
                                                    dateStyle: "short",
                                                    timeStyle: "short",
                                                    timeZone: "UTC"
                                                }
                                  }
                    }}
                </span>
                <div class="field-error" *dtmUiFieldHasError="startTimeControl; name: 'required'">
                    {{ "datsLibAlert.requiredFieldErrorMessage" | transloco }}
                </div>
            </dtm-ui-time-field>
            <dtm-ui-time-field required [isUtcTime]="true" [formControl]="endTimeControl" [referenceDate]="vm.minimumStartTimeConstraint">
                <label>{{ "datsLibAlert.alertTimeRange.endLabel" | transloco }}</label>
                <div
                    class="field-hint old-time"
                    *ngIf="vm.oldEndTime && (vm.oldEndTime | invoke : isTimesDifferent : endTimeControl.value)"
                >
                    {{ vm.oldEndTime | localizeDate : { timeStyle: "short", timeZone: "UTC" } }}
                </div>
                <span class="field-hint" *ngIf="vm.isStartTimeTomorrow || vm.isEndTimeEarlierThanStartTime || vm.isEndTimeDayAfterTomorrow">
                    {{
                        (vm.isEndTimeDayAfterTomorrow
                            ? "datsLibAlert.alertTimeRange.dayAfterTomorrowTimeLabel"
                            : "datsLibAlert.alertTimeRange.tomorrowTimeLabel"
                        )
                            | transloco
                                : {
                                      time:
                                          endTimeControl.value
                                          | invoke : getDateShiftedByDays : (vm.isEndTimeDayAfterTomorrow ? 2 : 1)
                                          | localizeDate
                                              : {
                                                    dateStyle: "short",
                                                    timeStyle: "short",
                                                    timeZone: "UTC"
                                                }
                                  }
                    }}
                </span>
                <div class="field-error" *dtmUiFieldHasError="endTimeControl; name: 'required'">
                    {{ "datsLibAlert.requiredFieldErrorMessage" | transloco }}
                </div>
            </dtm-ui-time-field>
        </div>

        <dtm-ui-expanded-number-input
            [formControl]="durationMinutesControl"
            [minValue]="1"
            [maxValue]="MAX_DURATION_MINUTES"
            [stepValue]="1"
            [valueSuffix]="'datsLibAlert.alertTimeRange.minutesSuffix' | transloco"
        >
            <label>{{ "datsLibAlert.alertTimeRange.durationLabel" | transloco }}</label>
            <div class="field-hint" *ngIf="durationMinutesControl.value && durationMinutesControl.value >= MINUTES_IN_HOUR">
                {{ durationMinutesControl.value | invoke : getISO8601TimeDuration | duration : "short" }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="durationMinutesControl; name: 'min'; error as error">
                {{ "datsLibAlert.alertTimeRange.durationMinErrorMessage" | transloco : { min: error.min.min } }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="durationMinutesControl; name: 'max'; error as error">
                {{
                    "datsLibAlert.alertTimeRange.durationMaxErrorMessage"
                        | transloco
                            : {
                                  max: error.max,
                                  textual:
                                      error.max <= MINUTES_IN_HOUR
                                          ? 0
                                          : (error.max | invoke : getISO8601TimeDuration | duration : "short" : "")
                              }
                }}
            </div>
        </dtm-ui-expanded-number-input>
    </form>
</ng-container>
