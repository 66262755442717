import { WGS84Coordinates } from "@dtm-frontend/shared/map/leaflet";
import { AlertTimeRange } from "../components/alert-forms/alert-time-range/alert-time-range.component";
import { AlertGeometrySourceType, AlertPointOfInterest, AlertProposalQueryParams, LocationAlertEntity } from "../models/alert.models";

export namespace AlertActions {
    export class SelectAlertGeometrySourceType {
        public static readonly type = "[Alert] Select alert type";
        constructor(public type: AlertGeometrySourceType | undefined) {}
    }

    export class GetAlerts {
        public static readonly type = "[Alert] Get alerts";
    }

    export class GetAlertProposal {
        public static readonly type = "[Alert] Get alert proposal";
        constructor(public params: AlertProposalQueryParams) {}
    }

    export class CreateAlert {
        public static readonly type = "[Alert] Create alert";
        constructor(public alertEntity: LocationAlertEntity) {}
    }

    export class ModifyAlert {
        public static readonly type = "[Alert] Modify alert";
        constructor(public alertId: string, public timeRange: AlertTimeRange | undefined) {}
    }

    export class FinishAlert {
        public static readonly type = "[Alert] Finish alert";
        constructor(public alertId: string) {}
    }

    export class GetAllHospitals {
        public static readonly type = "[Alert] Get all hospitals";
    }

    export class GetAllHemsBases {
        public static readonly type = "[Alert] Get all HEMS bases";
    }

    export class GetAllBookmarkedPois {
        public static readonly type = "[Alert] Get all bookmarked POIs";
    }

    export class SetBookmarkedHospitals {
        public static readonly type = "[Alert] Set bookmarked hospitals";
        constructor(public newBookmarkedHospitals: AlertPointOfInterest[]) {}
    }

    export class GetZoneAutocompletion {
        public static readonly type = "[Alert] Get zone autocompletion";
        constructor(public value: string) {}
    }

    export class SetBookmarkedHemsBases {
        public static readonly type = "[Alert] Set bookmarked hems bases";
        constructor(public newBookmarkedHemsBases: AlertPointOfInterest[]) {}
    }

    export class GetZonesForCoordinates {
        public static readonly type = "[Alert] Get zones for EPSG:4326 coordinates";
        constructor(public coordinates: WGS84Coordinates) {}
    }
}
