<ng-container *ngrxLet="{ zones: zones$, templates: templates$, selectedType: selectedType$ } as vm">
    <dats-lib-action-sidebar-header
        [headerText]="'datsLibAlert.alertForms.airspaceAlertForm.header' | transloco"
        (actionCancel)="actionCancel.emit()"
    >
    </dats-lib-action-sidebar-header>
    <dats-lib-alert-form class="action-sidebar-content scroll-shadows" [templates]="vm.templates">
        <dtm-ui-select-field
            contentSlot
            [placeholder]="'datsLibAlert.alertForms.selectPlaceholder' | transloco"
            [formControl]="zoneFormControl"
            [isClearable]="false"
        >
            <label>{{ "datsLibAlert.alertForms.airspaceAlertForm.zoneLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let zone of vm.zones" [value]="zone">
                {{ zone.title }}
                <span hint *ngIf="zone.description">{{ zone.description }}</span>
            </dtm-ui-select-option>

            <div class="field-error" *dtmUiFieldHasError="zoneFormControl; name: 'required'">
                {{ "datsLibAlert.requiredFieldErrorMessage" | transloco }}
            </div>
        </dtm-ui-select-field>
    </dats-lib-alert-form>
    <dats-lib-action-sidebar-footer
        (actionCancel)="actionCancel.emit()"
        (actionConfirm)="tryEmitConfirm()"
        [confirmButtonText]="'datsLibAlert.alertForms.saveButtonLabel' | transloco : { value: vm.selectedType }"
    >
    </dats-lib-action-sidebar-footer>
</ng-container>
