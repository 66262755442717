<div class="actions">
    <button type="button" class="button-icon reject" (click)="checkinModify.emit(CheckinActionType.Standby); $event.stopPropagation()">
        <dtm-ui-icon name="close-circle-fill"></dtm-ui-icon>
    </button>
    <button type="button" class="button-icon" (click)="checkinModify.emit(CheckinActionType.Modify); $event.stopPropagation()">
        <dtm-ui-icon name="equalizer-fill"></dtm-ui-icon>
    </button>
    <button type="button" class="button-icon approve" (click)="checkinModify.emit(CheckinActionType.Approve); $event.stopPropagation()">
        <dtm-ui-icon name="checkbox-circle-fill"></dtm-ui-icon>
    </button>
</div>
