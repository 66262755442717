<div class="container" *ngrxLet="{ isExpanded: isExpanded$, mission: mission$ } as vm">
    <div class="header" (click)="togglePanel(!vm.isExpanded)">
        <div class="row operator">
            <div class="row-item"><dtm-ui-icon name="user"></dtm-ui-icon> {{ vm.mission.operatorName }}</div>
            <div class="row-item">
                <div class="chip default">
                    {{ "datsLibFlights.missionItem.missionTypeLabel" | transloco : { value: vm.mission.type } }}
                </div>
                <button type="button" class="button-icon" (click)="openMissionDetails(); $event.stopPropagation()">
                    <dtm-ui-icon name="information-fill"></dtm-ui-icon>
                </button>
                <button
                    class="button-icon small"
                    type="button"
                    [attr.aria-label]="'dtmUi.expandButtonArialLabel' | transloco : { isExpanded: vm.isExpanded }"
                    [attr.aria-expanded]="vm.isExpanded"
                >
                    <dtm-ui-icon [name]="vm.isExpanded ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
                </button>
            </div>
        </div>
        <div class="row">
            <div
                class="row-item"
                [matTooltip]="vm.mission.startDateTime | localizeDate : { timeZoneName: 'short', hour: '2-digit', minute: '2-digit' }"
            >
                <dtm-ui-icon name="calendar-check-fill" class="success"></dtm-ui-icon>
                <div class="date">
                    <div>
                        {{
                            "datsLibFlights.timeHeightDisplay.fromLabel"
                                | transloco : { value: vm.mission.startDateTime | localizeDate : { dateStyle: "short", timeZone: "UTC" } }
                        }}
                    </div>
                    <span class="time">{{ vm.mission.startDateTime | localizeDate : { timeStyle: "short", timeZone: "UTC" } }}</span>
                </div>
            </div>
            <div
                class="row-item"
                [matTooltip]="vm.mission.endDateTime | localizeDate : { timeZoneName: 'short', hour: '2-digit', minute: '2-digit' }"
            >
                <dtm-ui-icon name="calendar-close-fill" class="error"></dtm-ui-icon>
                <div class="date">
                    <div>
                        {{
                            "datsLibFlights.timeHeightDisplay.toLabel"
                                | transloco : { value: vm.mission.endDateTime | localizeDate : { dateStyle: "short", timeZone: "UTC" } }
                        }}
                    </div>
                    <span class="time">{{ vm.mission.endDateTime | localizeDate : { timeStyle: "short", timeZone: "UTC" } }}</span>
                </div>
            </div>
            <div class="row-item">
                <dtm-ui-icon name="complex-top-height" class="height-icon"></dtm-ui-icon>
                <span class="height-label">{{
                    "datsLibFlights.timeHeightDisplay.maxHeightLabel" | transloco : { value: vm.mission.maxHeight | localizeNumber }
                }}</span>
            </div>
        </div>
    </div>
    <div class="content" *ngIf="vm.isExpanded" @slideInWithMargin>
        <div class="row" *ngIf="vm.mission.uavWeight || vm.mission.flightArea || vm.mission.flightCategory">
            <div
                class="row-item"
                *ngIf="vm.mission.uavWeight"
                [matTooltip]="vm.mission.uavName ?? ''"
                [matTooltipDisabled]="!vm.mission.uavName"
            >
                <dtm-ui-icon name="drone"></dtm-ui-icon>
                {{ "datsLibFlights.uavInfo.uavMassLimitLabel" | transloco : { value: (vm.mission.uavWeight | uavMassLimit) } }}
            </div>
            <div class="row-item" *ngIf="vm.mission.flightArea?.length !== undefined || vm.mission.flightArea?.maxRadius !== undefined">
                <dtm-ui-icon [name]="vm.mission.flightArea?.length ? 'polygon' : 'complex-radius'"></dtm-ui-icon>
                {{
                    "datsLibFlights.missionItem.radiusLabel"
                        | transloco
                            : {
                                  value:
                                      vm.mission.flightArea?.length ?? vm.mission.flightArea?.maxRadius
                                      | localizeNumber : { maximumFractionDigits: 0 }
                              }
                }}
            </div>
            <div class="row-item" *ngIf="vm.mission.flightCategory">
                <dtm-ui-icon name="book-cover"></dtm-ui-icon>
                {{ "datsLibFlights.missionItem.missionFlightCategoryLabel" | transloco : { value: vm.mission.flightCategory } }}
            </div>
        </div>
        <div class="row additional-info" *ngIf="vm.mission.additionalInfo">
            <div class="row-item">
                <dtm-ui-icon name="message"></dtm-ui-icon>
                {{ vm.mission.additionalInfo }}
            </div>
        </div>
        <span *ngIf="vm.mission | invoke : checkIfExtendedMissionDataEmpty">
            {{ "datsLibFlights.missionItem.extendedDataEmptyLabel" | transloco }}
        </span>
    </div>
</div>
