import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { ActionSidebarFooterComponent, ActionSidebarHeaderComponent, SharedModule } from "../shared";
import { AlertBadgesComponent } from "./components/alert-badges/alert-badges.component";
import { AirspaceAlertFormComponent } from "./components/alert-forms/airspace-alert-form/airspace-alert-form.component";
import { AlertFormComponent } from "./components/alert-forms/alert-form/alert-form.component";
import { AlertTimeRangeComponent } from "./components/alert-forms/alert-time-range/alert-time-range.component";
import { HemsAlertFormComponent } from "./components/alert-forms/hems-alert-form/hems-alert-form.component";
import { HospitalAlertFormComponent } from "./components/alert-forms/hospital-alert-form/hospital-alert-form.component";
import { AlertGeometryPanelComponent } from "./components/alert-geometry-panel/alert-geometry-panel.component";
import { AlertSelectButtonComponent } from "./components/alert-select-button/alert-select-button.component";
import { AlertsListComponent } from "./components/alerts-list/alerts-list.component";
import { AlertApiService } from "./services/alert-api.service";
import { AlertState } from "./state/alert.state";

@NgModule({
    imports: [
        CommonModule,
        LetModule,
        MatSlideToggleModule,
        FormsModule,
        PushModule,
        MatTooltipModule,
        SharedUiModule,
        SharedI18nModule,
        MatMenuModule,
        MatButtonModule,
        MatDialogModule,
        NgxsModule.forFeature([AlertState]),
        IconDirective,
        ReactiveFormsModule,
        MatInputModule,
        SharedModule,
        ActionSidebarHeaderComponent,
        ActionSidebarFooterComponent,
    ],
    declarations: [
        AlertBadgesComponent,
        AlertGeometryPanelComponent,
        AlertSelectButtonComponent,
        AlertsListComponent,
        AlertFormComponent,
        AlertTimeRangeComponent,
        AirspaceAlertFormComponent,
        HemsAlertFormComponent,
        HospitalAlertFormComponent,
    ],
    exports: [
        AlertBadgesComponent,
        AlertGeometryPanelComponent,
        AlertSelectButtonComponent,
        AlertsListComponent,
        AlertFormComponent,
        AlertTimeRangeComponent,
        AirspaceAlertFormComponent,
        HemsAlertFormComponent,
        HospitalAlertFormComponent,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "datsLibAlert",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class AlertModule {
    public static forRoot(): ModuleWithProviders<AlertModule> {
        return {
            ngModule: AlertModule,
            providers: [AlertApiService],
        };
    }

    public static forTest(): ModuleWithProviders<AlertModule> {
        return {
            ngModule: AlertModule,
            providers: [
                {
                    provide: AlertApiService,
                    useValue: null,
                },
            ],
        };
    }
}
