import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { WGS84Coordinates } from "@dtm-frontend/shared/map/leaflet";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { EMPTY, Observable, catchError, map, switchMap, throwError } from "rxjs";
import { ALERT_ENDPOINTS, AlertEndpoints } from "../alert.tokens";
import { AlertTimeRange } from "../components/alert-forms/alert-time-range/alert-time-range.component";
import {
    Alert,
    AlertErrorType,
    AlertPointOfInterest,
    AlertPointOfInterestWithPages,
    AlertProposal,
    AlertType,
    LocationAlertEntity,
    PoiType,
    Zone,
} from "../models/alert.models";
import {
    GetPoiListResponseBody,
    PageableResponseBody,
    categorizeAlertsByBadge,
    convertAlertResponseBodyToAlert,
    convertAlertTimeRangeToUpdateAlertRequestPayload,
    convertGetPoiListResponseBodyToPoiWithPages,
    convertLocationAlertEntityToCreateAlertRequestPayload,
} from "./alert-api.converters";

const ZONE_AUTOCOMPLETION_RESULT_LIMIT = 20;

@Injectable({
    providedIn: "root",
})
export class AlertApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(ALERT_ENDPOINTS) private readonly endpoints: AlertEndpoints) {}

    public getAlerts() {
        return this.httpClient.get<{ alerts: PageableResponseBody<Alert> }>(this.endpoints.manageAlerts).pipe(
            map((response) => categorizeAlertsByBadge(response.alerts.content.map(convertAlertResponseBodyToAlert))),
            catchError((error) => throwError(() => ({ type: AlertErrorType.CannotGetAlerts, error })))
        );
    }

    public createAlert(alertData: LocationAlertEntity): Observable<void> {
        return this.httpClient
            .post<void>(this.endpoints.manageAlerts, convertLocationAlertEntityToCreateAlertRequestPayload(alertData))
            .pipe(catchError((error) => throwError(() => ({ type: AlertErrorType.CannotCreateLocationAlert, error }))));
    }

    public updateAlert(id: string, timeRange: AlertTimeRange | undefined): Observable<void> {
        return this.httpClient
            .put<void>(
                StringUtils.replaceInTemplate(this.endpoints.alertManagement, { id }),
                convertAlertTimeRangeToUpdateAlertRequestPayload(timeRange)
            )
            .pipe(catchError((error) => throwError(() => ({ type: AlertErrorType.CannotModifyLocationAlert, error }))));
    }

    public finishAlert(alertId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.alertManagement, { id: alertId }))
            .pipe(catchError((error) => throwError(() => ({ type: AlertErrorType.CannotFinishAlert, error }))));
    }

    public getAlertProposal(type?: AlertType, poiId?: string, designator?: string): Observable<AlertProposal> {
        let params: HttpParams = new HttpParams();

        if (type) {
            params = params.append("type", type);
        }

        if (poiId) {
            params = params.append("poiId", poiId);
        }

        if (designator) {
            params = params.append("designator", designator);
        }

        return this.httpClient
            .put<AlertProposal>(this.endpoints.getAlertProposal, { params })
            .pipe(catchError((error) => throwError(() => ({ type: AlertErrorType.CannotGetAlertProposal, error }))));
    }

    public getPoiList(shouldGetOnlyBookmarked: boolean, poiType?: PoiType): Observable<AlertPointOfInterestWithPages> {
        let params: HttpParams = new HttpParams();

        if (shouldGetOnlyBookmarked) {
            params = params.append("bookmarked", shouldGetOnlyBookmarked);
        }

        if (poiType) {
            params = params.append("type", poiType);
        }

        return this.httpClient.get<GetPoiListResponseBody>(this.endpoints.managePoiList, { params }).pipe(
            catchError(() =>
                // switchMap(() =>
                // TODO: DTATS-347 - Remove this workaround once the backend is fixed
                this.httpClient
                    .post<GetPoiListResponseBody>(this.endpoints.managePoiList, {}, { params })
                    .pipe(switchMap(() => this.httpClient.get<GetPoiListResponseBody>(this.endpoints.managePoiList, { params })))
            ),
            map((response) => convertGetPoiListResponseBodyToPoiWithPages(response.items)),
            catchError((error) => throwError(() => ({ type: AlertErrorType.CannotGetPointsOfInterest, error })))
        );
    }

    public setBookmarkedHospitals(bookmarkedHospitals: AlertPointOfInterest[], newBookmarkedHospitals: AlertPointOfInterest[]) {
        const payload = this.createBookmarkedPoiRequestPayload(bookmarkedHospitals, newBookmarkedHospitals);

        if (!payload.addBookmarks.length && !payload.removeBookmarks.length) {
            return EMPTY;
        }

        return this.httpClient
            .put<void>(this.endpoints.managePoiList, payload)
            .pipe(catchError((error) => throwError(() => ({ type: AlertErrorType.CannotBookmarkHospitals, error }))));
    }

    public setBookmarkedHemsBases(bookmarkedHemsBases: AlertPointOfInterest[], newBookmarkedHemsBases: AlertPointOfInterest[]) {
        const payload = this.createBookmarkedPoiRequestPayload(bookmarkedHemsBases, newBookmarkedHemsBases);

        if (!payload.addBookmarks.length && !payload.removeBookmarks.length) {
            return EMPTY;
        }

        return this.httpClient
            .put<void>(this.endpoints.managePoiList, payload)
            .pipe(catchError((error) => throwError(() => ({ type: AlertErrorType.CannotBookmarkHemsBases, error }))));
    }

    public getZoneAutocompletion(query: string): Observable<Zone[]> {
        return this.httpClient
            .get<{ items: Zone[] }>(this.endpoints.zoneAutocompletion, {
                params: { query, limit: ZONE_AUTOCOMPLETION_RESULT_LIMIT },
            })
            .pipe(
                map((response) => response.items),
                catchError((error) => throwError(() => ({ type: AlertErrorType.CannotGetZoneAutocompletion, error })))
            );
    }
    public getZonesForWGS84Coordinates(coordinates: WGS84Coordinates): Observable<Zone[]> {
        // TODO: DTATS-352 - waiting for backend implementation

        return throwError(() => ({ type: AlertErrorType.CannotGetZonesForCoordinates, error: "Not yet implemented" }));
    }

    private createBookmarkedPoiRequestPayload(bookmarkedPois: AlertPointOfInterest[], newBookmarkedPois: AlertPointOfInterest[]) {
        const addBookmarks = newBookmarkedPois
            .filter(
                (newBookmarkedPoi) =>
                    newBookmarkedPoi.isBookmarked &&
                    !bookmarkedPois.some((bookmarkedPoi) => bookmarkedPoi.id === newBookmarkedPoi.id && bookmarkedPoi.isBookmarked)
            )
            .map((poi) => poi.id);

        const removeBookmarks = bookmarkedPois
            .filter(
                (bookmarkedPoi) =>
                    bookmarkedPoi.isBookmarked &&
                    !newBookmarkedPois.some((newBookmarkedPoi) => newBookmarkedPoi.id === bookmarkedPoi.id && newBookmarkedPoi.isBookmarked)
            )
            .map((poi) => poi.id);

        return { addBookmarks, removeBookmarks };
    }
}
