<ng-container *ngrxLet="uav$ as uav">
    <span
        [matTooltip]="
            (uav.type ? 'datsLibFlights.uavInfo.tooltip' : 'datsLibFlights.uavInfo.tooltipNotAvailable')
                | transloco : { type: uav.type, model: uav.model, isSwarm: uav.isSwarm }
        "
    >
        <ng-container *ngIf="uav.isSwarm; else singleUavTemplate">
            <dtm-ui-icon name="drone-swarm"></dtm-ui-icon>
        </ng-container>
    </span>
    <ng-template #singleUavTemplate>
        <dtm-ui-icon
            *ngIf="uav.type === UavType.Airplane || uav.type === UavType.VerticalTakeOffAndLanding; else defaultDroneIconTemplate"
            name="flyeye"
        ></dtm-ui-icon>
    </ng-template>
    <ng-template #defaultDroneIconTemplate>
        <dtm-ui-icon name="drone"></dtm-ui-icon>
    </ng-template>
    <ng-container *ngIf="uav.takeOffMass; else massLimitTemplate">{{ uav.takeOffMass | uavMass }}</ng-container>
    <ng-template #massLimitTemplate>{{
        "datsLibFlights.uavInfo.uavMassLimitLabel" | transloco : { value: (uav.takeOffMassLimit | uavMassLimit) }
    }}</ng-template>
</ng-container>
