import { InjectionToken } from "@angular/core";

export interface AlertEndpoints {
    manageAlerts: string;
    alertManagement: string;
    getAlertProposal: string;
    managePoiList: string;
    zoneAutocompletion: string;
}

export const ALERT_ENDPOINTS = new InjectionToken<AlertEndpoints>("Alert endpoints");
