import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { ExpandablePanelComponent } from "./components/expandable-panel/expandable-panel.component";
import { ActionSidebarFooterComponent } from "./components/sidebar/action-sidebar-footer/action-sidebar-footer.component";
import { ActionSidebarHeaderComponent } from "./components/sidebar/action-sidebar-header/action-sidebar-header.component";
import { ActionSidebarComponent } from "./components/sidebar/action-sidebar/action-sidebar.component";

@NgModule({
    imports: [
        CommonModule,
        LetModule,
        MatSlideToggleModule,
        FormsModule,
        PushModule,
        ActionSidebarHeaderComponent,
        ActionSidebarFooterComponent,
        SharedUiModule,
    ],
    declarations: [ExpandablePanelComponent, ActionSidebarComponent],
    exports: [ExpandablePanelComponent, ActionSidebarComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "datsLibShared",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class SharedModule {}
