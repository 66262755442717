import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatSliderModule } from "@angular/material/slider";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { LetDirective } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { UserSettingsActions } from "../../state/user-settings.actions";
import { UserSettingsState } from "../../state/user-settings.state";

@UntilDestroy()
@Component({
    selector: "dats-lib-settings",
    templateUrl: "./settings.component.html",
    standalone: true,
    imports: [
        CommonModule,
        SharedUiModule,
        SharedI18nModule,
        FormsModule,
        LetDirective,
        MatSlideToggleModule,
        MatInputModule,
        MatMenuModule,
        MatSliderModule,
        ReactiveFormsModule,
    ],
    styleUrls: ["./settings.component.scss"],
    providers: [LocalComponentStore, SharedUiModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent {
    private readonly store = inject(Store);
    protected screenBrightness$ = this.store.select(UserSettingsState.screenBrightness);
    protected systemVolume$ = this.store.select(UserSettingsState.systemVolume);

    protected setBrightness(screenBrightness: number) {
        this.store.dispatch(new UserSettingsActions.SetScreenBrightness(this.limitNumberInRange(screenBrightness, 0, 100)));
    }

    protected setSystemVolume(systemVolume: number) {
        this.store.dispatch(new UserSettingsActions.SetSystemVolume(this.limitNumberInRange(systemVolume, 0, 100)));
    }

    private limitNumberInRange(num: number, min: number, max: number) {
        return Math.max(min, Math.min(num, max));
    }
}
