import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { CAPABILITIES_ENDPOINTS, CapabilitiesEndpoints } from "../capabilities.tokens";
import { Capabilities } from "../models/capabilities.model";
import { CapabilitiesResponseBody, convertCapabilitiesResponseBodyToCapabilities } from "./capabilities-api.converters";

@Injectable({
    providedIn: "root",
})
export class CapabilitiesApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(CAPABILITIES_ENDPOINTS) private readonly endpoints: CapabilitiesEndpoints
    ) {}

    public getCapabilities(): Observable<Capabilities> {
        return this.httpClient
            .get<CapabilitiesResponseBody>(this.endpoints.getCapabilities)
            .pipe(map((response) => convertCapabilitiesResponseBodyToCapabilities(response)));
    }
}
