<div
    class="wrapper"
    *ngrxLet="{
        isLoggedIn: isLoggedIn$,
        isHeaderUserButtonShortMode: isHeaderUserButtonShortMode$,
        userName: userName$,
        isAtcController: isAtcController$
    } as vm"
>
    <dats-lib-flights-navbar [isAtcController]="vm.isAtcController" />
    <div class="user-wrapper">
        <dats-lib-settings></dats-lib-settings>
        <dtm-ui-header-user-button
            [isUserLoggedIn]="vm.isLoggedIn"
            [isShortMode]="vm.isHeaderUserButtonShortMode"
            [userName]="vm.userName"
            (logout)="logout()"
        >
        </dtm-ui-header-user-button>
    </div>
</div>
