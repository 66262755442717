import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { LetDirective } from "@ngrx/component";
import { JurisdictionMission } from "../../models/flight.models";
import { UavMassLimitPipe } from "../../pipes/uav-mass-limit.pipe";

interface MissionItemComponentState {
    mission: JurisdictionMission | undefined;
    isExpanded: boolean;
}

@Component({
    selector: "dats-lib-mission-item",
    standalone: true,
    imports: [CommonModule, SharedUiModule, SharedI18nModule, LetDirective, TranslocoModule, MatTooltipModule, UavMassLimitPipe],
    templateUrl: "./mission-item.component.html",
    styleUrls: ["./mission-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimationWithMargin()],
    providers: [LocalComponentStore],
})
export class MissionItemComponent {
    @Input({ required: true }) public set mission(value: JurisdictionMission) {
        this.localStore.patchState({ mission: value });
    }

    @Output() protected readonly extendedMissionDataOpen = new EventEmitter<void>();

    protected readonly mission$ = this.localStore.selectByKey("mission").pipe(RxjsUtils.filterFalsy());
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");

    constructor(private readonly localStore: LocalComponentStore<MissionItemComponentState>) {
        this.localStore.setState({
            mission: undefined,
            isExpanded: false,
        });
    }

    protected openMissionDetails() {
        // TODO: DTATS-232 Open mission details
        alert("DTATS-232 Open mission details");
    }

    protected togglePanel(isExpanded: boolean) {
        if (isExpanded) {
            this.extendedMissionDataOpen.emit();
        }
        this.localStore.patchState({ isExpanded });
    }

    protected checkIfExtendedMissionDataEmpty(mission: JurisdictionMission): boolean {
        return !mission.flightArea && !mission.uavWeight && !mission.flightCategory && !mission.additionalInfo;
    }
}
