<button type="button" [matMenuTriggerFor]="settingsMenu" class="button-icon">
    <dtm-ui-icon name="settings-fill"></dtm-ui-icon>
</button>
<mat-menu #settingsMenu="matMenu">
    <div
        class="menu-container"
        (click)="$event.stopPropagation()"
        *ngrxLet="{ screenBrightness: screenBrightness$, systemVolume: systemVolume$ } as vm"
    >
        <h3>{{ "datsLibUserSettings.settings.title" | transloco }}</h3>
        <div class="settings-row dark-mode">
            <div class="settings-row-label">
                <dtm-ui-icon name="sun"></dtm-ui-icon>
                <span>{{ "datsLibUserSettings.settings.lightDarkModeToggleLabel" | transloco }}</span>
            </div>
            <div class="dark-mode-toggle">
                <dtm-ui-dark-mode-toggle></dtm-ui-dark-mode-toggle>
            </div>
        </div>
        <div class="settings-row-wrapper">
            <div class="settings-row">
                <div class="settings-row-label">
                    <dtm-ui-icon name="contrast"></dtm-ui-icon>
                    <span>{{ "datsLibUserSettings.settings.screenBrightness" | transloco }}</span>
                </div>
                <dtm-ui-input-field [isClearable]="false">
                    <input matInput type="number" [ngModel]="vm.screenBrightness" (ngModelChange)="setBrightness($event)" />
                    <div class="field-suffix">%</div>
                </dtm-ui-input-field>
            </div>
            <mat-slider min="0" max="100">
                <input matSliderThumb [ngModel]="vm.screenBrightness" (ngModelChange)="setBrightness($event)" />
            </mat-slider>
        </div>
        <div class="settings-row-wrapper">
            <div class="settings-row">
                <div class="settings-row-label">
                    <dtm-ui-icon name="volume-up-fill"></dtm-ui-icon>
                    <span>{{ "datsLibUserSettings.settings.systemVolume" | transloco }}</span>
                </div>
                <dtm-ui-input-field [isClearable]="false">
                    <input matInput type="number" [ngModel]="vm.systemVolume" (ngModelChange)="setSystemVolume($event)" />
                    <div class="field-suffix">%</div>
                </dtm-ui-input-field>
            </div>
            <mat-slider min="0" max="100">
                <input matSliderThumb [ngModel]="vm.systemVolume" (ngModelChange)="setSystemVolume($event)" />
            </mat-slider>
        </div>
    </div>
</mat-menu>
