<ng-container
    *ngrxLet="{
        operation: operation$,
        modification: modification$,
        isEmergency: isEmergency$,
        isDataAcknowledged: isDataAcknowledged$
    } as vm"
>
    <ng-container *ngIf="vm.modification; else noModificationsTemplate">
        <ng-template
            [ngTemplateOutlet]="currentDataTemplate"
            [ngTemplateOutletContext]="{ $implicit: vm.modification.modified }"
        ></ng-template>

        <div
            *ngIf="!vm.isEmergency"
            class="row no-icon"
            [ngClass]="vm.isDataAcknowledged ? 'modification-acknowledged' : 'modification-not-acknowledged'"
        >
            <div>
                <dtm-ui-time-range-display
                    [startTime]="vm.modification.initial.plannedStartAt"
                    [endTime]="vm.modification.initial.plannedEndAt"
                    timeZone="UTC"
                    *ngIf="vm.modification.isTimeModified"
                ></dtm-ui-time-range-display>
            </div>
            <span class="height-label" *ngIf="vm.modification.isHeightModified">
                {{ "datsLibFlights.timeHeightDisplay.heightLabel" | transloco : { value: vm.modification.initial.maxHeight } }}
            </span>
        </div>
    </ng-container>

    <ng-template #noModificationsTemplate>
        <ng-template [ngTemplateOutlet]="currentDataTemplate" [ngTemplateOutletContext]="{ $implicit: vm.operation }"></ng-template>
    </ng-template>

    <ng-template #currentDataTemplate let-data>
        <div class="row" [class.data-emergency]="vm.isEmergency" [class.modification-proposed]="!vm.isDataAcknowledged">
            <div class="column">
                <dtm-ui-icon name="time-utc"></dtm-ui-icon>
                <dtm-ui-time-range-display
                    [startTime]="data.plannedStartAt"
                    [endTime]="data.plannedEndAt"
                    timeZone="UTC"
                    [matTooltip]="
                        'datsLibFlights.timeHeightDisplay.timeDisplayTooltip'
                            | transloco
                                : {
                                      dateFrom: data.plannedStartAt | localizeDate : localTimezoneLocalizeDateConfig,
                                      dateTo: data.plannedEndAt | localizeDate : localTimezoneLocalizeDateConfig,
                                      dateFromUtc: data.plannedStartAt | localizeDate : utcLocalizeDateConfig,
                                      dateToUtc: data.plannedEndAt | localizeDate : utcLocalizeDateConfig
                                  }
                    "
                    matTooltipClass="time-display-tooltip"
                ></dtm-ui-time-range-display>
            </div>
            <div class="column">
                <dtm-ui-icon name="complex-top-height"></dtm-ui-icon>
                <span class="height-label">{{
                    "datsLibFlights.timeHeightDisplay.heightLabel" | transloco : { value: data.maxHeight ?? data.geography.maxHeight }
                }}</span>
            </div>
        </div>
    </ng-template>
</ng-container>
